import { AdScreensaverComponent } from './ad-screensaver.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from '../services/auth.guard';

const routes: Routes = [
  { path: 'ad-screensaver', component: AdScreensaverComponent, canActivate: [AuthGuard] }
];


@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AdScreensaverRoutingModule { }
