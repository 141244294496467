import { Injectable } from '@angular/core';
import { Observer, Observable, ReplaySubject } from 'rxjs';
import { map, share } from 'rxjs/operators';
import { HttpParams } from '@angular/common/http';
import { AuthService } from './auth.service';
import { UtilsService } from './utils.service';
import { ApiService } from './api.service';

/**
 * This service handles all requests, modification and store actions for pages
 */
@Injectable()
export class PagesService {
  pages$: Observable<any>;
  widgets$: Observable<any>;
  currentPage$: Observable<any>;
  currentPage: Page;

  private _pageStore: any = {};
  private _pagesObserver: ReplaySubject<any> = new ReplaySubject(1);
  widgetStore: any = {};
  private _widgetsObserver: Observer<any>;
  private _currentPageObserver: Observer<any>;

  constructor(
    private authService: AuthService,
    private utils: UtilsService,
    private api: ApiService
  ) {
    // this.pages$ = new Observable(observer => this._pagesObserver = observer).share();
    this.pages$ = this._pagesObserver.asObservable();
    this.widgets$ = new Observable(
      observer => (this._widgetsObserver = observer)
    ).pipe(share());
    this.currentPage$ = new Observable(
      observer => (this._currentPageObserver = observer)
    ).pipe(share());
  }

  /**
   * Fetch all pages for a client/device-type combination.
   * @return {Observable<any[]>} The obervable about the pages request
   */
  getAllPages() {
    // default preprocessing
    if (Object.getOwnPropertyNames(this._pageStore).length === 0) {
      const clientId: string = this.authService.authObject.clientId;
      const deviceGroupId: string = this.authService.authObject.deviceGroupId;

      // set the query params
      let parameters = new HttpParams();
      parameters = parameters.append('key', 'pages.' + clientId);
      parameters = parameters.append('deviceGroupId', deviceGroupId);

      this.api
        .get('/content/' + clientId, parameters)
        .pipe(map(res => this.utils.extractData(res)))
        .subscribe(pages => {
          for (let index = 0; index < pages.length; index++) {
            const page: Page = pages[index];
            this.getWidgets(page.id);
            if (page.background && typeof page.background === 'object') {
              for (const locale in page.background) {
                // If it is no object it is the image ID
                if (typeof page.background[locale] !== 'object') {
                  page.background[locale] = this.utils.buildAmazonawsURL(
                    clientId,
                    page.background[locale]
                  );
                  this.setPageImage(
                    page,
                    page.background[locale],
                    locale,
                    'background'
                  );
                }
              }
            } else if (page.background && typeof page.background === 'string') {
              page.background = this.utils.buildAmazonawsURL(
                clientId,
                page.background
              );
              this.setPageImage(page, page.background, '_', 'background');
            }
          }
          this._pageStore = pages;
          this._pagesObserver.next(this._pageStore);
        });
    } else {
      this._pagesObserver.next(this._pageStore);
      this._widgetsObserver.next(this.widgetStore);
    }
  }

  /**
   * Set current page as observable and as class variable
   * @param {Page} page - The page that should be set as current page
   */
  setCurrentPage(page: Page) {
    this.currentPage = page;
    this._currentPageObserver.next(page);
  }

  /**
   * Gets a page by its id
   * @param {string} id - The ID of the page that should be delivered
   * @return {Observable<any>} The Observable of the request.
   */
  getPageByID(id: string): Observable<any> {
    // page identifier and token
    const clientId: string = this.authService.authObject.clientId;

    // call and return the Obserbable
    return this.api
      .get(/content/ + clientId + '/' + id)
      .pipe(map(res => this.utils.extractData(res)));
    // return this.api.get(/content/ + pageId + '/' + id).map(res => {
    // 	return this.utils.extractData(res);
    // });
  }

  /**
   * This function makes a rest call to get all widgets for a given id
   * @param 	{number} pageID - The ID of the page
   * @return void
   */
  getWidgets(pageID: number): void {
    const clientId: string = this.authService.authObject.clientId;

    let parameters = new HttpParams();
    parameters = parameters.append('key', 'widgets.' + clientId);
    parameters = parameters.append('pageId', pageID.toString());
    this.api
      .get(/pageContent/ + clientId, parameters)
      .pipe(map(res => this.utils.extractData(res, 'widget')))
      .subscribe(widgets => {
        if (widgets) {
          widgets.forEach(widget => {
            if (widget.image && typeof widget.image === 'object') {
              for (const locale in widget.image) {
                // If it is no object it is the image ID
                if (typeof widget.image[locale] !== 'object') {
                  widget.image[locale] = this.utils.buildAmazonawsURL(
                    clientId,
                    widget.image[locale]
                  );
                  this.setWidgetImage(
                    widget,
                    widget.image[locale],
                    locale,
                    'image'
                  );
                }
              }
            } else if (widget.image !== '') {
              widget.image = this.utils.buildAmazonawsURL(
                clientId,
                widget.image
              );
              this.setWidgetImage(widget, widget.image, '_', 'image');
            }

            this.utils.prepareWidget(widget, true);

            if (widget.slides) {
              for (const slide of widget.slides) {
                for (const locale in slide) {
                  if (slide[locale].key) {
                    this.utils.preloadImage(
                      this.utils.buildAmazonawsURL(clientId, slide[locale].key)
                    );
                  }
                }
              }
            }
          });
        }
        this.widgetStore[pageID] = widgets;
        this._widgetsObserver.next(this.widgetStore);
      });
  }

  /**
   * This function asks for the image of a page and if it returns
   * the function set it back to the page intead of the id.
   * @param {Page} page - The page that the image is related to
   * @param {string} imageID - The ID of the content element
   * @param {string} locale - The locale that this image relates to
   * @param {string} fieldname - The name of the key in the page object (e.g. image, background etc.)
   * @return {void}
   */
  private setPageImage(
    page: Page,
    imageID: string,
    locale: string,
    fieldname: string
  ): void {
    this.utils.preloadImage(imageID);
    if (this._pageStore && Array.isArray(this._pageStore)) {
      this._pageStore.forEach(p => {
        if (p.id === page.id) {
          if (p.hasOwnProperty(fieldname)) {
            if (typeof p[fieldname] === 'string') {
              p[fieldname] = {};
            }
            p[fieldname][locale] = imageID;
            if (this.currentPage.id === p.id) {
              this.setCurrentPage(p);
            }
            this._pagesObserver.next(this._pageStore);
          }
        }
      });
    }
  }

  /**
   * This function asks for the image of a widget and if it returns
   * the function set it back to the widget intead of the id. Therefore it uses a local widget store.
   * @param {Page} widget - The widget that the image is related to
   * @param {string} imageID - The ID of the content element
   * @param {string} locale - The locale that this image relates to
   * @param {string} fieldname - The name of the key in the widget object (e.g. image, background etc.)
   * @return {void}
   */
  private setWidgetImage(
    widget: Page,
    imageID: string,
    locale: string,
    fieldname: string
  ): void {
    if (typeof imageID !== 'undefined') {
      this.utils.preloadImage(imageID);
      for (const pageID in this.widgetStore) {
        if (
          this.widgetStore.hasOwnProperty(pageID) &&
          this.widgetStore[pageID]
        ) {
          this.widgetStore[pageID].forEach(w => {
            if (w.id === widget.id) {
              if (w.hasOwnProperty(fieldname)) {
                if (typeof w[fieldname] === 'string') {
                  w[fieldname] = {};
                }
                w[fieldname][locale] = imageID;
                this._widgetsObserver.next(this.widgetStore);
              }
            }
          });
        }
      }
    }
  }
}

/**
 * This class describes the structure of a Page or a Widget
 * This class is used for both, pages and widgets.
 */
export class Page {
  constructor(
    public id: number,
    public title: string | Object,
    public type: string,
    public image: any,
    public background: any,
    public content: string | Object,
    public order: number,
    public description: string | Object,
    public shortDescription: string | Object,
    public active: boolean,
    public smsType: string,
    public url: string,
    public urlRedirect: boolean,
    public wideOverlay: boolean,
    public largeLabels: boolean,
    public eventOption: any,
    public event: number,
    public eventListOption: string,
    public eventDateRange: Date[],
    public eventTags: string[]
  ) {}
}
