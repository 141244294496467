import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { ApiService } from './api.service';
import { UtilsService } from './utils.service';
import { HttpParams } from '@angular/common/http';
import { Observable, ReplaySubject } from 'rxjs';
import { map } from 'rxjs/operators';
import * as moment from 'moment';

/**
 * The DishSet Service Stores current dish sets.
 */
@Injectable()
export class DishSetService {
  // new structure, now using a ReplaySubject which fires back a cached result (= it replays the stream)

  dishSets$: Observable<any>;

  private _dishSetsStore: any = {};
  private _dishSetsObserver: ReplaySubject<any> = new ReplaySubject(1);

  constructor(
    private authService: AuthService,
    private utils: UtilsService,
    private api: ApiService
  ) {
    this.dishSets$ = this._dishSetsObserver.asObservable();
  }

  /**
   * Fetch all dish sets of the client.
   * @return void
   */
  getAllDishSets(): void {
    const clientId: string = this.authService.authObject.clientId;

    // set the query params
    let parameters = new HttpParams();
    parameters = parameters.append('key', 'dishsets.' + clientId);

    this.api
      .get('/content/' + clientId, parameters)
      .pipe(map(res => this.utils.extractData(res)))
      .subscribe(dishSets => {
        this._dishSetsStore = dishSets;
        this._dishSetsObserver.next(this._dishSetsStore);
      });
  }
}

export class Dish {
  constructor(
    public name: string,
    public order: number,
    public isMainDish: boolean
  ) {}
}

export class DishSet {
  constructor(
    public activated: boolean,
    public name: string,
    public dateRange: Date[],
    public dishes: Dish[]
  ) {}
}

export class MenuDay {
  constructor(public day: moment.Moment, public dayString: string) {}
}
