import { Geometry } from './../services/places.service';
import {Component, OnInit, OnDestroy, ViewChild, ElementRef, Renderer2, HostListener } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import {
  ClientService,
  LanguagesService,
  ModalService,
  PlacesService,
  AuthService,
  Place,
  Client,
  Advertising, UtilsService
} from '../services';
import { fromEvent, Observable, Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { I18nPipe } from '../shared/pipes/i18n.pipe';
import * as QRious from 'qrious';
import { environment } from '../../environments/environment';
import { MapsAPILoader, MouseEvent, AgmInfoWindow, AgmMarker, AgmMap } from '@agm/core';
import { map } from 'rxjs/operators';

export class SelectedMarker {
  constructor(
    public location: number[],
    public isOpen: boolean,
    public content: any,
    public iconUrl: any,
    public qrCode: string,
    public distanceInMeters: string
  ) { }
}

declare const google;
@Component({
  selector: 'app-map',
  templateUrl: 'map.component.html',
  styleUrls: ['map.component.css'],
  providers: [PlacesService]
})
/**
 * Map Component. In here everything arround the map is defined.
 * It uses Google maps with a custom style. A token must be provided in the
 * initialisation of the component (e.g the constructor)
 */
export class MapComponent implements OnInit, OnDestroy {
  localmode = false;
  places: Observable<Place[]>;
  client: any;
  getClient: Observable<Client>;
  client$: any;
  selectedMarker = new SelectedMarker(new Array(), false, null, null, '', '');
  mapCoordinates: number[] = new Array();
  activeTab: string;
  sideBarVisible = false;
  initialPlace: number[] = new Array();
  page = null;
  categoryId: string;
  placeId: string;
  zoom = 18;
  sort = {sortBy: 'Name', order: 'DESC'};
  public myInterval = 5000;
  public noWrapSlides = false;
  public noTransitions = true;

  @ViewChild('mapContent') mapContent: ElementRef;
  @ViewChild('leftPanelMapBanner') leftPanelMapBanner;
  @ViewChild('leftPanelCategories') leftPanelCategories;
  @ViewChild('leftPanelPlaces') leftPanelPlaces;
  @ViewChild('modal') modal: any;
  selectedPlace: any;

  resizeObservable$: Observable<Event>;
  resizeSubscription$: Subscription;
  threeDView = false;

  categories: Array<any>;

  @ViewChild('urlModal')
  urlModal: any;
  initializeUrlModal = false;
  private backToMenu$: any;

  openWebsiteString = '';
  openQrcode = 'QR-Code';
  initialized = false;

  styles = [
    {
     'featureType': 'poi',
     'elementType': 'labels.icon',
     'stylers': [
       {
         'visibility': 'off'
       }
     ]
    },
    {
     'featureType': 'poi.school',
     'stylers': [
       {
         'visibility': 'off'
       }
     ]
    },
    {
     'featureType': 'transit',
     'elementType': 'labels.icon',
     'stylers': [
       {
         'visibility': 'off'
       }
     ]
    }
  ];

  /**
   * constructor
   */
  constructor(
    private clientService: ClientService,
    private placesService: PlacesService,
    private authService: AuthService,
    private router: Router,
    private route: ActivatedRoute,
    private translate: TranslateService,
    private languagesService: LanguagesService,
    private modalService: ModalService,
    private i18nPipe: I18nPipe,
    private elementRef: ElementRef,
    private renderer: Renderer2,
    private utils: UtilsService
  ) {
    this.localmode = this.getLocalModeSetting();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.reSizeLeftPanelHeight();
  }

  /**
	 * ngOnInit
	 * Normal place for starting in a controller
	 */
  ngOnInit() {
    this.reSizeLeftPanelHeight();
    // Get url params
    let typ: string = this.route.snapshot.params['type'];
    let lng: string = this.route.snapshot.params['lng'];
    let lat: string = this.route.snapshot.params['lat'];
    let pid: string = this.route.snapshot.params['pid'];

    // Router hack. If the address was set by router and not by user this method will be used
    // to get the params
    if (typeof lng === 'undefined' && typeof lat === 'undefined' && typeof pid === 'undefined' && typeof typ !== 'undefined') {
      const tmp = typ.split(';');
      if (tmp.length >= 3) {
        typ = tmp[0];
        const param1 = tmp[1].split('=');
        if (param1[0] === 'lng') {
          lng = param1[1];
        }
        if (param1[0] === 'lat') {
          lat = param1[1];
        }
        const param2 = tmp[2].split('=');
        if (param2[0] === 'lng') {
          lng = param2[1];
        }
        if (param2[0] === 'lat') {
          lat = param2[1];
        }
      }
      if (tmp.length === 2) {
        typ = tmp[0];
        const param1 = tmp[1].split('=');
        if (param1[0] === 'pid') {
          pid = param1[1];
        }
      }
    }

    this.categoryId = typ;
    this.placeId = pid;

    this.placesService.getCategories().subscribe(data => {
      this.getCategoriesByCategoryId(data);
    });

    this.translate.onLangChange.subscribe(() => {
      this.translate.get('map.openWebsite').subscribe(translation => {
        this.openWebsiteString = translation;
      });
    });

    this.translate.get('map.openWebsite').subscribe(translation => {
      this.openWebsiteString = translation;
    });

    this.initMap(typ, lng, lat, pid);

    this.backToMenu$ = this.modalService.backToMenu$.subscribe(res => {
      this.backToMenu();
    });
  }

  private getCategoriesByCategoryId(data: any) {
    const cat = data.find(c => c.id === +this.categoryId);
    this.categories = cat !== undefined && this.categoryId !== undefined ? [cat] : data;
  }

  /**
	 * initMap
	 * This function exists because we cant just attach to this.map at the initialisation phase of the
	 * component.
	 * @param {string} typ  - (optional) Source and layer ids, defined by the typ comes from location params
	 * @param {string} lng  - (optional) Custom longitude
	 * @param {string} lat  - (optional) Custom latitude
	 * @return {void}
	 */
  initMap(typ: string, lng: string, lat: string, pid: string): void {

    // create a map and assign it to the global map object
    this.getClient = this.clientService.getClient();
    this.client$ = this.getClient.subscribe(client => {
      this.client = client;

      if (this.initialized === false) {
        this.initialPlace = [+client.location.lng, +client.location.lat];
        this.mapCoordinates = [...this.initialPlace];

        // check if a pre-selection was made
        // if there is no custom place defined, start normal
        if (typeof typ !== 'undefined' && typ !== null && typ !== '' && typ !== 'custom') {
          this.places = this.placesService.getPlacesByCat(typ);
          this.places
          .pipe(map(data => this.mapDistancePlaces(data)))
          .subscribe(data => {
            if (data instanceof Array && data.length) {
              this.sideBarVisible = true;
            }
          });
          this.activeTab = 'mapOnly';
          // if a place id was found, fetch data and go on with the result
        } else if (typeof typ !== 'undefined' && typ !== null && typ !== ''
          && typ === 'custom' && typeof pid !== 'undefined' && pid !== '') {
          this.places = this.placesService.getPlaceById(pid).pipe(map(data => this.mapDistancePlaces([data])));
          this.places
            .subscribe(data => {
             if (data instanceof Array && data.length) {
              this.sideBarVisible = true;
              this.placeSideBarClickHandler(data[0]);
              this.categoryId = data[0].properties.category;
              this.getCategoriesByCategoryId(this.categories);
            }
            this.activeTab = 'mapOnly';
          });
        } else if (typeof lng !== 'undefined' && typ !== null && lng !== '' && typeof lat !== 'undefined' && lat !== '') {
          this.activeTab = 'mapOnly';
        } else {
          // otherwise go on with the default case
          this.activeTab = 'mapOnly';
        }
        // initLayerPopUp : initialize the layer for the popups
        this.initialized = true;
      }
    });
  }

  private mapDistancePlaces(data) {
    const initialPlace = new google.maps.LatLng(this.initialPlace[1], this.initialPlace[0]);
    data.forEach((place) => {
      const poiPlace = new google.maps.LatLng(place.geometry.coordinates[1], place.geometry.coordinates[0]);
      place.properties.distanceInMeters = Math.round(
          google.maps.geometry.spherical.computeDistanceBetween(initialPlace, poiPlace)
        ).toString();
    });
    return data;
  }

  sortPlaces(sortBy: string) {
    if (sortBy !== this.sort.sortBy) {
      this.sort = {sortBy: sortBy, order: 'DESC'};
    }
    if (this.sort.order === 'DESC') {
      this.sort.order = 'ASC';
    } else {
      this.sort.order = 'DESC';
    }

    this.places = this.places
        .pipe(
          map(data => this.mapDistancePlaces(data)),
          map(data => {
            switch (sortBy) {
              case 'Distance':
                data.sort((p1, p2) => +p1.properties.distanceInMeters - +p2.properties.distanceInMeters);
                if (this.sort.order === 'DESC') {
                  data.reverse();
                }
                break;
              case 'Name':
              default:
                data.sort((p1, p2) => {
                  const order = this.sort.order === 'DESC' ? -1 : 1;
                  const t1 = p1.properties.title._.toLowerCase();
                  const t2 = p2.properties.title._.toLowerCase();
                  if (t1 < t2) { return -1 * order; }
                  if (t1 > t2) { return 1 * order; }
                  return 0;
                });
              }
              return data;
          })
        );
  }

  jumpToInitial() {
    this.sideBarVisible = false;
    this.zoom = 18;
    this.mapCoordinates = [...this.initialPlace];
    if (this.placeId === undefined) {
      this.selectedMarker.isOpen = false;
      this.initialized = true;
    }
  }

  getFilterLayer(ID: number) {
    const id = String(ID);
    if (this.activeTab !== undefined && this.activeTab !== id) {
      if (typeof id !== 'undefined' && id !== '') {
        if (this.placeId) {
          this.places = this.placesService.getPlaceById(this.placeId).pipe(map(data => this.mapDistancePlaces([data])));
          this.places
            .subscribe(data => {
              if (data instanceof Array && data.length) {
                this.sideBarVisible = true;
                this.placeSideBarClickHandler(data[0]);
              }
            });
        } else {
          this.places = this.placesService.getPlacesByCat(id);
          this.places
          .pipe(map(data => this.mapDistancePlaces(data)))
          .subscribe(data => {
            if (data.length > 0) {
              this.sideBarVisible = true;
            }
          });
        }
      }
    }
  }

  placeSideBarClickHandler(place: any) {
    this.selectedMarker = new SelectedMarker(new Array(), false, null, null, '', '');
    this.selectedMarker.location = [place.geometry.coordinates[0], place.geometry.coordinates[1]];
    this.initialized = false;
    this.calculateMapCoordinates(this.selectedMarker.location);
    const content = this.createPopUpContent(place);
    this.selectedMarker.content = content;
    this.selectedMarker.distanceInMeters = place.properties.distanceInMeters;
    this.selectedMarker.isOpen = true;
    if (place.properties.symbol) {
      this.selectedMarker.iconUrl = {
        url: '/assets/maki/icons/' + this.i18nPipe.transform(place.properties.symbol, false) + '-15.svg',
        scaledSize: {height: 40, width: 40}
      };
    } else {
      this.selectedMarker.iconUrl = {
        url: '/assets/maki/icons/marker-15.svg',
        scaledSize: {height: 40, width: 40}
      };
    }
    const qr = new QRious({
      size: 80,
      padding: 0,
      value: 'http://maps.google.com/maps?q=' + place.geometry.coordinates[1] + ',' + place.geometry.coordinates[0]
    });
    this.selectedMarker.qrCode = qr.image.src;
  }

  private calculateMapCoordinates(selectedLocation: any[]) {
    this.mapCoordinates = [ (selectedLocation[0] + this.initialPlace[0]) / 2,
      (selectedLocation[1] + this.initialPlace[1]) / 2 ];

    const WORLD_DIM = { height: 256, width: 256 };
    const ZOOM_MAX = 18;

    const bounds = new google.maps.LatLngBounds(
      new google.maps.LatLng(selectedLocation[0], selectedLocation[1]),
      new google.maps.LatLng(this.initialPlace[0], this.initialPlace[1])
    );

    const mapDiv = document.getElementById('mapContainer');
    const mapDim = { height: mapDiv.clientHeight, width: mapDiv.clientWidth };

    function latRad(lat) {
        const sin = Math.sin(lat * Math.PI / 180);
        const radX2 = Math.log((1 + sin) / (1 - sin)) / 2;
        return Math.max(Math.min(radX2, Math.PI), -Math.PI) / 2;
    }

    function zoom(mapPx, worldPx, fraction) {
        return Math.floor(Math.log(mapPx / worldPx / fraction) / Math.LN2);
    }

    const ne = bounds.getNorthEast();
    const sw = bounds.getSouthWest();

    const latFraction = (latRad(ne.lat()) - latRad(sw.lat())) / Math.PI;

    const lngDiff = ne.lng() - sw.lng();
    const lngFraction = ((lngDiff < 0) ? (lngDiff * (-1)) : lngDiff) / 360;

    const latZoom = zoom((mapDim.height), WORLD_DIM.height, latFraction < 0 ? latFraction * (-1) : latFraction);
    const lngZoom = zoom((mapDim.width / 3), WORLD_DIM.width, lngFraction);

    const calculatedZoom = Math.min(latZoom, lngZoom, ZOOM_MAX);
    this.zoom = (selectedLocation[1] - this.initialPlace[1]) > 0 ? calculatedZoom - 1 : calculatedZoom;
  }

  private assignAwsBucket(place: any): any {
    const amazonAwsBucketName: string = this.authService.authObject ? this.authService.authObject.awsBucketName : '';
    if (typeof place['description'] === 'object') {
      for (const propertyName in <Object>place['description']) {
        if (place['description'].hasOwnProperty(propertyName)) {
          place['description'][propertyName] = place['description'][propertyName].replace(
            this.getAwsBucketUrlRegex(environment.amazonAwsBucketNamePlaceholder),
            amazonAwsBucketName === 'live-bit4m' && environment.cdnAddress
              ? 'https://' + environment.cdnAddress + '/img/'
              : 'https://' + amazonAwsBucketName + '.s3.amazonaws.com/img/'
          );
        }
      }
    } else {
      place['description'] = place['description'].replace(
        this.getAwsBucketUrlRegex(environment.amazonAwsBucketNamePlaceholder),
        amazonAwsBucketName === 'live-bit4m' && environment.cdnAddress
          ? 'https://' + environment.cdnAddress + '/img/'
          : 'https://' + amazonAwsBucketName + '.s3.amazonaws.com/img/'
      );
    }

    return place;
  }

  private getAwsBucketUrlRegex(bucket: string) {
    const oldValue = 'https://' + bucket + '.s3.amazonaws.com/img/';
    return new RegExp(oldValue, 'g');
  }

  /**
	* createPopUpContent
	* @param {any} data - The data to display should be a Place
	* @return {string} html
	*/
  createPopUpContent(data: any): string {
    const lang = this.languagesService.currentLanguage;
    let html: string;
    let place: Object;
    if (typeof data.properties.title === 'object') {
      place = data.properties;
    } else if (data.properties['_toString']) {
      place = JSON.parse(data.properties._toString);
    }

    place = this.assignAwsBucket(place);
    html = '<div style="width:50vw; max-width:500px;">';
    let title = '';
    if (place['title'] && place['title'][lang]) {
      title = place['title'][lang];
    } else if (place['title'] && place['title']['_']) {
      title = place['title']['_'];
    } else {
      title = place['title'];
    }
    html += '<div style="width:100%;font-size:1rem;background-color:rgb(39, 50, 56);color:#fff;padding: 2px 5px;">';
    html += '<span style="line-height: 10px; margin: 0; padding: 0">';
    html += '<b>' + title + '</b>';
    html += '</span>';
    html += '</div>';

    html += '<div style="padding: 5px;">';
    html += '<div style="width: 100%; display: inline-block; vertical-align: top; font-size: 18px;">';
    if (place['description'] && place['description'][lang]) {
      html += '<div>' + place['description'][lang] + '</div>';
    } else if (place['description'] && place['description']['_']) {
      html += '<div>' + place['description']['_'] + '</div>';
    } else if (typeof place['description'] === 'string') {
      html += '<div>' + place['description'] + '</div>';
    }
    html += '</div></div>';
    this.selectedPlace = place;
    return html;
  }

  /**
   * toggleSideBar
   * Small helper function. This function actually toggeles the places sidebar.
   * @return {void}
   */
  toggleSideBar() {
    this.sideBarVisible = !this.sideBarVisible;
  }

  /**
   * backToMenu
   * @return {void}
   */
  backToMenu() {
    this.router.navigate(['/menu']);
  }

  /**
   * getRandomArbitrary
   * This functions returns a random value between the min and max value
   * - Only integers are returned
   * @param {number} min - The min value that can be returned
   * @param {number} may - The max value that can be returned
   * @return {number} The arbitrary number selected
   */
  getRandomArbitrary(min: number, max: number): number {
    // return Math.random() * (max - min) + min;
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  getLocalModeSetting() {
    const local = localStorage.getItem('local');
    if (local && (local === 'true' || local === 'True' || local === 'TRUE')) {
      return true;
    }
    return false;
  }

  private reSizeLeftPanelHeight() {
    const mapHeight = this.mapContent.nativeElement.offsetHeight - 2;
    this.renderer.setStyle(this.leftPanelMapBanner.nativeElement, 'height', mapHeight * 0.5 - 10 + 'px');
    this.renderer.setStyle(this.leftPanelCategories.nativeElement, 'height', mapHeight * 0.5 + 'px');
    this.renderer.setStyle(this.leftPanelPlaces.nativeElement, 'height', mapHeight + 'px');
  }

  redirectPartnerPage(title: string, url: string) {
    if (this.utils.isValid(url) && url !== '') {
      this.modal.showIframeModal({ '_': url }, title);
    }
  }

  getMapBannerHeight(ad: HTMLElement): number {
    return ad.offsetHeight;
  }

  ngOnDestroy() {
    this.client$.unsubscribe();

    if (this.backToMenu$) {
      this.backToMenu$.unsubscribe();
    }
  }
}
