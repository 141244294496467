import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { ApiService } from './api.service';
import { UtilsService } from './utils.service';
import { HttpParams } from '@angular/common/http';
import { Observable, ReplaySubject } from 'rxjs';
import { map } from 'rxjs/operators';
import * as moment from 'moment';

/**
 * The Events Service Stores current events.
 */
@Injectable()
export class EventsService {
  // new structure, now using a ReplaySubject which fires back a cached result (= it replays the stream)

  events$: Observable<any>;

  private _eventsStore: any = {};
  private _eventsObserver: ReplaySubject<any> = new ReplaySubject(1);

  constructor(
    private authService: AuthService,
    private utils: UtilsService,
    private api: ApiService
  ) {
    this.events$ = this._eventsObserver.asObservable();
  }

  /**
   * Fetch all events of the client.
   * @return void
   */
  getAllEvents(): void {
    const clientId: string = this.authService.authObject.clientId;

    // set the query params
    let parameters = new HttpParams();
    parameters = parameters.append('key', 'events.' + clientId);
    const dateFormat = 'dd.MM.yyyy';
    this.api
      .get('/content/' + clientId, parameters)
      .pipe(
        map(res => this.utils.extractData(res)),
        map((events: Event[]) => {
          if (events) {
            events.forEach(ev => {
              if (ev.dateRange && ev.dateRange.length > 1) {
                ev.dateFrom = moment(ev.dateRange[0]).format(dateFormat);
                ev.dateTo = moment(ev.dateRange[1]).format(dateFormat);
              }
            });
          }
          return events;
        })
      )
      .subscribe(events => {
        this._eventsStore = events;
        this._eventsObserver.next(this._eventsStore);
      });
  }
}

export class Event {
  constructor(
    public activated: boolean,
    public title: string,
    public dateRange: Date[],
    public timeFrom: Date,
    public timeTo: Date,
    public description: string,
    public location: string,
    public tags: string[],
    public dateFrom?: string,
    public dateTo?: string
  ) {}
}
