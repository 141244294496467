import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { AuthService } from '.';

@Injectable()
export class AuthGuard implements CanActivate {

  constructor(private authService: AuthService, private router: Router) { }

  /**
	 * canActivate
	 * this function is called every time a route is traversed.
	 * It is checked if the token is still valid
	 * @returns boolean
	 */
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const authString = localStorage.getItem('mac');
    const password = localStorage.getItem('password');
    if (this.authService.authObject) {
      // Check if token expires in the near future and generate a new one if necessary.
      const payload = JSON.parse(atob(this.authService.authObject.token.split('.')[1]));
      const currentTime = new Date().getTime() / 1000;
      if (payload.exp && currentTime < +payload.exp) {
        return true;
      } else {
        this.authService.login(authString, password);
        return true;
      }
    }

    if (state.url && state.url !== 'undefined') {
      this.authService.redirectUrl = state.url;
    } else {
      this.authService.redirectUrl = '/';
    }
    if (authString && authString !== '') {
      this.authService.login(authString, password).subscribe(authObject => {
        this.router.navigate([this.authService.redirectUrl]);
      });
    }

    // TODO: If no mac is available redirect to "Error Page".

    return false;
  }

}
