import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { UtilsService, ClientService, Page, ModalService } from '@app/services';
import { I18nPipe } from '@app/shared/pipes/i18n.pipe';

@Component({
  selector: 'app-email-widget',
  templateUrl: 'email-widget.component.html',
  styleUrls: ['email-widget.component.css']
})

/**
 * Email Widget Class
 */
export class EmailWidgetComponent implements OnInit {
  @Input()
  page: any;
  bg: 'string';
  client: any;

  constructor(
    protected clientService: ClientService,
    protected router: Router,
    protected utils: UtilsService,
    protected i18n: I18nPipe,
    protected modalService: ModalService
  ) { }

  ngOnInit() {
    this.clientService.getClient().subscribe(client => {
      this.client = client;
    });
  }

  /**
	 * open
	 * routes to the email component
	 * @param page the page with the email content information
	 * @returns void
	 */
  open() {
    this.modalService.openWidget(this.page);
  }

  /**
	 * setBGStyle
	 * this function is called by the dom elems to check style information
	 * @return {Object} style class
	 */
  setBGStyle() {
    if (this.client && this.client.colors) {
      const styles = {
        'background-color': this.utils.hexToRgb(this.client.colors.lightBG, this.page.opacity),
      };
      return styles;
    }
    return {};
  }

  getBackgroundPosition() {
    if (this.page && this.page['centerImage']) {
      return 'center';
    }
    return 'left top';
  }

  getBackground(image: any): string {
    const imageUrl = this.i18n.transform(image, false);
    if (imageUrl && imageUrl !== '') {
      return 'url(' + imageUrl + ')';
    }
    return '';
  }

  /**
	 * setFontStyle
	 * this function is called by the dom elems to check style information
	 * @return {Object} style class
	 */
  setFontStyle() {
    if (this.client && this.client.colors) {
      const styles = {
        'color': this.client.colors.main,
      };
      return styles;
    }
    return {};
  }

  /**
	 * This function sets the background of the widget (only if available)
	 * @param {Page} page - The widget object
	 * @return {void}
	 */
  setBackground(page: Page) {
    if (page.image && page.image.value) {
      return { 'background-image': 'url(' + page.image.value + ')' };
    }
    return;
  }

}
