import { Pipe, PipeTransform } from '@angular/core';
import { environment } from 'environments/environment';

@Pipe({
  name: 'awsUrl'
})
export class AwsUrlPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    const amazonAwsBucketName = environment.amazonAwsBucketName ? environment.amazonAwsBucketName : 'live-bit4m';
    const textToReplace = '{bucketname-placeholder}';
    const content = value ? value.changingThisBreaksApplicationSecurity : null;
    if (content !== undefined && content !== null) {
      value.changingThisBreaksApplicationSecurity = content.replaceAll(textToReplace, amazonAwsBucketName);
    }
    return value;
  }

}
