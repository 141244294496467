<div #card class="card transition" (click)="toggleActive(page, $event)" [class.active]="page.active">
  <div [ngStyle]="{ 'background-image': 'url(' + (page.image | i18n) + ')'}" class="card_circle transition"></div>
  <h2 #transition class="transition" [style.top]="page.active ? 0 : cardHeight - transitionHeight - 1 + 'px'"
      [ngStyle]="setFontStyle()">{{page.title | i18n}}</h2>
  <p>{{getDescription(page) | i18n}}</p>
  <div class="cta-container transition">
    <a [ngStyle]="setButtonStyle()" *ngIf="page.content" (click)="openModal()" class="cta">{{ 'std.details' | translate }}</a>
    <a [ngStyle]="setButtonStyle()" *ngIf="page.poiCategory || page.location" (click)="openMap(page.target)" class="cta">{{ 'std.map' | translate }}</a>
  </div>
</div>
<custom-modal #modal></custom-modal>
