import { ScreensaverService } from './services/screensaver.service';
import { Component, OnInit, OnDestroy, ViewContainerRef } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import {
  ClientService, AuthService,
  Page, PagesService,
} from './services';
import { Observable, interval, Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../environments/environment';
import { Angulartics2 } from 'angulartics2';
import { Angulartics2GoogleAnalytics } from 'angulartics2/ga';

declare let gtag: Function;

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'panel-frontend-app',
  templateUrl: 'panel-frontend.component.html',
  styleUrls: ['panel-frontend.component.css'],
})
export class PanelFrontendAppComponent implements OnInit, OnDestroy {
  // static curLang : string;
  client: any;
  currentPage: Observable<Page>;
  pageID: string;
  curBG: string;
  private sub: Subscription;
  updateAvailable = false;
  localmode = false;

  constructor(
    private viewContainerRef: ViewContainerRef,
    private clientService: ClientService,
    private authService: AuthService,
    private pagesService: PagesService,
    private router: Router,
    private translate: TranslateService,
    private screensaverService: ScreensaverService
  ) {
    this.localmode = this._getLocalModeSetting();
    // start with the default lang until all available lang are fetched
    this.translate.setDefaultLang('_');
    this.translate.use('_');
  }

  ngOnInit() {
    // get version from local storage
    let version: string = localStorage.getItem('version');
    let buildVersion: string = localStorage.getItem('buildVersion')
      ? localStorage.getItem('buildVersion')
      : null;

    // check for updates
    this.clientService.client$.subscribe(client => {
      this.client = client;
      this.prepareGoogleAnalyticsGtag();
      if (typeof version === 'undefined' || version == null || version === '') {
        version = '';
        if (client) {
          version = client['version'];
        }
        localStorage.setItem('version', version);
      }

      if (
        !buildVersion ||
        typeof buildVersion === 'undefined' ||
        buildVersion == null || //
        buildVersion === '' ||
        buildVersion === 'undefined'
      ) {
        buildVersion =
          this.authService.authObject &&
            this.authService.authObject.buildVersion //
            ? this.authService.authObject.buildVersion
            : null;
        if (buildVersion) {
          localStorage.setItem('buildVersion', buildVersion);
        }
      }

      if (client && version + '' !== client['version'] + '') {
        localStorage.setItem('version', client['version']);
        localStorage.removeItem('page');
        this.router.navigate(['/']);
        window.location.reload(true);
      }

      if (
        this.authService.authObject &&
        this.authService.authObject.buildVersion && //
        buildVersion + '' !== this.authService.authObject.buildVersion + ''
      ) {
        localStorage.setItem(
          'buildVersion',
          this.authService.authObject.buildVersion
        );
        localStorage.removeItem('page');
        this.router.navigate(['/']);
        window.location.reload(true);
      }

      this.screensaverService.handleScreensaver();
    });

    // get the background for the selected page
    this.viewContainerRef = this.viewContainerRef;
    this.pagesService.currentPage$.subscribe((cur: Page) => {
      if (cur.background) {
        this.curBG = cur.background;
      }
    });

    // check every 5 minutes for updates, this intervall only fires a refresh  of the clientService
    interval(300000).subscribe(() => {
      const authString = localStorage.getItem('mac');
      const password = localStorage.getItem('password');
      this.authService.login(authString, password).subscribe(() => {
        this.clientService.getClient(true).subscribe();
      });
    });
  }

  updateLastActive() {
    this.screensaverService.updateLastActive();
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
    this.screensaverService.clearTimeout();
  }

  private _getLocalModeSetting() {
    const local = localStorage.getItem('local');
    if (local && (local === 'true' || local === 'True' || local === 'TRUE')) {
      return true;
    }
    return false;
  }

  private prepareGoogleAnalyticsGtag() {
    if (environment.analytics) {
      const script = document.createElement('script');
      script.async = true;
      script.src = 'https://www.googletagmanager.com/gtag/js?id=' + environment.analyticsTag;
      document.head.insertBefore(script, document.head.firstChild);
      const navEndEvent$ = this.router.events.pipe(
        filter(e => e instanceof NavigationEnd)
      );
      navEndEvent$.subscribe((e: NavigationEnd) => {
        gtag('config', environment.analyticsTag, {
          'page_path': e.urlAfterRedirects,
          'custom_map': { 'dimension1': 'client_id', 'dimension2': 'device_group_id', 'dimension3': 'device_id', }
        });
        gtag('event', 'client_data', {
          'client_id': this.authService.authObject ? this.authService.authObject.clientId + '' : '',
          'device_group_id': this.authService.authObject ? this.authService.authObject.deviceGroupId + '' : '',
          'device_id': this.authService.authObject ? this.authService.authObject.deviceId + '' : '',
        });
      });
    }
  }
}
