import { Component, OnInit } from '@angular/core';
import { MainMenuComponent } from '../main-menu.component';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { Angulartics2 } from 'angulartics2';
import {
  ActivityService, ApiService, AuthService, ModalService, UtilsService,
  ClientService, Page, PagesService
} from '../../services';

@Component({
  selector: 'app-main-menu-horizontal',
  templateUrl: './main-menu-horizontal.component.html',
  styleUrls: ['./main-menu-horizontal.component.css']
})
export class MainMenuHorizontalComponent extends MainMenuComponent implements OnInit {

  constructor (
    protected api: ApiService,
    protected activityService: ActivityService,
    protected authService: AuthService,
    protected pagesService: PagesService,
    protected clientService: ClientService,
    protected router: Router,
    protected angulartics2: Angulartics2,
    protected modalService: ModalService,
    protected utils: UtilsService
  ) {
    super(api, activityService, authService, pagesService, clientService, router, angulartics2, modalService, utils);
  }

  ngOnInit() {
    super.ngOnInit();
  }
}
