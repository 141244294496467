<div class="tabs tabs-style-bar" style="margin-top: 2rem;">
  <div *ngIf="pages | async; let pageList">
    <nav *ngIf="pageList.length > 1">
      <ul>
        <li *ngFor="let page of pages | async | sortPages" (click)="onSelect(page)">
          <a [ngStyle]="setButtonStyle(page)">
            <i class="material-icons">{{page.icon | i18n }}</i>
            <span>{{page.title | i18n }}</span>
          </a>
        </li>
      </ul>
    </nav>
  </div>
  <router-outlet></router-outlet>
</div>

<app-advertising-partner [smallerAd]="false"></app-advertising-partner>
