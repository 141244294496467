import { Component, Input, OnInit } from '@angular/core';
import { ClientService } from '@app/services';
import { I18nPipe } from '@app/shared/pipes/i18n.pipe';

@Component({
  selector: 'app-static-widget',
  templateUrl: 'static-widget.component.html',
  styleUrls: ['static-widget.component.css']
})

/**
 * Static Widget
 */
export class StaticWidgetComponent implements OnInit {

  @Input()
  page: any;
  bg: string;
  client: any;
  constructor(
    protected clientService: ClientService,
    protected i18n: I18nPipe
  ) { }

  ngOnInit() {
    this.clientService.getClient().subscribe(client => {
      this.client = client;
    });
  }

  /**
	 * setBGStyle
	 * this function is called by the dom elems to check style information
	 * @return {Object} style class
	 */
  setBGStyle() {
    if (this.client && this.client.colors) {
      const styles = {
        'background-color': this.client.colors.accent,
      };
      return styles;
    }
    return {};
  }

  getBackgroundPosition() {
    if (this.page && this.page['centerImage']) {
      return 'center';
    }
    return 'left top';
  }

  getBackground(image: any): string {
    const imageUrl = this.i18n.transform(image, false);
    if (imageUrl && imageUrl !== '') {
      return 'url(' + imageUrl + ')';
    }
    return '';
  }

  /**
	 * setFontStyle
	 * this function is called by the dom elems to check style information
	 * @return {Object} style class
	 */
  setFontStyle() {
    if (this.client && this.client.colors) {
      const styles = {
        'color': this.client.colors.fontLight,
      };
      return styles;
    }
    return {};
  }

}
