import { Injectable } from '@angular/core';
import { Observer, Observable, ReplaySubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthService } from './auth.service';
import { ApiService } from './api.service';
import { UtilsService } from './utils.service';
import { TranslateService } from '@ngx-translate/core';
import { HttpParams } from '@angular/common/http';

/**
 * The Language Service Stores the current language and informs every module if
 * language is changing by creating a current language subject.
 */
@Injectable()
export class LanguagesService {
  // new structure, now using a ReplaySubject which fires back a cached result (= it replays the stream)
  currentLanguage$: ReplaySubject<string> = new ReplaySubject<string>(1);
  currentLanguage: string;

  languages$: Observable<any>;

  private _localeStore: any = {};
  private _localesObserver: ReplaySubject<any> = new ReplaySubject(1);

  constructor(
    private authService: AuthService,
    private translate: TranslateService,
    private utils: UtilsService,
    private api: ApiService
  ) {
    // Set the default language to '_' in case the languages are not received from the server
    this.setCurrentLanguage('_');
    this.languages$ = this._localesObserver.asObservable();
  }

  /**
	 * This function sets the current language set should be used in the application
	 * @param {string} key - Language key. Something like de_DE or en etc.
	 * @return {void}
	 */
  setCurrentLanguage(key: string): void {
    if (key) {
      this.currentLanguage = key;
      this.currentLanguage$.next(key);
      this.translate.use(key);
    }
  }

  /**
	 * Fetch all locales for a client/device-type combination.
	 * @return void
	 */
  getAllLocales(): void {
    const clientId: string = this.authService.authObject.clientId;
    const deviceGroupId: string = this.authService.authObject.deviceGroupId;

    // set the query params
    let parameters = new HttpParams();
    parameters = parameters.append('key', 'locales.' + clientId);
    parameters = parameters.append('deviceGroupId', deviceGroupId);

    this.api.get('/content/' + clientId, parameters).pipe(
      map(
        res => this.utils.extractData(res)
      )
    ).subscribe(locales => {
      this._localeStore = locales;
      this._localesObserver.next(this._localeStore);
    });
  }

}

export class Locale {
  constructor(
    /** The language key according to IETF BCP 47 standard */
    public key: string,
    /** The language icon */
    public icon: string,
    /** Speaching name of the language like English, Deutsch etc. */
    public description: string,
    /** Flag if language is active */
    public active: boolean
  ) { }
}
