import { AdScreensaverModule } from './ad-screensaver/ad-screensaver.module';
// Angular Components
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { LocationStrategy, HashLocationStrategy } from '@angular/common';
import { RouterModule } from '@angular/router';

// App Component
import { PanelFrontendAppComponent } from './panel-frontend.component';

// 3rd Party Components
import { Angulartics2Module } from 'angulartics2';
import { Angulartics2GoogleAnalytics } from 'angulartics2/ga';
import { ModalModule, AlertModule } from 'ngx-bootstrap';
import { SwiperModule } from 'ngx-useful-swiper';

// import './rxjs-operators';

// import { IDLE_PROVIDERS } from 'ng2-idle/core';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';


// App Components
import { MainMenuModule } from './main-menu/main-menu.module';
// import { WidgetBarComponent } from './main-menu/widget-bar';
import { MapModule } from './map/map.module';
import { SmsModule } from './sms/sms.module';
import { EmailModule } from './email/email.module';
import { FeedbackModule } from './feedback/feedback.module';
import { SlideshowModule } from './slideshow/slideshow.module';
import { HeaderModule } from './header/header.module';
import { WidgetHeaderModule } from './widget-header/widget-header.module';
import { WeatherModule } from './weather/weather.module';
import { LanguageModule } from './language/language.module';
import { CustomModalModule } from './custom-modal/custom-modal.module';
import { TimepickerModule } from './timepicker/timepicker.module';

import { WidgetsModule } from './widgets/widgets.module';
import { SharedModule } from './shared/shared.module';
import { DishSetModule } from './dish-set/dish-set.module';
import { WeatherCurrentModule } from './weather-current/weather-current.module';
import { EventsModule } from './events/events.module';
import { AgmCoreModule } from '@agm/core';
import { environment } from '../environments/environment';

// App Services/Guards/Pipes
import { AuthGuard } from './services/auth.guard';
import {
  // Services
  ApiService,
  ClientService,
  UtilsService,
  AuthService,
  FilesService,
  ActivityService,
  ModalService,
  PagesService
} from './services';

// App routing
import { AppRoutingModule } from './app.routes';
import { AdScreensaverComponent } from './ad-screensaver/ad-screensaver.component';

@NgModule({
  imports: [
    BrowserModule, FormsModule, HttpClientModule, RouterModule.forRoot([]), AppRoutingModule, SharedModule,
    Angulartics2Module.forRoot([Angulartics2GoogleAnalytics]), CustomModalModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient]
      }
    }),
    BrowserAnimationsModule, MainMenuModule, DishSetModule, WeatherModule, SmsModule, EmailModule, FeedbackModule,
    SlideshowModule, MapModule, HeaderModule, LanguageModule, WeatherCurrentModule, EventsModule, AdScreensaverModule,
    AgmCoreModule.forRoot({
          apiKey: environment.googleMapsApiKey,
          libraries: ['geometry']
        })
  ],
  declarations: [
    PanelFrontendAppComponent
  ],
  providers: [
    AuthGuard, FilesService, ApiService, ClientService,
    ModalService, PagesService, ActivityService, AuthService, UtilsService,
    { provide: LocationStrategy, useClass: HashLocationStrategy },
  ],
  bootstrap: [PanelFrontendAppComponent]
})
export class AppModule { }

// NGX Translate configuration
class MyTranslateStaticLoader implements TranslateLoader {
  constructor(private http: HttpClient, private prefix = 'i18n', private suffix = '.json') {
  }

  /**
   * Gets the translations from the server
   * @param lang
   * @returns {any}
   */
  public getTranslation(lang: string) {
    const availableLanguages = ['_', 'de_DE', 'en', 'pl_PL', 'de'];
    if (availableLanguages.indexOf(lang) > -1) {
      // Loading available language file
      return this.http.get<Object>(`assets/${this.prefix}/${lang}${this.suffix}`);

    } else {
      // Translation is not provided by panel frontend, use default.
      return this.http.get<Object>(`assets/${this.prefix}/_${this.suffix}`);
    }
  }
}

export function createTranslateLoader(http: HttpClient) {
  return new MyTranslateStaticLoader(http, 'i18n', '.json');
}
