import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from '../services/auth.guard';
import { MainMenuComponent } from './main-menu.component';
import { WidgetBarComponent } from './widget-bar';

const routes: Routes = [
  {
    path: 'menu',
    component: MainMenuComponent,
    canActivate: [AuthGuard],
    children: [
      { path: ':id', component: WidgetBarComponent, canActivate: [AuthGuard] },
      { path: '', component: WidgetBarComponent, canActivate: [AuthGuard] },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class MainMenuRoutingModule { }
