import { Component, OnInit } from '@angular/core';
import { WidgetBarComponent } from '../widget-bar.component';
import { ActivatedRoute } from '@angular/router';
import { PagesService, ClientService } from '../../../services';

@Component({
  selector: 'app-vertical-widget-grid',
  templateUrl: './vertical-widget-grid.component.html',
  styleUrls: ['./vertical-widget-grid.component.css']
})
export class VerticalWidgetGridComponent extends WidgetBarComponent implements OnInit {

  constructor(
    protected route: ActivatedRoute,
    protected pagesService: PagesService,
    protected clientService: ClientService
  ) {
    super(route, pagesService, clientService);
  }

  ngOnInit() {
    super.ngOnInit();
  }

}
