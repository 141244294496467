<div class="map-wrapper" >
  <app-widget-header [header]="page ? (page.title | i18n) : null" (onClose)="backToMenu()"></app-widget-header>
  <div class="row">
    <div class="col-sm-3 col-lg-2" >
      <div class="map-menu-left scrollable" #leftPanelCategories>
        <div class="list-group">
          <button class="list-group-item list-group-item-action" (click)="jumpToInitial()">{{'map.currentPosition' | translate}}</button>
          <button type="button" class="list-group-item list-group-item-action listStop touchHight" *ngFor="let cat of categories" (click)="getFilterLayer(cat.id)"
            [ngClass]="{disabled: cat.id == activeTab}">
            {{cat.value.label | i18n}}
          </button>
        </div>
        <div class="map-menu-left2 scrollable" id="matchedPlaces" [hidden]="!sideBarVisible" #leftPanelPlaces>
          <div class="list-group">
            <div class="list-place-sort-container" *ngIf="placeId === undefined">
              <div class="list-place-sort-by-name">
                <button type="button" class="list-group-item list-group-item-action touchHight" (click)="sortPlaces('Name')">{{'map.sortBy.name' | translate}}</button>
              </div>
              <div class="list-place-sort-by-distance">
                <button type="button" class="list-group-item list-group-item-action touchHight" (click)="sortPlaces('Distance')">{{'map.sortBy.distance' | translate}}</button>
              </div>
            </div>
            <button type="button" class="list-group-item list-group-item-action touchHight" *ngFor="let place of places |async"
                    (click)="placeSideBarClickHandler(place)" style="background-color: rgba(255, 255, 255, 0.5);">
              {{place.properties.title | i18n}}
              <span *ngIf="place.properties.distanceInMeters !== undefined">
                ({{ +place.properties.distanceInMeters > 1000 ? +place.properties.distanceInMeters/1000 + 'km' : place.properties.distanceInMeters + 'm' }})
              </span>
            </button>
          </div>
        </div>
      </div>
      <div class="ad-map-banner" #leftPanelMapBanner>
        <app-ad-map-banner [bannerHeight]='getMapBannerHeight(leftPanelMapBanner)'></app-ad-map-banner>
      </div>

    </div>

    <div id="mapContainer" class="col-sm-9 col-lg-10" #mapContent>
      <agm-map id="map" [ngClass]="{'showCursor': localmode, 'hideCursor': !localmode}" [latitude]="mapCoordinates[1]"
        [longitude]="mapCoordinates[0]" [zoom]="zoom" [streetViewControl]="false" [styles]="styles" [draggableCursor]="'none'">
        <agm-marker [latitude]="initialPlace[1]" [longitude]="initialPlace[0]" [markerDraggable]="false" [iconUrl]="{ url: '/assets/maki/icons/marker-15.svg', scaledSize: {height: 40, width: 40} }">
          <agm-info-window [disableAutoPan]="true">
            {{'map.currentPosition' | translate}}
          </agm-info-window>
        </agm-marker>
        <agm-marker *ngIf="!initialized" [latitude]="selectedMarker.location[1]" [longitude]="selectedMarker.location[0]" [markerDraggable]="false" [animation]="'BOUNCE'" [iconUrl]="selectedMarker.iconUrl">
          <agm-info-window [disableAutoPan]="true" [isOpen]="selectedMarker.isOpen">
            <div id="markerContent" style="margin: 0px; padding: 0px;" [innerHTML]="selectedMarker.content"></div>
            <div *ngIf="selectedPlace && selectedPlace['url']" id="placeWebImage" style="float:left; margin-right:20px; margin-bottom: 0.2rem; margin-top: 0.4rem;">
              <span (click)="redirectPartnerPage(selectedPlace['title'], selectedPlace['url'])" >
                <i class="material-icons" role="img" style="font-size: 2rem; vertical-align: middle; margin-top: auto; margin-bottom: auto;" aria-label="public">public</i>
                <div style="font-size: 0.8rem; display: inline-block; margin-left: 1rem; margin-top: auto; margin-bottom: auto;">
                  {{ openWebsiteString }}
                </div>
              </span>
            </div>
            <div>
              <span style="line-height: 30px; margin: 0;" *ngIf="selectedMarker.distanceInMeters !== undefined">
                {{ 'map.distance' | translate }}: {{ +selectedMarker.distanceInMeters > 1000 ? +selectedMarker.distanceInMeters/1000 + 'km' : selectedMarker.distanceInMeters + 'm' }}
              </span>
            </div>
            <div>
              <div>
                <a href="javascript:mapboxglContentQrToggler()">
                  <i class="material-icons" role="img" style="font-size: 2rem; vertical-align: middle; margin-bottom: 1rem;" aria-label="send">send</i>
                  <div style="font-size: 1rem; display: inline-block; margin-left: 1rem;">
                    {{openQrcode}}
                  </div>
                </a>
              </div>
              <p id="mbglqrcode" class="mapboxgl-content-qr-toggle">
                <img [src]="selectedMarker?.qrCode" style="width:10vw; max-width:120px;"/>
              </p>
            </div>
          </agm-info-window>
        </agm-marker>
      </agm-map>

    </div>
  </div>
</div>
<custom-modal *ngIf="initializeUrlModal" #urlModal></custom-modal>
<custom-modal #modal></custom-modal>
