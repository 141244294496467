import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { Observable, interval, Observer, Subscriber } from 'rxjs';
import { map, share } from 'rxjs/operators';
import * as moment from 'moment';
import { ClientService } from './client.service';
import { ApiService } from './api.service';
import { AuthService } from './auth.service';
import { LanguagesService } from './languages.service';



/** Coordinates object */
export class LonLat {
  constructor(
    /* City geo location, longitude */
    public lon: number,
    /* City geo location, latitude */
    public lat: number
  ) {}
}

/** Weather object */
export class WeatherObject {
  constructor(
    /* Weather condition id */
    public id: string,
    /* Group of weather parameters (Rain, Snow, Extreme etc.) */
    public main: string,
    /* Weather condition within the group */
    public description: string,
    /* Weather icon id */
    public icon: string
  ) {}
}

/** openweather main weather data */
export class MainWeatherObject {
  constructor(
    // Temperature. Unit Default: Kelvin, Metric: Celsius, Imperial: Fahrenheit.
    public temp: number,
    // Atmospheric pressure (on the sea level, if there is no sea_level or grnd_level data), hPa
    public pressure: number,
    // Humidity, %
    public humidity: number,
    /**
     * Minimum temperature at the moment. This is deviation from current temp that is possible for
     * large cities and megalopolises geographically expanded (use these parameter optionally).
     * Unit Default: Kelvin,
     * Metric: Celsius,
     * Imperial: Fahrenheit.
     */
    public temp_min: number,
    /**
     * Maximum temperature at the moment. This is deviation from current temp that is possible for
     * large cities and megalopolises geographically expanded (use these parameter optionally).
     * Unit Default: Kelvin,
     * Metric: Celsius,
     * Imperial: Fahrenheit.
     */
    public temp_max: number
  ) {}
}

/** wind data representation */
export class WindObject {
  constructor(
    // Wind speed. Unit Default: meter/sec, Metric: meter/sec, Imperial:
    public speed: number,
    // Wind direction, degrees (meteorological)
    public deg: number
  ) {}
}

/** cloud data representation */
export class CloudObject {
  constructor(
    // Cloudiness, %
    public all: number
  ) {}
}

/** weatherdata for last three hours */
export class LastThreeHoursObject {
  constructor(
    /** Rain, or Snow volume for the last 3 hours */
    public threeH: number
  ) {}
}

/** openweather Systemvariables */
export class SysObject {
  constructor(
    /** Internal parameter */
    public type: number,
    /** Internal parameter */
    public id: number,
    /** Internal parameter */
    public message: number,
    /** Country code (GB, JP etc.) */
    public country: string,
    /** Sunrise time, unix, UTC */
    public sunrise: number,
    /** Sunset time, unix, UTC */
    public sunset: number
  ) {}
}
/** Object representation for the current weather */
export class CurrentWeatherObject {
  constructor(
    /** Coordinates as LonLat Object */
    public coord: LonLat,
    /** weather */
    public weather: WeatherObject,
    // Internal parameter
    /** base data */
    public base: string,
    /** main weather data */
    public main: MainWeatherObject,
    /** wind data */
    public wind: WindObject,
    /** cloud data */
    public clouds: CloudObject,
    /** rain data */
    public rain: LastThreeHoursObject,
    /** snow data */
    public snow: LastThreeHoursObject,
    // Time of data calculation, unix, UTC
    public dt: number,
    /** openweather system object */
    public sys: SysObject,
    /** City ID */
    public id: number,
    /** City name */
    public name: string,
    /** Internal parameter */
    public cod: number,
    // Copied from MainWeatherObject - Temperature. Unit Default: Kelvin, Metric: Celsius, Imperial: Fahrenheit.
    public temp?: number
  ) {}
}



/**
 * Weather services.
 * This class gets, holds and manipulates weather data
 */
@Injectable()
export class WeatherService {
  currentWeather$: Observable<CurrentWeatherObject>;
  currentWeather: CurrentWeatherObject;
  private _currentWeatherObserver: Observer<CurrentWeatherObject>;
  private _forecast: Observer<any>;
  forecast$: Observable<any>;
  private _dataStore: CurrentWeatherObject;
  public client: any;

  constructor(
    private api: ApiService,
    private authService: AuthService,
    private clientService: ClientService,
    private languagesService: LanguagesService
  ) {
    // Setup Observer
    this.currentWeather$ = new Observable(
      (observer: Subscriber<CurrentWeatherObject>) =>
        (this._currentWeatherObserver = observer)
    ).pipe(share());
    this.forecast$ = new Observable(
      observer => (this._forecast = observer)
    ).pipe(share());

    // If there is a new client, set client and load weather
    this.clientService.client$.subscribe(client => {
      this.client = client;
      this.getCurrentWeather(client);
    });

    // Get weather again if language changes
    this.languagesService.currentLanguage$.subscribe(() => {
      if (this.client) {
        this.getCurrentWeather(this.client);
      }
    });

    // Check for new weather every 10 minutes (600000 Milliseconds)
    interval(600000).subscribe(() => {
      if (this.client) {
        this.getCurrentWeather(this.client);
      }
    });
  }

  /**
   * Gets the current Weather of a specific location stored in the client configuration
   * @param {Client} client - The weather will be received for this clients location
   * @return void
   */
  getCurrentWeather(client: any): void {
    // check if all preconditions are set
    if (client.location && client.location.lat && client.location.lng) {
      // collect base information
      let lang = this.languagesService.currentLanguage;

      // build parameter set for the request
      let parameters = new HttpParams();
      parameters = parameters.append('latitude', client.location.lat);
      parameters = parameters.append('longitude', client.location.lng);

      // check if any language information are available and set a language if so
      if (lang && lang !== '' && lang !== '_') {
        lang = lang.slice(0, 2);
      } else if (lang === '_') {
        lang = 'de';
      }
      parameters.set('lang', lang);

      // fire request
      this.api.post('/weather/current', '', parameters).subscribe(
        res => {
          const data = res.body;
          let currentWeather: CurrentWeatherObject;
          if (data && data.cod === 404) {
            console.warn(data);
          } else {
            currentWeather = new CurrentWeatherObject(
              data.coord,
              data.weather,
              data.base,
              data.main,
              data.wind,
              data.clouds,
              data.rain,
              data.snow,
              data.dt,
              data.sys,
              data.id,
              data.name,
              data.cod
            );
            if (
              currentWeather.main &&
              currentWeather.main.hasOwnProperty('temp')
            ) {
              currentWeather.temp = currentWeather.main.temp;
            }
          }
          this._dataStore = currentWeather;

          // Push the new weather info into the Observable stream
          this.currentWeather = this._dataStore;
          this._currentWeatherObserver.next(this._dataStore);
        },
        error => console.warn('Could not load current weather.')
      );
    }
  }

  /**
   * loadForecast
   * validate the auth token, if everything is alright fire a request for a forecast
   * with that token
   * @return {void}
   */
  loadForecast() {
    if (this.client) {
      let lang = this.languagesService.currentLanguage;
      let parameters = new HttpParams();
      parameters = parameters.append('latitude', this.client.location.lat);
      parameters = parameters.append('longitude', this.client.location.lng);
      if (lang && lang !== '') {
        lang = lang.slice(0, 2);
        parameters.set('lang', lang);
      }

      this.api
        .post('/weather/forecast', '', parameters)
        .pipe(map(response => response.body))
        .subscribe(
          data => {
            if (data && data.cod === 404) {
              console.warn(data);
            } else {
              if (data && data.response) {
                data = data.response;
              }
              data = this.splitDateTime(data);
              data = this.groupByDay(data);
            }
            // Push the new list of todos into the Observable stream
            this._forecast.next(data);
          },
          error => console.warn('Could not load forecast weather.')
        );
    }
  }

  /**
   * Split input in dates and times
   * @param {any} data - The object to splitDateTime
   * @return {any} An object with seperate day and time lists
   */
  private splitDateTime(data: any): any {
    for (const key in data['list']) {
      if (key) {
        const item = data['list'][key];
        const dateAsString: string = item['dt_txt'];
        const date = moment(dateAsString, 'YYYY-MM-DD HH:mm:ss');
        data['list'][key]['day'] = date.format('dddd');
        data['list'][key]['time'] = date.format('HH:mm');
      }
    }
    return data;
  }

  /**
   * Helper function.
   * Transforms a meterological direction degree in an human readable format.
   */
  getWindDirectionAsText(windDeg: number): string {
    const directions = [
      'Error',
      'NNO',
      'NO',
      'ONO',
      'O',
      'OSO',
      'SO',
      'SSO',
      'S',
      'SSW',
      'SW',
      'WSW',
      'W',
      'WNW',
      'NW',
      'WN',
      'N'
    ];
    const windStringNumber = Math.round(windDeg / 22.5);
    return directions[windStringNumber];
  }

  /**
   * Group data from input by day
   * @param {any} data - The input to sort
   * @return {any} The sorted Object
   */
  private groupByDay(data): any {
    const newList: Array<any> = [];
    let lastDay: string;
    const ob = {};
    for (const key in data['list']) {
      if (key) {
        const item = data['list'][key];
        const day = item['day'];

        if (lastDay !== item['day']) {
          ob[day] = [];
        }
        ob[day].push(item);
        lastDay = item['day'];
      }
    }
    newList.push(ob);
    data['list'] = ob;
    return data;
  }
}

