import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MainMenuRoutingModule } from './main-menu-routing.module';
import { MainMenuComponent } from './main-menu.component';
import { WidgetBarComponent } from './widget-bar/widget-bar.component';
import { CustomModalModule } from '../custom-modal/custom-modal.module';
import { SharedModule } from '../shared';
import { WidgetsModule } from '../widgets/widgets.module';
import { ActivityService, ApiService, AuthService, ModalService, UtilsService, ClientService, PagesService } from '../services';
import { AdvertisingPartnerComponent } from './advertising-partner/advertising-partner.component';
import { SwiperModule } from 'ngx-useful-swiper';
import { MainMenuVerticalComponent } from './main-menu-vertical/main-menu-vertical.component';
import { MainMenuHorizontalComponent } from './main-menu-horizontal/main-menu-horizontal.component';
import { VerticalWidgetGridComponent } from './widget-bar/vertical-widget-grid/vertical-widget-grid.component';
import { HorizontalWidgetBarComponent } from './widget-bar/horizontal-widget-bar/horizontal-widget-bar.component';
import { HeaderModule } from '../header/header.module';

@NgModule({
  imports: [
    CommonModule, MainMenuRoutingModule, CustomModalModule, SharedModule, WidgetsModule, SwiperModule, HeaderModule
  ],
  declarations: [MainMenuComponent, WidgetBarComponent, AdvertisingPartnerComponent, MainMenuVerticalComponent,
    MainMenuHorizontalComponent, VerticalWidgetGridComponent, HorizontalWidgetBarComponent],
  exports: [MainMenuComponent],
  providers: [ActivityService, ApiService, AuthService, ModalService, UtilsService, ClientService, PagesService]
})
export class MainMenuModule { }
