import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PagesService, ClientService, DisplayTypeEnum } from '../../services';

@Component({
  selector: 'app-widget-bar',
  templateUrl: 'widget-bar.component.html'
})

/**
 * Handles the widget parts
 */
export class WidgetBarComponent implements OnInit {
  isVerticalDisplay = false;
  private sub: any;
  private _widgets: any = {};
  localmode = 'false';
  currentWidgets: Array<any> = new Array();
  getClient$: any;
  constructor(
    protected route: ActivatedRoute,
    protected pagesService: PagesService,
    protected clientService: ClientService
  ) { }

  ngOnInit() {
    this.localmode = window.localStorage.getItem('local');
    if (this.pagesService.widgetStore) {
      this._widgets = this.pagesService.widgetStore;
    }
    this.pagesService.widgets$.subscribe(widgets => {
      this._widgets = widgets;
      if (this.pagesService.currentPage && this.pagesService.currentPage.id) {
        this.currentWidgets = widgets[this.pagesService.currentPage.id];
      }
    });

    this.sub = this.route.params.subscribe(params => {
      const id = params['id'];
      if (id) {
        if (this._widgets[id]) {
          this.currentWidgets = this._widgets[id];
        }
      }
    });

    this.getClient$ = this.clientService.getClient().subscribe(c => {
      this.isVerticalDisplay = c.displayType && c.displayType === DisplayTypeEnum.VERTICAL;
    });
  }
}
