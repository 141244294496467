<div class="advertising-container" [ngStyle]="bannerHeight ? {height: bannerHeight + 'px'} : {height: 'auto'}">

  <swiper #swiper [config]="config">
    <div class="swiper-wrapper">
      <div class="swiper-slide" *ngFor="let ad of getFilteredAdvertisements()">
        <img class="image" [src]="ad?.mapBanner.image" (click)="redirectPartnerPage(ad)" [ngStyle]="bannerHeight ? {height: bannerHeight + 'px'} : {height: 'auto'}">
      </div>
    </div>
  </swiper>
  <custom-modal #modal></custom-modal>
</div>

