import { Component, OnInit } from '@angular/core';
import { StaticWidgetComponent } from '../../static-widget/static-widget.component';
import { ClientService } from '@app/services';
import { I18nPipe } from '@app/shared/pipes/i18n.pipe';

@Component({
  selector: 'app-static-widget-vertical',
  templateUrl: './static-widget-vertical.component.html',
  styleUrls: ['./static-widget-vertical.component.css']
})
export class StaticWidgetVerticalComponent extends StaticWidgetComponent implements OnInit {

  constructor(
    protected clientService: ClientService,
    protected i18n: I18nPipe
  ) {
    super(clientService, i18n);
  }

  ngOnInit() {
    super.ngOnInit();
  }

}
