<div class="row menu-content">
  <div class="col-4 col-lg-2">
    <div class="page-nav-bar" [ngStyle]="setBackgroundColor()">
      <div *ngIf="pages | async; let pageList">
        <nav *ngIf="pageList.length > 1">
          <ul>
            <li *ngFor="let page of pages | async | sortPages" (click)="onSelect(page)">
              <a [ngStyle]="setButtonStyle(page)">
                <i class="material-icons" style="padding-right: 10px; vertical-align: middle;">{{page.icon | i18n }}</i>
                <span>{{page.title | i18n }}</span>
              </a>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  </div>
  <div class="col-8 col-lg-10">
    <router-outlet></router-outlet>
    <app-advertising-partner [smallerAd]="true"></app-advertising-partner>
  </div>
</div>
