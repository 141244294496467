<div (touchend)="updateLastActive()" (mouseup)="updateLastActive()" [ngClass]="{'local-mode': localmode, 'normal-mode': !localmode}">
  <div *ngIf="curBG" class="container-fluid app-wrapper fixed-bg" [ngStyle]="{ 'background-image': 'url(' + (curBG | i18n) + ')'}">
  </div>
  <app-header></app-header>

  <!-- Routed views go here -->
  <router-outlet></router-outlet>

  <app-language></app-language>
</div>
