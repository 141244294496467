import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { map } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import {
  ActivityService,
  AuthService,
  ApiService,
  ClientService,
  PagesService,
  EmailService,
  ModalService,
  UtilsService,
} from '../services';
import { I18nPipe } from '../shared/pipes/i18n.pipe';
import { SwiperComponent } from 'ngx-useful-swiper';

@Component({
  selector: 'app-slideshow',
  templateUrl: 'slideshow.component.html',
  styleUrls: ['slideshow.component.css'],
  providers: [PagesService, EmailService]
})
export class SlideshowComponent implements OnInit, OnDestroy {
  page: any;
  client: any;
  public clientId: any;
  public destroyed = false;

  @ViewChild('slideshowForm')
  slideshowForm: any;

  config = {
    pagination: {
      el: '.swiper-pagination'
    },
    paginationClickable: true,
    loop: true,
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev'
    },
    spaceBetween: 30
  };

  @ViewChild('swiper')
  swiper: SwiperComponent;
  @ViewChild('hotspotWidgetModal')
  hotspotWidgetModal: any;

  private backToMenu$: any;
  private openModal$: any;
  private openedAsScreensaver = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private api: ApiService,
    private activityService: ActivityService,
    private authService: AuthService,
    private pagesService: PagesService,
    private clientService: ClientService,
    private i18nPipe: I18nPipe,
    private modalService: ModalService,
    public utils: UtilsService
  ) {}

  ngOnInit() {
    if (
      Math.abs(
        new Date().getTime() -
          this.activityService.lastScreensaverWidgetOpened.getTime()
      ) < 1500
    ) {
      this.openedAsScreensaver = true;
    }
    this.clientService.getClient().subscribe(client => {
      this.client = client;
    });

    this.clientId = this.authService.authObject
      ? this.authService.authObject.clientId
      : null;
    const contentId = this.route.snapshot.params['id'];
    this.pagesService.getPageByID(contentId).subscribe(p => {
      this.page = p;
      let interval = 5000;
      if (this.page.slideInterval) {
        interval = this.page.slideInterval * 1000;
      }
      this.slideTimeout(interval, 0);
      setTimeout(() => {
        this.swiper.swiper['on']('touchstart', function() {
          this.updateLastTouched();
        });
      }, 500);
    });
    this.backToMenu$ = this.modalService.backToMenu$.subscribe(res => {
      this.back();
    });

    this.openModal$ = this.modalService.openModal$.subscribe(res => {
      this.hotspotWidgetModal.showModal(
        res.content,
        res.title,
        null,
        null,
        res.wideOverlay
      );
    });
  }

  private slideTimeout(interval, substractInterval) {
    if (!substractInterval) {
      substractInterval = 0;
    }
    setTimeout(() => {
      const lastTouchedPassed =
        new Date().getTime() - this.activityService.lastActive.getTime();
      if (lastTouchedPassed < interval) {
        this.slideTimeout(interval, lastTouchedPassed);
      } else {
        if (!this.swiper.swiper.slideNext()) {
          this.swiper.swiper.slideTo(
            0,
            interval / 2 > 2000 ? 2000 : interval / 2
          );
        }
        this.slideTimeout(interval, 0);
      }
    }, interval - substractInterval);
  }

  public slideClicked(event, slide: any) {
    slide = this.i18nPipe.transform(slide, false);
    if (!this.isNull(slide.hotspotWidgetId)) {
      if (
        slide.hotspotX1 &&
        slide.hotspotY1 &&
        slide.hotspotX2 &&
        slide.hotspotY2
      ) {
        const x = (event.offsetX / event.target.width) * 100;
        const y = (event.offsetY / event.target.height) * 100;
        if (
          x > this.getLesser(slide.hotspotX1, slide.hotspotX2) &&
          x < this.getGreater(slide.hotspotX1, slide.hotspotX2) &&
          y > this.getLesser(slide.hotspotY1, slide.hotspotY2) &&
          y < this.getGreater(slide.hotspotY1, slide.hotspotY2)
        ) {
          this.getHotspotWidgetAndOpen(slide);
        }
      } else {
        this.getHotspotWidgetAndOpen(slide);
      }
    }
  }

  private getHotspotWidgetAndOpen(slide: any) {
    this.api
      .get(/content/ + this.clientId + '/' + slide.hotspotWidgetId)
      .pipe(map(res => this.utils.extractData(res)))
      .subscribe(hotspotWidget => {
        this.utils.prepareWidget(hotspotWidget, false);
        const wideOverlay = hotspotWidget.wideOverlay ? true : false;
        this.modalService.openWidget(
          hotspotWidget,
          this.openedAsScreensaver || wideOverlay,
          this.openedAsScreensaver
        );
      });
    return;
  }

  private updateLastTouched() {
    this.activityService.updateLastActive();
  }

  private isNull(val) {
    if (!val || val == null || val.toString() === 'null') {
      return true;
    }
    return false;
  }

  private getLesser(val1, val2) {
    return val1 < val2 ? val1 : val2;
  }

  private getGreater(val1, val2) {
    return val1 > val2 ? val1 : val2;
  }

  /**
   * back
   * return to the main navigation
   * @return {void}
   */
  back() {
    this.router.navigate(['/']);
  }

  /**
   * _resetForm
   * reset the formdata
   * @return {void}
   */
  _resetForm() {
    this.slideshowForm.form.reset();
  }

  /**
   * set the colors styles for the client
   * @return {void}
   */
  setHeaderStyle() {
    if (this.client && this.client.colors) {
      const styles = {
        'background-color': this.client.colors.main,
        color: this.client.colors.fontLight,
        opacity: 1
      };
      return styles;
    }
    return {};
  }

  ngOnDestroy() {
    if (this.backToMenu$) {
      this.backToMenu$.unsubscribe();
    }
    if (this.openModal$) {
      this.openModal$.unsubscribe();
    }
    this.destroyed = true;
  }
}
