import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError, share } from 'rxjs/operators';

// import 'rxjs/add/operator/cache';
import { AuthService } from './auth.service';
import { UtilsService } from './utils.service';


@Injectable()
export class ApiService {
  private apiURL: string = localStorage.getItem('backendURL') + '/api/v2';
  constructor(
    private _http: HttpClient,
    private authService: AuthService,
    private utils: UtilsService
  ) { }

  buildOptions(params?: HttpParams): any {
    const token: string = this.authService.authObject.token;
    const headers = new HttpHeaders({ 'Authorization': 'Bearer ' + token });
    let options: any;

    if (params !== undefined) {
      options = { headers: headers, params: params, observe: 'response' };
    } else {
      options = { headers: headers, observe: 'response' };
    }
    return options;
  }

  get(route: string, parameters?: HttpParams): Observable<HttpResponse<any>> {
    const options = this.buildOptions(parameters);
    return this._http.get(this.apiURL + route, options).pipe(
      catchError(this.utils.handleError),
      share()
    );
  }

  post(route: string, body: string, parameters?: HttpParams): Observable<HttpResponse<any>> {
    const options = this.buildOptions(parameters);
    return this._http.post(this.apiURL + route, body, options).pipe(
      catchError(this.utils.handleError),
      share()
    );
  }

  postWithJsonContent(route: string, body: any): Observable<HttpResponse<any>> {
    const token: string = this.authService.authObject.token;
    const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token });
    const options = { headers: headers };
    return this._http.post(this.apiURL + route, body, options).pipe(
      catchError(this.utils.handleError),
      share()
    );
  }

}
