import { Component, ViewChild, OnDestroy, ElementRef, Renderer2 } from '@angular/core';
import { Router } from '@angular/router';
import { SafeResourceUrl, DomSanitizer } from '@angular/platform-browser';
import { ClientService, LanguagesService, ModalService, UtilsService } from '../services';

/**
 * contains the differentiation for the content
 */
class BeforeContent {
  modalBody: string;
  iframeSrc: SafeResourceUrl;
}


@Component({
  // tslint:disable-next-line:component-selector
  selector: 'custom-modal',
  templateUrl: 'custom-modal.component.html',
  styleUrls: ['custom-modal.component.css']
})

/**
 * Modal class. Use this to display a static modal.
 */
export class CustomModalComponent implements OnDestroy {
  modalTitle: string;
  modalBody: string;
  modalTarget: string;
  modalTargetTitle: string;
  wideOverlay: boolean;
  client: any;
  iframeSrc: SafeResourceUrl;
  beforeContent: BeforeContent = new BeforeContent;
  @ViewChild('modal') modal: any;
  @ViewChild('urlModal') urlModal: any;
  initializeUrlModal = false;
  backToMenu$: any;
  isModalShown = false;

  constructor(
    public router: Router,
    private clientService: ClientService,
    private sanitizer: DomSanitizer,
    private languagesService: LanguagesService,
    private modalService: ModalService,
    private utils: UtilsService,
    private elementRef: ElementRef,
    private renderer: Renderer2
  ) {
    this.clientService.getClient().subscribe(client => {
      this.client = client;
    });
    this.backToMenu$ = this.modalService.backToMenu$.subscribe(res => {
      if (this.initializeUrlModal && this.urlModal) {
        this.urlModal.modal.hide();
      }
    });
  }

  /**
	 * opens a modal with the given params
	 * @param {string} modalBody
	 * @param {string} modalTitle
	 * @param {string} modalTarget
	 * @param {string} modalTargetTitle
	 * @param {boolean} wideOverlay
	 * @return {void}
	 */
  showModal(
    modalBody: string,
    modalTitle: string,
    modalTarget: string,
    modalTargetTitle: string,
    wideOverlay?: boolean
  ) {
    if (typeof modalBody !== 'undefined') {
      this.modalTitle = modalTitle;
      this.beforeContent.modalBody = modalBody;
      this.modalTarget = modalTarget;
      this.modalTargetTitle = modalTargetTitle;
      this.wideOverlay = wideOverlay ? true : false;
      this.modal.show();
      this.isModalShown = true;
      setTimeout(() => {
        const elements = this.elementRef.nativeElement.querySelectorAll('a[source]');
        for (const element of elements) {
          this.renderer.listen(element, 'click', (evt) => {
            this.urlModal.showIframeModal({ '_': element.getAttribute('source') }, 'Title');
          });
        }
        if (elements.length > 0) {
          this.initializeUrlModal = true;
        }
      }, 1500);
    }
  }

  /**
	 * set the content
	 * @returns void
	 */
  setContent() {
    this.modalBody = this.beforeContent.modalBody;
    this.iframeSrc = this.beforeContent.iframeSrc;
  }

  /**
	 * reset the modal
	 * @return {void}
	 */
  reset() {
    this.modalTitle = '';
    this.modalBody = '';
    this.iframeSrc = null;
    this.isModalShown = false;
  }

  /**
	 * showIframeModal
	 * @param {string} iframeSrc - Target source url
	 * @param {string} modalTitle - The title to display
	 * @return {void}
	 */
  showIframeModal(iframeSrc: string, modalTitle: string) {
    const lang = this.languagesService.currentLanguage;
    this.modalTitle = modalTitle;
    if (iframeSrc[lang]) {
      this.beforeContent.iframeSrc = this.sanitizer.bypassSecurityTrustResourceUrl(iframeSrc[lang]);
    } else if (iframeSrc['_']) {
      this.beforeContent.iframeSrc = this.sanitizer.bypassSecurityTrustResourceUrl(iframeSrc['_']);
    }
    if (iframeSrc) {
      this.modal.show();
      this.isModalShown = true;
    } else {
      throw new Error('Error: Could not found IFrame src');
    }
  }

  /**
	 * openTarget
	 * @param {string} target - Taget uri
	 * @return {void}
	 */
  openTarget(target: string) {
    this.modal.hide();
    this.isModalShown = false;
    this.router.navigate([target]);
  }

  /**
	 * setHeaderStyle
	 * returns style class
	 */
  setHeaderStyle() {
    if (this.client && this.client.colors) {
      const styles = {
        'background-color': this.client.colors.main,
        'color': this.client.colors.fontLight,
        'opacity': 1
      };
      return styles;
    }
    return {};
  }

  ngOnDestroy() {
    if (this.backToMenu$) {
      this.backToMenu$.unsubscribe();
    }
  }

}

