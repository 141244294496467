import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { SwiperModule } from 'ngx-useful-swiper';
import { SlideshowComponent } from './slideshow.component';
import { SharedModule } from '../shared';
import { WidgetHeaderModule } from '../widget-header/widget-header.module';
import { CustomModalModule } from '../custom-modal/custom-modal.module';
import {
  ActivityService, AuthService, ApiService, ClientService,
  PagesService, EmailService, ModalService, UtilsService
} from '../services';

@NgModule({
  imports: [
    CommonModule, SharedModule, WidgetHeaderModule, FormsModule, SwiperModule, CustomModalModule
  ],
  declarations: [SlideshowComponent],
  exports: [SlideshowComponent],
  providers: [ActivityService, AuthService, ApiService, ClientService, PagesService, EmailService, ModalService, UtilsService]
})
export class SlideshowModule { }
