import { Component, OnInit, Input } from '@angular/core';
import { ContentWidgetComponent } from '../../content-widget/content-widget.component';
import { Router } from '@angular/router';
import { UtilsService, ClientService, LanguagesService, ModalService } from '@app/services';
import { I18nPipe } from '@app/shared/pipes/i18n.pipe';

@Component({
  selector: 'app-content-widget-vertical',
  templateUrl: './content-widget-vertical.component.html',
  styleUrls: ['./content-widget-vertical.component.css']
})
export class ContentWidgetVerticalComponent extends ContentWidgetComponent implements OnInit {

  constructor(
    protected router: Router,
    protected clientService: ClientService,
    protected languagesService: LanguagesService,
    protected modalService: ModalService,
    protected utils: UtilsService,
    protected i18n: I18nPipe
  ) {
    super(router, clientService, languagesService, modalService, utils, i18n);
  }

  ngOnInit() {
    super.ngOnInit();
  }

}
