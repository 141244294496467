import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { AlertModule } from 'ngx-bootstrap/alert';
import { ModalModule } from 'ngx-bootstrap/modal';
import { EmailComponent } from './email.component';
import { WidgetHeaderModule } from '../widget-header/widget-header.module';
import { SharedModule } from '../shared';
import { TimepickerModule } from '../timepicker/timepicker.module';
import { CustomModalModule } from '../custom-modal/custom-modal.module';
import { ClientService, ModalService, PagesService, EmailService, UtilsService } from '../services';
import { NgSelectModule } from '@ng-select/ng-select';

@NgModule({
  imports: [
    CommonModule, WidgetHeaderModule, SharedModule, FormsModule, TranslateModule.forChild(), AlertModule.forRoot(), ModalModule.forRoot(),
    TimepickerModule, CustomModalModule, NgSelectModule
  ],
  declarations: [EmailComponent],
  exports: [EmailComponent],
  providers: [ClientService, ModalService, PagesService, EmailService, UtilsService]
})
export class EmailModule { }
