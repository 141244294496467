import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { ClientService, ModalService, PagesService, EmailService, Email, FeedbackService, Feedback } from '../services';
import { I18nPipe } from '../shared/pipes/i18n.pipe';

@Component({
  selector: 'app-feedback',
  templateUrl: 'feedback.component.html',
  styleUrls: ['feedback.component.css'],
  providers: [ PagesService, EmailService ]
})
export class FeedbackComponent implements OnInit, OnDestroy {
  page: any;
  roomNumber = '';
  additionalTextValue = '';
  sendMessage: string;
  client: any;
  sending = false;
  feedbackSuccessfullySend: boolean;
  private backToMenu$: any;

  @ViewChild('feedbackForm') feedbackForm: any;
  @ViewChild('modalSend') modalSend: any;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private clientService: ClientService,
    private emailService: EmailService,
    private modalService: ModalService,
    private pagesService: PagesService,
    private feedbackService: FeedbackService,
    private i18nPipe: I18nPipe
  ) {}

  ngOnInit() {
    this.feedbackSuccessfullySend = false;
    this.clientService.getClient().subscribe( client => {
      this.client = client;
    });

    const contentId = this.route.snapshot.params['id'];
    this.pagesService.getPageByID(contentId).subscribe( p => {
      this.page = p;
    });

    this.backToMenu$ = this.modalService.backToMenu$.subscribe(res => {
      this.back();
    });
  }

  answer(index: number, answer: number) {
    if (this.page.questions[index].answer === answer) {
      this.page.questions[index].answer = null;
    } else {
      this.page.questions[index].answer = answer;
    }
  }

  answeredAllRequired(): boolean {
    for (const question of this.page.questions) {
      if (!question.notRequired && !question.answer) {
        return false;
      }
    }
    return true;
  }

  /**
	 * back
	 * return to the main navigation
	 * @return {void}
	 */
  back() {
    this.router.navigate(['/']);
  }

  /**
	 * _resetForm
	 * reset the formdata
	 * @return {void}
	 */
  _resetForm() {
    this.roomNumber = '';
    this.feedbackForm.form.reset();
  }

  /**
	 * successAndBack
	 * closes the modal and calls back
	 * @return {void}
	 */
  successAndBack() {
    this.modalSend.hide();
    this.back();
  }

  /**
	 * set the colors styles for the client
	 * @return {void}
	 */
  setHeaderStyle() {
    if (this.client && this.client.colors) {
      const styles = {
        'background-color': this.client.colors.main,
        'color': this.client.colors.fontLight,
        'opacity' : 1
      };
      return styles;
    }
    return {};
  }

  getChoiceValue(choice) {
    return JSON.stringify(choice);
  }

  submitFeedback() {
    const questionAnswer = {};
    for (let i = 0, a = this.page.questions; i < a.length; i++) {
      questionAnswer[a[i]._] = a[i].answer ? a[i].answer : null;
    }
    if (this.page.choiceQuestion && this.page.choiceQuestion.active && this.page.choiceQuestion.answer) {
      questionAnswer['choiceQuestion'] = { question: this.page.choiceQuestion.question,
        answer: JSON.parse(this.page.choiceQuestion.answer) };
    }
    const additionalText = this.page.additionalTextForm ?
      {labelName: this.page.additionalTextForm.labelName._, answer: this.additionalTextValue} : '';
    const feedback = new Feedback(Number(this.page.id), this.roomNumber, questionAnswer, additionalText);
    this.feedbackService.storeFeedback(feedback)
      .subscribe(data => {
        if (this.page.feedbackRecipients.length > 0) {
          this.sendEmail();
        } else {
          this.feedbackSuccessfullySend = true;
          this.modalSend.show();
          this._resetForm();
        }
      }, error => {
        this.feedbackSuccessfullySend = false;
        this.modalSend.show();
        this._resetForm();
    });
  }

  sendEmail() {
    this.sending = true;

    let message = '<style>' +
    '.entry {padding: 10px 5px;}' +
    '.answer {padding: 5px 10px; border-radius: 10px; font-weight: bold;}' +
    '.answer-5 {background-color: rgba(51,204,0,0.5);}' +
    '.answer-4 {background-color: rgba(153,230,0,0.5);}' +
    '.answer-3 {background-color: rgba(255,255,0,0.5);}' +
    '.answer-2 {background-color: rgba(255,128,0,0.5);}' +
    '.answer-1 {background-color: rgba(255,0,0,0.5);}' +
    '</style><div><br>' +
    'Ein Lobbit Nutzer hat gerade sein Feedback abgegeben. Die Bewertung der Fragen geht von 5(gut) bis 1(schlecht).' +
    'Fragen mit den dazugehörigen Antworten werden im folgenden aufgelistet:<br><br>';
    for (const question of this.page.questions) {
      const answer = question.answer ?
        ' </span><span class=\'answer answer-' + question.answer + '\'>' + question.answer + '</span></div>' :
        '';
      message += '<div class=\'entry\'><span>' + this.i18nPipe.transform(question, false) + answer;
    }
    message += '</div><br>';
    if (this.page.askForRoomNumber && this.roomNumber) {
      message += 'Raumnummer des Nutzers: ' + this.roomNumber + '<br><br>';
    }

    if (this.page.choiceQuestion && this.page.choiceQuestion.answer) {
      message += '<span>' + this.i18nPipe.transform(this.page.choiceQuestion.question, false) + '</span> <span>'
        + this.i18nPipe.transform(JSON.parse(this.page.choiceQuestion.answer), false) + '</span><br><br>';
    }

    if (this.additionalTextValue) {
      message += 'Zusätzlicher Text: ' + this.additionalTextValue + '<br><br>';
    }

    const senderInfoTemplate = 'Geräte Informationen:<br>' +
    'Client: {0}<br>' +
    'Geräte-Typen: {1}<br>' +
    'MAC-Adresse: {2}';

    const subjectProvided: boolean = this.page.emailSubject && this.page.emailSubject !== '';

    const email = new Email(Number(this.page.id), subjectProvided ? this.page.emailSubject : 'Feedback', message, senderInfoTemplate);

    this.emailService.sendEmail(email)
      .subscribe(data => {
        this.sending = false;
        this.feedbackSuccessfullySend = true;
        this.modalSend.show();
        this._resetForm();
      }, error => {
        this.sending = false;
        this.feedbackSuccessfullySend = false;
        this.modalSend.show();
        this._resetForm();
    });
  }

  keyUp($event) {
    if ($event && $event.path && $event.path[0]) {
      this.roomNumber = $event.path[0].value;
    }
  }

  ngOnDestroy() {
    if (this.backToMenu$) {
      this.backToMenu$.unsubscribe();
    }
  }

}
