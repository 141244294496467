import { Component, OnInit, Input } from '@angular/core';
import * as moment from 'moment';
import { LanguagesService } from '../../services';
import { interval } from 'rxjs';

@Component({
  moduleId: module.id,
  selector: 'app-time',
  templateUrl: 'time.component.html',
  styleUrls: ['time.component.css']
})

/**
 * Logic to display the time in the header
 */
export class TimeComponent implements OnInit {
  h = '';
  m = '';
  day = '';
  date = '';
  @Input()
  onlyTime: boolean;

  constructor(private languagesService: LanguagesService) {
  }

  ngOnInit() {
    moment.locale('de');
    this.tick();
    interval(10000).subscribe(() => {
      this.tick();
    });
    this.languagesService.currentLanguage$.subscribe(lang => {
      const l: string = <string>lang;
      if (l !== '_') {
        moment.locale(l.slice(0, 2));
        this.tick();
      }
    });
  }

  /**
	 * set actual time
	 * @return {void}
	 */
  tick() {
    const time = moment();
    this.day = time.format('dddd');
    this.h = time.format('HH');
    this.m = time.format('mm');
    this.date = time.format('DD. MMMM YYYY');
  }

}
