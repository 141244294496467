import { Client, ClientService } from './../services/client.service';
import { ScreensaverService } from './../services/screensaver.service';
import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { Advertising } from '@app/services';
import { SwiperComponent } from 'ngx-useful-swiper';

@Component({
  selector: 'app-ad-screensaver',
  templateUrl: './ad-screensaver.component.html',
  styleUrls: ['./ad-screensaver.component.css']
})
export class AdScreensaverComponent implements OnInit, OnDestroy {
  @ViewChild('swiper') swiper: SwiperComponent;

  imgHeight = 100;
  advertisements: Array<Advertising>;
  interval = 15;

  config = {
    paginationClickable: false,
    loop: false,
    spaceBetween: 15,
  };

  swiper$: any;
  client: Client;
  private redirectScreensaver = false;

  constructor(
    private screensaverService: ScreensaverService,
    private clientService: ClientService,
  ) { }

  ngOnInit() {
    this.clientService.getClient().subscribe(client => {
      this.client = client;
    });
    this.imgHeight = window.innerHeight;
    this.advertisements = this.screensaverService.screensaverAds;
    this.setAdChangeTimeout();
  }

  backToMenu() {
    this.screensaverService.closeAds();
  }

  private setAdChangeTimeout() {
    const milliSecInterval = this.interval * 1000;
    this.swiper$ = setTimeout(() => {
      if (this.redirectScreensaver) {
        this.redirectScreensaver = false;
        this.screensaverService.redirectAdsToWidget();
      }
      if (this.swiper && !this.swiper.swiper.slideNext()) {
        this.swiper.swiper.slideTo(0, milliSecInterval / 2 > 500 ? 500 : milliSecInterval / 2);
      }
      if (this.swiper.swiper.isEnd) {
        this.redirectScreensaver = true;
      }
      this.setAdChangeTimeout();
    }, milliSecInterval);
  }

  setHeaderStyle() {
    if (this.client && this.client.colors) {
      const styles = {
        'color': this.client.colors.fontLight,
      };
      return styles;
    }
    return {};
  }

  ngOnDestroy(): void {
    clearTimeout(this.swiper$);
  }
}
