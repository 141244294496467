<header class="row" *ngIf="client && isVerticalDisplay">
  <div class="col client-logo">
    <img class="img-fluid" [src]="logo | i18n" [alt]="client.title | i18n" (click)="modalService.backToMenu()">
  </div>
  <div class="col">
    <app-time onlyTime="true"></app-time>
  </div>
  <div *ngIf="currentWeather" class="col weather-info" (click)="showForecast()">
    <span class="temperature">{{ currentWeather.temp | number:'1.0-0' }}°C</span>
    <div class="weather-icon-block">
      <div class="weather-icon-block" *ngFor="let weather of currentWeather.weather | slice:0:1">
        <img *ngIf="weather.icon" class="weather-icon img-fluid" src="https://openweathermap.org/img/wn/{{weather.icon}}.png">
      </div>
    </div>
  </div>
</header>
