<div class="watch">

  <div class="controls">
    <span (click)="toggleHourMinute()" [style.font-weight]="hourMode ? 'bold' : 'normal'">{{currentHour}}</span>:
    <span (click)="toggleHourMinute()" [style.font-weight]="!hourMode ? 'bold' : 'normal'">{{currentMinute | number:'2.0-0'}}</span>
  </div>

  <div class="face">
    <div class="numbers">
      <div *ngIf="hourMode">
        <div *ngFor="let primary of primarys" [@selectedState]="primary.state" [ngClass]="getNumeralClassNames('hour', primary)"
          [class.primary]="true" (click)="selectHour(primary)">{{primary.number}}</div>
        <div *ngFor="let secondary of secondarys" [@selectedState]="secondary.state" [ngClass]="getNumeralClassNames('hour', secondary)"
          [class.secondary]="true" (click)="selectHour(secondary)">{{secondary.number}}</div>
      </div>
      <div *ngIf="!hourMode">
        <div *ngFor="let minute of minutes" [@selectedState]="minute.state" [ngClass]="getNumeralClassNames('minute', minute)" [class.minute]="true"
          (click)="selectMinute(minute)">{{minute.number | number:'2.0-0'}}</div>
      </div>
    </div>
    <div class="hands">
      <div *ngIf="hourMode">
        <div class="hand hand__hour" [ngClass]="getHandClassNames('hour', currentHourIn12)"></div>
      </div>
      <div *ngIf="!hourMode">
        <div class="hand hand__minute" [ngClass]="getHandClassNames('minute', currentMinute)"></div>
      </div>
    </div>
    <div class="circle"></div>
  </div>

</div>
