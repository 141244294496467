import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import * as moment from 'moment';
import {
  EventsService,
  ModalService,
  PagesService,
  Page,
  Event,
  UtilsService,
  AuthService
} from '../services';
import { I18nPipe } from '../shared/pipes/i18n.pipe';

@Component({
  selector: 'app-events',
  templateUrl: './events.component.html',
  styleUrls: ['./events.component.css']
})
export class EventsComponent implements OnInit, OnDestroy {
  page: Page;
  events: Event[] = [];
  loading = true;
  hasLocation = false;
  private backToMenu$: any;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private eventsService: EventsService,
    private modalService: ModalService,
    private pagesService: PagesService,
    private i18nPipe: I18nPipe,
    private utilsService: UtilsService,
    private authService: AuthService
  ) {}

  ngOnInit() {
    const contentId = this.route.snapshot.params['id'];
    this.pagesService.getPageByID(contentId).subscribe(page => {
      this.page = page;
      this.eventsService.events$.subscribe(events => {
        this.loading = false;
        const eventOption = this.page.eventOption;
        const eventId = this.page.event;
        const eventListOption = this.page.eventListOption;
        const eventDateRange = this.page.eventDateRange;
        const eventTags = this.page.eventTags;
        this.prepareEvents(events);
        if (
          eventOption === 'SINGLE' &&
          eventId &&
          this.getEventById(events, eventId)
        ) {
          this.events.push(this.getEventById(events, eventId));
        } else if (eventOption === 'LIST') {
          switch (eventListOption) {
            case 'CURRENT_DAY': {
              this.events = this.getEventsFromDateRange(events, [
                new Date(),
                new Date()
              ]);
              break;
            }
            case 'CURRENT_WEEK': {
              this.events = this.getEventsFromDateRange(events, [
                moment()
                  .startOf('week')
                  .toDate(),
                moment()
                  .endOf('week')
                  .toDate()
              ]);
              break;
            }
            case 'CURRENT_MONTH': {
              this.events = this.getEventsFromDateRange(events, [
                moment()
                  .startOf('month')
                  .toDate(),
                moment()
                  .endOf('month')
                  .toDate()
              ]);
              break;
            }
            case 'DATERANGE': {
              this.events = this.getEventsFromDateRange(events, eventDateRange);
              break;
            }
            case 'NEXT_DAY': {
              this.events = this.getEventsFromDateRange(events, [
                moment()
                  .add(1, 'days')
                  .toDate(),
                moment()
                  .add(1, 'days')
                  .toDate()
              ]);
              break;
            }
            case 'NEXT_WEEK': {
              this.events = this.getEventsFromDateRange(events, [
                moment()
                  .add(1, 'weeks')
                  .startOf('week')
                  .toDate(),
                moment()
                  .add(1, 'weeks')
                  .endOf('week')
                  .toDate()
              ]);
              break;
            }
            case 'NEXT_MONTH': {
              this.events = this.getEventsFromDateRange(events, [
                moment()
                  .add(1, 'months')
                  .startOf('month')
                  .toDate(),
                moment()
                  .add(1, 'months')
                  .endOf('month')
                  .toDate()
              ]);
              break;
            }
          }
          if (eventTags) {
            this.events = this.filterEventByTags(this.events, eventTags);
          }
          if (eventListOption === 'CURRENT_DAY') {
            this.events.sort(this.compareTimeEvents);
          } else {
            this.events.sort(this.compareDateTimeEvents);
          }
          // Check if an event has a location. If not the location column will not be displayed.
          const numberOfEventsWithLocation = this.events.reduce(
            (hasLocationVal, currentVal) => {
              if (this.i18nPipe.transform(currentVal.location, false)) {
                hasLocationVal = hasLocationVal + 1;
              }
              return hasLocationVal;
            },
            0
          );
          if (numberOfEventsWithLocation > 0) {
            this.hasLocation = true;
          }
        }
      });
    });

    this.backToMenu$ = this.modalService.backToMenu$.subscribe(res => {
      this.back();
    });
  }

  private compareTimeEvents(event1, event2) {
    if (event1.timeFrom && event2.timeFrom) {
      const basetime = new Date(1970, 0, 1);
      const event1Time = new Date(event1.timeFrom);
      const event2Time = new Date(event2.timeFrom);
      const e1time = new Date(basetime);
      const e2time = new Date(basetime);
      e1time.setHours(event1Time.getHours(), event1Time.getMinutes());
      e2time.setHours(event2Time.getHours(), event2Time.getMinutes());
      if (e1time < e2time) {
        return -1;
      }
      if (e1time > e2time) {
        return 1;
      }
    } else if (!event1.timeFrom && event2.timeFrom) {
      return -1;
    } else if (!event2.timeFrom && event1.timeFrom) {
      return 1;
    }
    return 0;
  }

  private compareDateTimeEvents(event1, event2) {
    if (event1.dateRange && event2.dateRange && event1.timeFrom && event2.timeFrom) {
      const temp_e1date = new Date(event1.dateRange[0]);
      const temp_e2date = new Date(event2.dateRange[0]);

      const e1date = new Date(temp_e1date.getFullYear(), temp_e1date.getMonth(), temp_e1date.getDate());
      const e2date = new Date(temp_e2date.getFullYear(), temp_e2date.getMonth(), temp_e2date.getDate());

      const basetime = new Date(1970, 0, 1);
      const event1Time = new Date(event1.timeFrom);
      const event2Time = new Date(event2.timeFrom);
      const e1time = new Date(basetime);
      const e2time = new Date(basetime);
      e1time.setHours(event1Time.getHours(), event1Time.getMinutes());
      e2time.setHours(event2Time.getHours(), event2Time.getMinutes());

      if (e1date < e2date) {
        return -1;
      }
      if (e1date > e2date) {
        return 1;
      }
      if (e1date.getDate()  === e2date.getDate() ) {
        if (e1time < e2time) {
          return -1;
        }
        if (e1time > e2time) {
          return 1;
        }
      }
    } else if (event1.timeFrom && event2.timeFrom) {
      const basetime = new Date(1970, 0, 1);
      const event1Time = new Date(event1.timeFrom);
      const event2Time = new Date(event2.timeFrom);
      const e1time = new Date(basetime);
      const e2time = new Date(basetime);
      e1time.setHours(event1Time.getHours(), event1Time.getMinutes());
      e2time.setHours(event2Time.getHours(), event2Time.getMinutes());
      if (e1time < e2time) {
        return -1;
      }
      if (e1time > e2time) {
        return 1;
      }
    } else if (event1.dateRange && event2.dateRange) {
      const temp_e1date = new Date(event1.dateRange[0]);
      const temp_e2date = new Date(event2.dateRange[0]);

      const e1date = new Date(temp_e1date.getFullYear(), temp_e1date.getMonth(), temp_e1date.getDate());
      const e2date = new Date(temp_e2date.getFullYear(), temp_e2date.getMonth(), temp_e2date.getDate());
      if (e1date < e2date) {
        return -1;
      }
      if (e1date > e2date) {
        return 1;
      }
      if (e1date.getDate() === e2date.getDate()) {
        if (!event1.timeFrom && event2.timeFrom) {
          return -1;
        }
        if (!event2.timeFrom && event1.timeFrom) {
          return 1;
        }
        return 0;
      }
    }
    return 0;
  }


  private getEventById(events: any, eventId: number) {
    const filteredEvents = events.filter(event => event.id === +eventId);
    if (filteredEvents && filteredEvents.length !== 0) {
      return filteredEvents[0];
    }
  }

  private getEventsFromDateRange(events: any, dateRange: Date[]) {
    return events.filter(event => {
      if (event.dateRange) {
        if (
          moment(event.dateRange[0]).isBefore(dateRange[1], 'day') ||
          moment(event.dateRange[0]).isSame(dateRange[1], 'day')
        ) {
          if (
            moment(event.dateRange[1]).isSame(dateRange[0], 'day') ||
            moment(event.dateRange[1]).isAfter(dateRange[0], 'day')
          ) {
            return true;
          }
        }
      }
      return false;
    });
  }

  private filterEventByTags(events: any, tags: string[]) {
    if (!tags || tags.length === 0) {
      return events;
    }
    return events.filter(event => {
      if (!event.tags || event.tags.length === 0) {
        return false;
      }
      for (const tag of tags) {
        if (!event.tags.includes(tag)) {
          return false;
        }
      }
      return true;
    });
  }

  private prepareEvents(events): void {
    const amazonAwsBucketName: string = this.authService.authObject
      ? this.authService.authObject.awsBucketName
      : '';
    for (const event of events) {
      for (const locale of Object.keys(event.description)) {
        // If it is no object it is the image ID
        if (typeof event.description[locale] !== 'object') {
          event.description[locale] = this.utilsService.replaceAwsBucketUrl(event.description[locale], amazonAwsBucketName);
        }
      }
    }
  }

  /**
   * back
   * return to the main navigation
   * @return {void}
   */
  back() {
    this.router.navigate(['/']);
  }

  ngOnDestroy() {
    if (this.backToMenu$) {
      this.backToMenu$.unsubscribe();
    }
  }
}
