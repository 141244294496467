import { Pipe, PipeTransform, Injectable } from '@angular/core';
import { LanguagesService } from '../../services/languages.service';

/**
 * This Pipe handles the translation of text and images
 */
@Pipe({
  name: 'i18n',
  pure: false
})
@Injectable()
export class I18nPipe implements PipeTransform {
  constructor(
    private languagesSevice: LanguagesService
  ) { }
  transform(value: any, isImage: any): string {
    const lang = this.languagesSevice.currentLanguage;

    if (!value) {
      return '';
    }

    /** Check if value is an image object */
    if (isImage) {
      if (value && value[lang] && value[lang].value) {
        return value[lang].value;
      } else if (value && value['_'] && value['_'].value) {
        return value['_'].value;
      } else if (value && value['value']) {
        return value['value'];
      } else {
        return '';
      }
    }

    /** Continue checking if value is an i18n object */
    if (value && typeof value !== 'undefined' && value[lang] && value !== '' && value[lang] !== '') {
      return value[lang];
    } else if (value && value['_']) {
      return value['_'];
    } else if (value && typeof value === 'string') {
      return value;
    }

    /**
		 * Handle old data structure before i18n.
		 * Check if value is a string
		 */
    if (value && typeof value === 'string') {
      return value;
    }

  }

}
