<div class="events-component-wrapper">

  <app-widget-header [header]="page ? (page.title | i18n) : null" (onClose)="back()"></app-widget-header>
  <div class="events-content-wrapper">
    <div class="container-fluid" *ngIf="!loading; else eventsLoading">
      <div class="row" *ngIf="page && events && events.length > 0; else noEvents">
        <div class="col">
          <div *ngIf="page.eventOption === 'SINGLE'; else eventList" class="event-single-container">
            <h2 *ngIf="!events[0].canceled" class="event-title">{{events[0].title | i18n}}</h2>
            <h2 *ngIf="events[0].canceled" class="event-title canceled-title">{{ events[0].title | i18n }} - {{ 'events.canceled' | translate }}</h2>
            <div class="d-flex justify-content-between">
              <div *ngIf="events[0].location | i18n">
                <h2 class="event-subtitle">
                  <i class="material-icons">location_on</i>{{'events.location' | translate}}: {{ events[0].location | i18n }}</h2>
              </div>
              <div>
                <h2 class="event-subtitle">
                  <i class="material-icons">watch_later</i>
                  {{'events.time' | translate}}:
                  <span *ngIf="events[0].dateRange && (events[0].dateFrom != events[0].dateTo)">
                    {{ (events[0].dateRange[0] ? (events[0].dateRange[0] | date:"dd.MM.yyyy") : '') }} {{ (events[0].timeFrom ? (events[0].timeFrom
                    | date:"HH:mm") : '') }} - {{ (events[0].dateRange[1] ? (events[0].dateRange[1] | date:"dd.MM.yyyy")
                    : '') }} {{ (events[0].timeTo ? (events[0].timeTo | date:"HH:mm") : '') }}
                  </span>
                  <span *ngIf="events[0].dateRange && (events[0].dateFrom == events[0].dateTo)">
                    {{ (events[0].dateRange[0] ? (events[0].dateRange[0] | date:"dd.MM.yyyy") : '') }} {{ (events[0].timeFrom ? (events[0].timeFrom
                    | date:"HH:mm") : '') }} - {{ (events[0].timeTo ? (events[0].timeTo | date:"HH:mm") : '') }}
                  </span>
                </h2>
              </div>
            </div>
            <div class="event-description fr-view" [innerHTML]="(events[0].description | i18n | safe )"></div>
          </div>

          <ng-template #eventList>
            <div class="event-headline" [ngSwitch]="page.eventListOption">
              <h2 *ngSwitchCase="'CURRENT_DAY'">{{'events.headline.currentDay' | translate}}</h2>
              <h2 *ngSwitchCase="'CURRENT_WEEK'">{{'events.headline.currentWeek' | translate}}</h2>
              <h2 *ngSwitchCase="'CURRENT_MONTH'">{{'events.headline.currentMonth' | translate}}</h2>
              <h2 *ngSwitchCase="'DATERANGE'">{{'events.headline.dateRange' | translate}}</h2>
            </div>
            <div class="event-list row" *ngFor="let event of events" style="box-sizing: border-box;">
              <div [class]="page.eventListOption !== 'CURRENT_DAY' ? 'col-lg-6 col-md-12' : 'col-lg-3 col-md-12'">
                <div class="row">
                  <div class="resp-col-fix" *ngIf="page.eventListOption !== 'CURRENT_DAY'">
                    <span [class]="event.canceled ? 'canceled-title' : ''" *ngIf="events[0].dateRange && (event.dateFrom != event.dateTo)">
                      {{ (event.dateRange[0] ? (event.dateRange[0] | date:"dd.MM.yyyy") : '') }} - {{ (event.dateRange[1] ? (event.dateRange[1] | date:"dd.MM.yyyy")
                      : '') }}
                    </span>
                    <span [class]="event.canceled ? 'canceled-title' : ''" *ngIf="events[0].dateRange && (event.dateFrom == event.dateTo)">
                      {{ (event.dateRange[0] ? (event.dateRange[0] | date:"dd.MM.yyyy") : '') }}
                    </span>
                  </div>
                  <div class="resp-col-fix" style="padding-left: 10px">
                    <strong [class]="event.canceled ? 'canceled-title' : ''" *ngIf="event.timeFrom || event.timeTo">{{ (event.timeFrom ? (event.timeFrom | date:"HH:mm") : '') }} - {{ (event.timeTo ? (event.timeTo | date:"HH:mm") : '') }}</strong>
                  </div>
                </div>
                <div>
                  <span [class]="event.canceled ? 'canceled-title' : ''">{{ event.location | i18n }}</span>
                </div>
              </div>

              <div [class]="page.eventListOption !== 'CURRENT_DAY' ? ' col-lg-6 col-md-12' : 'col-lg-9 col-md-12'">
                <h2 *ngIf="!event.canceled" class="event-title">{{ event.title | i18n }}</h2>
                <h2 *ngIf="event.canceled" class="event-title canceled-title">{{ event.title | i18n }} - {{ 'events.canceled' | translate }}</h2>
                <div [class]="event.canceled ? 'col event-description canceled-title fr-view' : 'col event-description fr-view'" [innerHTML]="(event.description | i18n )"></div>
              </div>
            </div>

          </ng-template>
        </div>
      </div>
      <ng-template #noEvents>
        <h2 class="message">{{ 'events.noEvents' | translate }}</h2>
      </ng-template>
    </div>
    <ng-template #eventsLoading>
      <h2 class="message">{{ 'events.loading' | translate }}</h2>
    </ng-template>
  </div>
</div>
