import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModalModule } from 'ngx-bootstrap';
import { CustomModalComponent } from './custom-modal.component';
import { SharedModule } from '../shared';
import { WidgetHeaderModule } from '../widget-header/widget-header.module';
import { ClientService, LanguagesService, ModalService, UtilsService } from '../services';

@NgModule({
  imports: [
    CommonModule, SharedModule, WidgetHeaderModule, ModalModule.forRoot(),
  ],
  declarations: [CustomModalComponent],
  exports: [CustomModalComponent],
  providers: [ClientService, LanguagesService, ModalService, UtilsService]
})
export class CustomModalModule { }
