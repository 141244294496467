<div class="dish-set-component-wrapper">

  <app-widget-header [header]="page ? (page.title | i18n) : null" (onClose)="back()"></app-widget-header>
  <div class="dish-set-content-wrapper">
    <div class="container-fluid" *ngIf="!loading; else dishSetsLoading">
      <div class="row" *ngIf="page && dishSets && dishSets.length; else noDishSet" style="margin: 0px;">
        <div class="col-sm-12" style="padding: 0px;">

          <div *ngFor="let day of days">
            <h1 class="menu-heading">{{day.dayString}} - {{day.day | date:"dd.MM.yyyy"}}</h1>
            <table>
              <thead>
                <tr>
                  <ng-template *ngFor="let dishSet of dishSets" [ngIf]="isDishSetValidIn(dishSet, day.day)">
                    <th>
                      {{dishSet.name | i18n}}
                    </th>
                  </ng-template>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <ng-template *ngFor="let dishSet of dishSets" [ngIf]="isDishSetValidIn(dishSet, day.day)">
                    <td>
                      <p *ngFor="let dish of dishSet.dishes">
                        <span *ngIf="!dish.isMainDish">{{dish.name | i18n}}</span>
                        <strong *ngIf="dish.isMainDish">{{dish.name | i18n}}</strong>
                      </p>
                    </td>
                  </ng-template>
                </tr>
              </tbody>
            </table>
          </div>

        </div>
      </div>
      <ng-template #noDishSet>
        <h2 class="message">{{'dish-sets.no-dish-set-available' | translate}}</h2>>
      </ng-template>
    </div>
    <ng-template #dishSetsLoading>
      <h2 class="message">{{ 'dish-sets.loading' | translate }}</h2>
    </ng-template>
  </div>
</div>
