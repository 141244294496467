import { UtilsService } from './../services/utils.service';
import { AdvertisingPartnerService } from './../services/advertising-partner.service';
import { SwiperModule } from 'ngx-useful-swiper';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { MapComponent } from './map.component';
import { MapRoutingModule } from './map-routing.module';
import { SharedModule } from '../shared';
import { WidgetHeaderModule } from '../widget-header/widget-header.module';
import { CustomModalModule } from '../custom-modal/custom-modal.module';
import { ClientService, LanguagesService, ModalService, PlacesService, AuthService } from '../services';
import { AgmCoreModule } from '@agm/core';
import { AdMapBannerComponent } from './ad-map-banner/ad-map-banner.component';

@NgModule({
  imports: [
    CommonModule, TranslateModule.forChild(),
    MapRoutingModule, SharedModule, WidgetHeaderModule, CustomModalModule, AgmCoreModule, SwiperModule
  ],
  declarations: [MapComponent, AdMapBannerComponent],
  exports: [MapComponent],
  providers: [ClientService, LanguagesService, ModalService, PlacesService, AuthService, AdvertisingPartnerService, UtilsService]
})
export class MapModule { }
