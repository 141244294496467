import { Component, OnInit } from '@angular/core';
import { WidgetBarComponent } from '../widget-bar.component';
import { ActivatedRoute } from '@angular/router';
import { PagesService, ClientService } from '../../../services';

@Component({
  selector: 'app-horizontal-widget-bar',
  templateUrl: './horizontal-widget-bar.component.html',
  styleUrls: ['./horizontal-widget-bar.component.css']
})
export class HorizontalWidgetBarComponent extends WidgetBarComponent implements OnInit {

  constructor(
    protected route: ActivatedRoute,
    protected pagesService: PagesService,
    protected clientService: ClientService
  ) {
    super(route, pagesService, clientService);
  }

  ngOnInit() {
    super.ngOnInit();
  }

}
