<div #adContent class="advertising-container" [ngStyle]="{height: '100%', width: '100%'}">

  <swiper #swiper [config]="config">
    <div class="swiper-wrapper">
      <div class="swiper-slide" *ngFor="let ad of advertisements">
        <img #imgContent class="image" [src]="ad?.screensaver.image" (click)="backToMenu()" 
        [style.width.%]='100' [style.height.px]="imgHeight">
        <div class="exit-button" style="z-index: 12;">
          <i class="material-icons close-icon icon-centered" (click)="backToMenu()" [ngStyle]="setHeaderStyle()">close</i>
        </div>
      </div>
    </div>
  </swiper>
</div>
