<div class="weather-wrapper">
  <app-widget-header (onClose)="back()"></app-widget-header>
  <div class="weather-content" *ngIf="currentWeather">
    <div class="row">
      <div class="col-md-2">
        <div>
          <img class="weather-icon" src="/assets/weather-icons/{{currentWeather.weather[0].icon}}.svg">
        </div>
      </div>
      <div class="col-md-6">
        <div>
          <div class="temperature">
            {{ currentWeather.temp | number:'1.0-0' }}°C
          </div>
          <div class="description">
            <span>{{currentWeather.weather[0].description}}</span>
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="sun-info">
          <div class="sunrise">
            <img class="sun-icon" src="/assets/weather-icons/Sunrise.svg">
            <span>{{sunrise}}</span>
          </div>
          <div class="sunset">
            <img class="sun-icon" src="/assets/weather-icons/Sunset.svg">
            <span>{{sunset}}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="row" style="height:20%;">

    </div>
    <div class="row">
      <div class="col-md-8">
        <div class="town-name">
          {{currentWeather.name}}
        </div>
      </div>
      <div class="col-md-4">
        <div class="wind-info">
          <div class="humidity">
            Luftfeuchtigkeit: {{currentWeather.main.humidity}} %
          </div>
          <div class="wind-speed">
            Windgeschwindigkeit: {{currentWeather.wind.speed}} m/s
          </div>
          <div class="wind-direction" *ngIf="currentWeather.wind.deg">
            Windrichdtung: {{this.windDirection}}
          </div>
        </div>
      </div>
    </div>
  </div>

</div>
