import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  OnChanges
} from '@angular/core';
import {
  trigger,
  state,
  style,
  transition,
  animate
} from '@angular/animations';

class TimeObject {
  number: number;
  state: string;
}

@Component({
  selector: 'app-timepicker',
  templateUrl: 'timepicker.component.html',
  styleUrls: ['timepicker.component.css'],
  animations: [
    trigger('selectedState', [
      state('inactive', style({
        color: '#000',
        backgroundColor: '#f5f5f5',
        transform: 'scale(1)'
      })),
      state('active', style({
        color: '#f5f5f5',
        backgroundColor: 'rgb(133, 47, 46)',
        transform: 'scale(1.2)'
      })),
      transition('inactive => active', animate('200ms ease-in')),
      transition('active => inactive', animate('400ms ease-out'))
    ])
  ]
})

/**
 * Time picker component
 */
export class TimepickerComponent implements OnInit, OnChanges {
  @Input()
  currentHour: number;
  @Output()
  currentHourChange: EventEmitter<any> = new EventEmitter();

  @Input()
  currentMinute: number;
  @Output()
  currentMinuteChange: EventEmitter<any> = new EventEmitter();

  currentHourIn12 = 12;
  hourMode = true;
  ticks: Array<number> = new Array<number>();
  primarys: Array<TimeObject> = new Array<TimeObject>();
  secondarys: Array<TimeObject> = new Array<TimeObject>();
  minutes: Array<TimeObject> = new Array<TimeObject>();

  ngOnInit() {
    // Prefill ticks and minutes
    for (let index = 1; index < 61; index++) {
      // this.ticks.push(index);
      if ((index - 1) % 5 === 0) {
        const tm: TimeObject = { number: index - 1, state: 'inactive' };
        this.minutes.push(tm);
      }
    }
    // Prefill primary and secondary hours
    for (let index = 1; index <= 12; index++) {
      const tp: TimeObject = { number: index, state: 'inactive' };
      const ts: TimeObject = { number: index + 12, state: 'inactive' };
      this.primarys.push(tp);
      this.secondarys.push(ts);
    }
    if (!this.currentHour) {
      this.currentHour = 12;
    }
    if (!this.currentMinute) {
      this.currentMinute = 0;
    }
  }

  ngOnChanges(change: any) {
    if (change.currentHour && change.currentHour.currentValue) {
      this.currentHourIn12 = this.transferTo12(change.currentHour.currentValue);
    }
  }

  /**
	 * set the hour
	 * @param {TimeObject} h - The selected hour TimeObject
	 * @return {void}
	 */
  selectHour(h: TimeObject) {
    this.currentHour = h.number;
    this.currentHourChange.next(h.number);
    this.toggleHourMinute();
  }

  /**
	 * set the minutes
	 * @param {TimeObject} m - The selected minute TimeObject
	 * @return {void}
	 */
  selectMinute(m: TimeObject) {
    this.currentMinute = m.number;
    this.currentMinuteChange.next(m.number);
  }

  /**
	 * transform to 12h display format
	 * @param {number} h - A number representing the hours
	 * @return {number} The transformed number
	 */
  transferTo12(h: number): number {
    if (h > 12) {
      h -= 12;
    } else if (h === 0) {
      h = 12;
    }
    return h;
  }

  /**
	 * sets numeral classes after userinteraction
	 * @param {string} prefix - The class prefix.
	 * @param {TimeObject} time - The selected time
	 * @return {Object}
	 */
  getNumeralClassNames(prefix: string, time: TimeObject): Object {
    const obj = {};
    obj[prefix] = true;
    obj[prefix + '--' + time.number] = true;
    if ((time.number === this.currentHour && prefix === 'hour') || (time.number === this.currentMinute && prefix === 'minute')) {
      time.state = 'active';
    } else {
      time.state = 'inactive';
    }
    return obj;
  }

  /**
	 * sets hand classes after userinteraction
	 * @param {string} prefix - The class prefix.
	 * @param {number} counter - The selected number
	 * @return {Object}
	 */
  getHandClassNames(prefix: string, counter: number): Object {
    const obj = {};
    obj['hand'] = true;
    obj['hand__' + prefix] = true;
    obj['hand__' + prefix + '--' + counter] = true;
    if (this.hourMode && prefix === 'hour' || !this.hourMode && prefix === 'minute') {
      obj['active'] = true;
    } else {
      obj['inactive'] = true;
    }

    if (this.hourMode && prefix === 'hour' && this.currentHour <= 12) {
      obj['active-long'] = true;
    } else {
      obj['inactive'] = true;
    }
    return obj;
  }

  /**
	 * toggle between hour and minute selector
	 * @return {void}
	 */
  toggleHourMinute() {
    this.hourMode = !this.hourMode;
  }

}
