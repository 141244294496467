import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
// Component Imports
import { FlipCardWidgetComponent } from './flip-card-widget/flip-card-widget.component';
import { ContentWidgetComponent } from './content-widget/content-widget.component';
import { EmailWidgetComponent } from './email-widget/email-widget.component';
import { MapWidgetComponent } from './map-widget/map-widget.component';
import { SmsWidgetComponent } from './sms-widget/sms-widget.component';
import { StaticWidgetComponent } from './static-widget/static-widget.component';
import { WeatherCurrentWidgetComponent } from './weather-current-widget/weather-current-widget.component';
// Module Imports
import { CustomModalModule } from '../custom-modal/custom-modal.module';
import { SharedModule } from '../shared';
// Service Imports
import { ClientService, WeatherService, LanguagesService, UtilsService, ModalService } from '../services';
import { ContentWidgetVerticalComponent } from './vertical-layout/content-widget-vertical/content-widget-vertical.component';
import { EmailWidgetVerticalComponent } from './vertical-layout/email-widget-vertical/email-widget-vertical.component';
import { FlipCardWidgetVerticalComponent } from './vertical-layout/flip-card-widget-vertical/flip-card-widget-vertical.component';
import { MapWidgetVerticalComponent } from './vertical-layout/map-widget-vertical/map-widget-vertical.component';
import { SmsWidgetVerticalComponent } from './vertical-layout/sms-widget-vertical/sms-widget-vertical.component';
import { StaticWidgetVerticalComponent } from './vertical-layout/static-widget-vertical/static-widget-vertical.component';
import { WeatherCurrentWidgetVerticalComponent
  } from './vertical-layout/weather-current-widget-vertical/weather-current-widget-vertical.component';

@NgModule({
  imports: [
    CommonModule, SharedModule, CustomModalModule, TranslateModule.forChild()
  ],
  declarations: [
    ContentWidgetComponent, EmailWidgetComponent, FlipCardWidgetComponent, MapWidgetComponent,
    SmsWidgetComponent, StaticWidgetComponent, WeatherCurrentWidgetComponent, ContentWidgetVerticalComponent, EmailWidgetVerticalComponent,
    FlipCardWidgetVerticalComponent, MapWidgetVerticalComponent, SmsWidgetVerticalComponent, StaticWidgetVerticalComponent,
    WeatherCurrentWidgetVerticalComponent
  ],
  exports: [
    ContentWidgetComponent, EmailWidgetComponent, FlipCardWidgetComponent, MapWidgetComponent,
    SmsWidgetComponent, StaticWidgetComponent, WeatherCurrentWidgetComponent, ContentWidgetVerticalComponent, EmailWidgetVerticalComponent,
    FlipCardWidgetVerticalComponent, MapWidgetVerticalComponent, SmsWidgetVerticalComponent, StaticWidgetVerticalComponent,
    WeatherCurrentWidgetVerticalComponent
  ],
  providers: [ClientService, WeatherService, LanguagesService, UtilsService, ModalService]
})
export class WidgetsModule { }
