import { ModalService } from './../../services/modal.service';
import { UtilsService } from './../../services/utils.service';
import { Advertising, AdvertisingPartnerService } from './../../services/advertising-partner.service';
import { AuthService } from './../../services/auth.service';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { SwiperComponent } from 'ngx-useful-swiper';

@Component({
  selector: 'app-ad-map-banner',
  templateUrl: './ad-map-banner.component.html',
  styleUrls: ['./ad-map-banner.component.css']
})
export class AdMapBannerComponent implements OnInit {
  @Input() bannerHeight: number;
  @ViewChild('modal') modal: any;
  @ViewChild('swiper') swiper: SwiperComponent;

  backToMenu$: any;
  private advertisements: Array<Advertising>;
  private filteredAdvertisements: Array<Advertising>;

  interval = 15;

  config = {
    paginationClickable: false,
    loop: false,
    spaceBetween: 15,
    direction: 'vertical'
  };

  constructor(
    private authService: AuthService,
    private advertisingPartnerService: AdvertisingPartnerService,
    private utils: UtilsService,
    private modalService: ModalService
  ) { }

  ngOnInit() {
    const clientId: string = this.authService.authObject.clientId;
    this.advertisingPartnerService.getAllGlobal().subscribe(globalAds => {
      const tempAds = new Array<Advertising>();
      globalAds.forEach(ad => {
        if (ad.activated && ad.mapBanner) {
          ad.mapBanner.image = this.utils.buildAmazonawsURL('0', ad.mapBanner.image);
          tempAds.push(ad);
        }
      });

      this.advertisingPartnerService.getAll().subscribe(ads => {
        ads.forEach(ad => {
          if (ad.activated && ad.mapBanner) {
            ad.mapBanner.image = this.utils.buildAmazonawsURL(clientId, ad.mapBanner.image);
            tempAds.push(ad);
          }
        });

        this.advertisements = tempAds;
        this.filteredAdvertisements = tempAds.filter(ad =>
          this.advertisingPartnerService.verifyAdvertisement(ad) && this.isActive(ad)
        );
        this.setAdChangeTimeout();
      });
    });

    this.backToMenu$ = this.modalService.backToMenu$.subscribe(res => {
      if (this.modal) {
        this.modal.modal.hide();
      }
    });
  }

  redirectPartnerPage(ad: Advertising) {
    if (this.utils.isValid(ad.mapBanner.websiteUrl) && ad.mapBanner.websiteUrl !== '') {
      this.modal.showIframeModal({ '_': ad.mapBanner.websiteUrl }, ad.name);
    }
  }

  private setAdChangeTimeout() {
    const milliSecInterval = this.interval * 1000;
    setTimeout(() => {
      this.filteredAdvertisements = this.advertisements.filter(ad =>
        this.advertisingPartnerService.verifyAdvertisement(ad) && this.isActive(ad)
      );
      if (this.swiper && !this.swiper.swiper.slideNext()) {
        this.swiper.swiper.slideTo(0, milliSecInterval / 2 > 500 ? 500 : milliSecInterval / 2);
      }
      this.setAdChangeTimeout();
    }, milliSecInterval);
  }

  getFilteredAdvertisements(): Array<any> {
    if (!this.utils.isValid(this.filteredAdvertisements)) {
      return [];
    }
    return this.filteredAdvertisements;
  }

  private isActive(ad: Advertising): boolean {
    return ad.mapBanner && ad.mapBanner.active;
  }
}
