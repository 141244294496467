import { Injectable } from '@angular/core';
import { HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';
import { ApiService } from './api.service';

/**
 * Service class to send email
 */
@Injectable()
export class EmailService {
  constructor(
    private authService: AuthService,
    private api: ApiService
  ) { }

  sendEmail(body: Email): Observable<HttpResponse<any>> {
    const token: string = this.authService.authObject.token;
    if (!token || token === '') {
      console.warn('No valid token ' + token + '! Could not send Email.');
      return;
    }

    // make request and return the response Observable
    return this.api.postWithJsonContent('/email/send', body);
  }

}

export class Email {
  constructor(
    public contentId: number,
    public subject: string,
    public message: string,
    public senderInfoTemplate: string
  ) { }
}
