import { Component, Input, OnInit, OnDestroy, AfterViewInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { UtilsService, ClientService, ModalService, Page } from '@app/services';
import { WidgetType } from '../widget-type';

@Component({
  selector: 'app-flip-card-widget',
  templateUrl: 'flip-card-widget.component.html',
  styleUrls: ['flip-card-widget.component.css']
})
/**
 * Flipcard Widget
 */
export class FlipCardWidgetComponent implements OnInit, AfterViewInit, OnDestroy {

  @Input()
  page: any;
  bg: string;
  client: any;
  @ViewChild('modal') modal: any;
  @ViewChild('transition') transition;
  @ViewChild('card') card;
  transitionHeight = 80;
  cardHeight = 300;
  private backToMenu$: any;
  constructor(
    protected router: Router,
    protected clientService: ClientService,
    protected modalService: ModalService,
    protected utils: UtilsService
  ) {
    this.clientService.getClient().subscribe(client => {
      this.client = client;
    });

  }

  ngOnInit() {
    this.backToMenu$ = this.modalService.backToMenu$.subscribe(res => {
      this.modal.modal.hide();
    });
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.transitionHeight = this.transition.nativeElement.offsetHeight;
      this.cardHeight = this.card.nativeElement.offsetHeight;
    }, 0);
  }

  /**
	 * toggleActive
	 * toggles the state of the card
	 * @param {Page} page
	 * @param {any} e - The event
	 * @return {void}
	 */
  toggleActive(page: Page, e: any) {
    // Only toggle when no link was clicked
    if (e.target && e.target.localName && e.target.localName !== 'a') {
      if (!page.active) {
        page.active = true;
      } else {
        page.active = false;
        setTimeout(() => {
          this.cardHeight = this.card.nativeElement.offsetHeight;
        }, 250);
      }
    }
  }

  /**
	 * select the description for the widget.
	 * Will be one of the following:
	 * - description
	 * - shortDescription
	 * - void
	 * @param {Page} page
	 * @returns {void}
	 */
  getDescription(page: any) {
    if (page.description) {
      return page.description;
    } else if (page.shortDescription) {
      return page.shortDescription;
    }
  }

  /**
	 * setButtonStyle
	 * @return {Objct} style object
	 */
  setButtonStyle() {
    if (this.client && this.client.colors) {
      const styles = {
        'border': '2px solid ' + this.client.colors.main,
        'color': this.client.colors.main,
      };
      return styles;
    }
    return {};
  }

  /**
	 * setFontStyle
	 * @return {Obect} style object
	 */
  setFontStyle() {
    if (this.client && this.client.colors) {
      const styles = {
        'color': this.client.colors.fontLight,
        'background-color': this.client.colors.main,
      };
      return styles;
    }
    return {};
  }

  openMap() {
   const p = JSON.parse(JSON.stringify(this.page));
   p.type = WidgetType.MAP.type;
   this.modalService.openWidget(p);
  }

  openModal() {
    this.modalService.openWidget(this.page);
  }

  ngOnDestroy() {
    if (this.backToMenu$) {
      this.backToMenu$.unsubscribe();
    }
  }
}
