import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { publishReplay,  map } from 'rxjs/operators';
import { HttpResponse } from '@angular/common/http';
import { ApiService } from './api.service';
import { AuthService } from './auth.service';

/**
 * The File Service is responsible for geting files and
 * formating the response from the server.
 */
@Injectable()
export class FilesService {

  constructor(
    private authService: AuthService,
    private api: ApiService
  ) {
  }

  /**
	 * This services gets the images from the API stored as base64 strings
	 * @param {string} fileID - The database id of the file
	 * @return Observable<any>
	 */
  getFileByID(fileID: string): Observable<String> {
    const clientId: string = this.authService.authObject.clientId;
    const apiURL = '/content/' + clientId + '/' + fileID;
    return this.api.get(apiURL).pipe(
      map(this.handleFile),
      publishReplay(1));
  }

  private handleFile(res: HttpResponse<any>): String {
    return res.body;
  }

}
