import { Injectable, EventEmitter } from '@angular/core';
import { LanguagesService } from './languages.service';
import { UtilsService } from './utils.service';
import { Router } from '@angular/router';
import { WidgetType } from '@app/widgets/widget-type';

@Injectable()
export class ModalService {
  openModal$: EventEmitter<any> = new EventEmitter();
  openIframeModal$: EventEmitter<any> = new EventEmitter();
  backToMenu$: EventEmitter<any> = new EventEmitter();
  screensaverOpened = false;

  constructor(
    private router: Router,
    private utils: UtilsService,
    private languagesService: LanguagesService
  ) { }

  public openModal(content: any, title: string, wideOverlay: boolean) {
    this.openModal$.emit({ 'content': content, 'title': title, 'wideOverlay': wideOverlay });
  }

  public openIframeModal(url: any, title: string) {
    this.openIframeModal$.emit({ 'url': url, 'title': title });
  }

  public backToMenu() {
    this.backToMenu$.emit();
  }

  public openWidget(data, wideOverlay = false, preventBackToMenu = false) {
    if (data.content && [WidgetType.NORMAL.type, WidgetType.FLIP.type].includes(data.type)) {
      this.utils.wrapContentToFroalaElement(data.content);
      preventBackToMenu ? this.backToMenu() : this.openModal(data.content, data.title, wideOverlay);
    } else if (data.type === WidgetType.WEB.type) {
      if (data.urlRedirect && (this.isBackButtonCreatedInLocalStorage() || this.isIgnoreButtonPlugin(data.url))) {
        this.utils.redirectWebWidget(data.url, this.languagesService.currentLanguage);
      } else {
        preventBackToMenu ? this.backToMenu() : this.openIframeModal(data.url, data.title);
      }
    } else {
      const widgetType = WidgetType.all().find(w => w.type === data.type);
      if (widgetType) {
        let urlNavigate = widgetType.url + '/';
        if (widgetType.type === WidgetType.MAP.type ) {
          urlNavigate += data.location ? 'custom;pid=' + data.location : data.poiCategory ? data.poiCategory : '';
        } else {
          urlNavigate += widgetType.id ? data.id : '';
        }
        if (widgetType.type === WidgetType.SMS.type) {
          urlNavigate += '/' + data.smsType;
        }
        this.router.navigate([urlNavigate]);
      }
    }
  }

  private isIgnoreButtonPlugin(url): boolean {
    const params: any = this.utils.getUrlParams(url, this.languagesService.currentLanguage);
    return params.hasOwnProperty('ignoreButtonPlugin') && params.ignoreButtonPlugin === 'true';
  }

  private isBackButtonCreatedInLocalStorage(): boolean {
    return window.localStorage.getItem('backButtonCreated') === 'true';
  }

  /**
   * back
   * return to the main navigation
   * @return {void}
   */
  back(): void {
    this.router.navigate(['/menu']);
  }
}
