import { Component, OnInit, ViewChild } from '@angular/core';
import {
  Client, ClientService,
  LanguagesService,
  WeatherService, Locale
} from '../services';
import { SortPagesPipe } from '../shared/pipes/sort-pages.pipe';

@Component({
  selector: 'app-language',
  templateUrl: 'language.component.html',
  styleUrls: ['language.component.css']
})

/**
 * language selector component
 */
export class LanguageComponent implements OnInit {
  client: Client;
  languages: Array<Locale>;
  currentLang: string;
  @ViewChild('lgModal') modal: any;
  constructor(
    private clientService: ClientService,
    private languagesService: LanguagesService,
    private weatherService: WeatherService,
    private sortPagesPipe: SortPagesPipe
  ) { }

  ngOnInit() {
    this.clientService.client$.subscribe(client => {
      this.client = client;
      this.weatherService.getCurrentWeather(client);
    });

    this.languagesService.languages$.subscribe(locales => {
      this.languages = this.sortPagesPipe.transform(locales);
      if (locales && locales.length > 0 && locales[0].key) {
        this.languagesService.setCurrentLanguage(this.languages[0].key);
      }
      // set the correct weather for the default language
      this.currentLang = this.languagesService.currentLanguage;
    });

    this.languagesService.currentLanguage$.subscribe(lang => {
      this.currentLang = lang;
    });
  }

  /**
	 * setHeaderStyle
	 * @return {Object} style class as JSON Object
	 */
  setHeaderStyle() {
    if (this.client && this.client.colors) {
      const styles = {
        'background-color': this.client.colors.main,
        'color': this.client.colors.fontLight,
        'opacity': 1
      };
      return styles;
    }
    return {};
  }

  /**
	 * opens an overlay to choose from all available languages
	 * @return {void}
	 */
  openAllLanguagesOverlay() {
    this.modal.show();
  }

  /**
	 * onSelect
	 * @param {string} key  - The new language key
	 * @return {void}
	 */
  onSelect(key: string) {
    this.languagesService.setCurrentLanguage(key);
    this.modal.hide();
  }

}
