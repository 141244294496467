<div class="advertising-container" [ngStyle]="smallerAd ? {height: '103px'} : {height: '126px'}">
  <swiper #swiper [config]="config">
    <div class="swiper-wrapper">
      <div class="swiper-slide" *ngFor="let ad of getFilteredAdvertisements()">
        <img class="image" [src]="ad?.image" (click)="redirectPartnerPage(ad)" [ngStyle]="smallerAd ? {height: '103px'} : {height: '126px'}">
      </div>
    </div>
  </swiper>

</div>
<custom-modal #modal></custom-modal>
