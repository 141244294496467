import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { Observer, Observable, ReplaySubject } from 'rxjs';
import { map, share } from 'rxjs/operators';
import { UtilsService } from './utils.service';
import { ApiService } from './api.service';
import { AuthService } from './auth.service';
import { EventsService } from './events.service';
import { DishSetService } from './dish-set.service';
import { LanguagesService } from './languages.service';

/**
 * This class handles all client specific manipulations including
 * getting, setting and storing the client data
 */
@Injectable()
export class ClientService {

  // new structure, now using a ReplaySubject which fires back a cached result (= it replays the stream)
  private dataObs$ = new ReplaySubject<Client>(1);
  client$: Observable<Client>;
  private _clientObserver: Observer<Client>;

  constructor(
    private authService: AuthService,
    private utils: UtilsService,
    private api: ApiService,
    private eventsService: EventsService,
    private dishSetService: DishSetService,
    private languagesService: LanguagesService
  ) {
    // Initialize client observer
    this.client$ = new Observable<Client>(observer => this._clientObserver = observer).pipe(share());
  }

  /**
	 * getClient
	 * this fuction fetches the client information and puts them into a ReplaySubject
	 * @param {boolean} forceRefresh - (optional) - If set to true a new rest call will be made, otherwise cached data is returned
	 * @return {Observable<Client>}
	 */
  getClient(forceRefresh?: boolean): Observable<Client> {
    // If the Subject was NOT subscribed before OR if forceRefresh is requested
    if (!this.dataObs$.observers.length || forceRefresh) {
      // construct route
      const clientId: string = this.authService.authObject.clientId;
      const deviceGroupId: string = this.authService.authObject.deviceGroupId;
      const apiURL = '/content/' + clientId;
      // build search params
      let parameters = new HttpParams();
      parameters = parameters.append('key', 'mainPage.' + deviceGroupId);
      // fire request
      this.api.get(apiURL, parameters).pipe(
        map(res => this.utils.extractData(res, 'client'))
      ).subscribe(client => {
        this.languagesService.getAllLocales();
        this.eventsService.getAllEvents();
        this.dishSetService.getAllDishSets();
        // Push the new list of pages into the Observable stream
        this._clientObserver.next(<Client>client);
        this.dataObs$.next(<Client>client);
      });
    }
    return this.dataObs$.asObservable();
  }

}

/** Location of the client */
export class ClientLocation {
  constructor(
    /** Latitude coordinate of the client */
    public lat: number,
    /** Longitude coordinate of the client */
    public lng: number,
    /** Clients town name */
    public name: string
  ) { }
}

/** The defined colors for the client */
export class ClientColors {
  constructor(
    /** The main color */
    public main: string,
    /** The accent color */
    public accent: string,
    /** Font color used for bright backgrounds */
    public fontDark: string,
    /** Font color used for dark backgrounds */
    public fontLight: string,
    /** The defined light background color */
    public lightBG: string
  ) { }
}

/** Clients localization class */
export class ClientLocales {
  constructor(
    /** The language key according to IETF BCP 47 standard */
    public key: string,
    /** The language icon */
    public icon: string,
    /** Speaching name of the language like English, Deutsch etc. */
    public description: string,
    /** Flag if language is active */
    public active: boolean
  ) { }
}

export enum DisplayTypeEnum {
  HORIZONTAL = 'horizontal',
  VERTICAL = 'vertical'
}

/** Client main structure */
export class Client {
  constructor(
    /** Internal id */
    public id: number,
    /** Clients name */
    public title: string,
    /** The content version */
    public version: string,
    /** Client logo */
    public logo: string | Object,
    /** Client location */
    public location: ClientLocation,
    /** Client colors */
    public colors: ClientColors,
    /** Client localization */
    public locales: ClientLocales[],
    public name?: string,
    public displayType?: DisplayTypeEnum
  ) { }
}
