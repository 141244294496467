import { Component, OnInit } from '@angular/core';
import { EmailWidgetComponent } from '../../email-widget/email-widget.component';
import { Router } from '@angular/router';
import { UtilsService, ClientService, Page, ModalService } from '@app/services';
import { I18nPipe } from '@app/shared/pipes/i18n.pipe';

@Component({
  selector: 'app-email-widget-vertical',
  templateUrl: './email-widget-vertical.component.html',
  styleUrls: ['./email-widget-vertical.component.css']
})
export class EmailWidgetVerticalComponent extends EmailWidgetComponent implements OnInit {

  constructor(
    protected clientService: ClientService,
    protected router: Router,
    protected utils: UtilsService,
    protected i18n: I18nPipe,
    protected modalService: ModalService
  ) {
    super(clientService, router, utils, i18n, modalService);
  }

  ngOnInit() {
    super.ngOnInit();
  }

}
