import { Pipe, PipeTransform } from '@angular/core';
import { AuthService } from '../../services/auth.service';

/**
 * this pipe sorts the content from pages object, the premise
 * is a order property
 */
@Pipe({
  name: 'sortPages'
})
export class SortPagesPipe implements PipeTransform {

  constructor(
    private authService: AuthService
  ) { }

  transform(value: any, args?: any): any {
    let deviceGroupId: string;
    if (this.authService.authObject) {
      deviceGroupId = this.authService.authObject['deviceGroupId'];
    }

    if (value && deviceGroupId) {
      value.sort((a, b) => {
        return this.getPageOrder(a, deviceGroupId) - this.getPageOrder(b, deviceGroupId);
      });
    }
    return value;
  }

  private getPageOrder(page: any, pageId: string): number {
    if (page.pageContents && page.pageContents.length !== 0) {
      for (const pageContent of page.pageContents) {
        if (pageContent.page_id === pageId) {
          return pageContent.sortOrder;
        }
      }
      for (const pageContent of page.pageContents) {
        if (!pageContent.page_id) {
          return pageContent.sortOrder;
        }
      }
    }
    return -1;
  }
}
