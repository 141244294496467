import { Component, EventEmitter, Input, Output, OnInit } from '@angular/core';
import { ClientService } from '../services';

@Component({
  selector: 'app-widget-header',
  templateUrl: 'widget-header.component.html',
  styleUrls: ['widget-header.component.css']
})
/**
 * WidgetHeader Class
 */
export class WidgetHeaderComponent implements OnInit {
  private client: any;
  @Input() header: any;
  // tslint:disable-next-line:no-output-on-prefix
  @Output() onClose: EventEmitter<any> = new EventEmitter();
  constructor(
    private clientService: ClientService
  ) {
  }

  ngOnInit() {
    this.clientService.getClient().subscribe(client => {
      this.client = client;
    });
  }

  /**
	 * set the colors styles for the client
	 * @return {void}
	 */
  setHeaderStyle() {
    if (this.client && this.client.colors) {
      const styles = {
        'background-color': this.client.colors.main,
        'color': this.client.colors.fontLight,
        'opacity': 1
      };
      return styles;
    }
    return {};
  }

  close() {
    this.onClose.next();
  }

}
