import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { WeatherComponent } from './weather';
import { WeatherCurrentComponent } from './weather-current/weather-current.component';
import { SmsComponent } from './sms';
import { EmailComponent } from './email/email.component';
import { FeedbackComponent } from './feedback/feedback.component';
import { SlideshowComponent } from './slideshow/slideshow.component';
import { EventsComponent } from './events/events.component';
import { DishSetComponent } from './dish-set/dish-set.component';
import { AuthGuard } from './services/auth.guard';

export const routes: Routes = [
  { path: '', pathMatch: 'full', redirectTo: '/menu', canActivate: [AuthGuard] },
  { path: 'weather', component: WeatherComponent, canActivate: [AuthGuard] },
  { path: 'weather-current', component: WeatherCurrentComponent, canActivate: [AuthGuard] },
  { path: 'sms/:id', component: SmsComponent, canActivate: [AuthGuard] },
  { path: 'email/:id', component: EmailComponent, canActivate: [AuthGuard] },
  { path: 'email/:id/:type', component: EmailComponent, canActivate: [AuthGuard] },
  { path: 'sms/:id/:type', component: SmsComponent, canActivate: [AuthGuard] },
  { path: 'sms/:id/:type', component: SmsComponent, canActivate: [AuthGuard] },
  { path: 'feedback/:id', component: FeedbackComponent, canActivate: [AuthGuard] },
  { path: 'slideshow/:id', component: SlideshowComponent, canActivate: [AuthGuard] },
  { path: 'events/:id', component: EventsComponent, canActivate: [AuthGuard] },
  { path: 'dish-set/:id', component: DishSetComponent, canActivate: [AuthGuard] },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
