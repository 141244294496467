import { Injectable } from '@angular/core';
import { HttpResponse, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ApiService } from './api.service';
import { AuthService } from './auth.service';

/**
 * Service class to send sms
 */
@Injectable()
export class SmsService {
  constructor(
    private authService: AuthService,
    private api: ApiService
  ) { }

  /**
	 * Tries to send a sms to a given number
	 * @param {string} message - The message body.
	 * @return {Observable<Response>} An Observable with the response
	 */
  sendSMS(message: string, clientID: number | string, widgetID: string): Observable<HttpResponse<any>> {
    const token: string = this.authService.authObject.token;
    if (!token || token === '') {
      console.warn('No valid token ' + token + '! Could not send SMS.');
      return;
    }
    // set the url parameters
    let parameters = new HttpParams();
    parameters = parameters.append('message', encodeURIComponent(message));
    parameters = parameters.append('widgetId', widgetID);
    // make request and return the response Observable
    return this.api.post('/content/' + clientID + '/send', '', parameters);
  }

  /**
	 * check and transform the number to be compatible with DIN-5008
	 * @param {string} number - The telefon number to check
	 * @return {string} The correctly transformed number
	 */
  private _transformNumber(number: string): string {
    // check for + for international numbering
    if (number[0] === '+') {
      number = number.substr(1, number.length - 1);
    }
    // check if + was substituted by 00
    if (number[0] === '0' && number[1] === '0') {
      return number = number.substr(2, number.length - 1);
    }
    // check if number is normal formatted, if add 49 and remove prefix 0
    if (number[0] === '0') {
      number = '49' + number.substr(1, number.length - 1);
    }
    return number;
  }
}
