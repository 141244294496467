import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DishSetComponent } from './dish-set.component';
import { SharedModule } from '../shared/shared.module';
import { WidgetHeaderModule } from '../widget-header/widget-header.module';
import { TranslateModule } from '@ngx-translate/core';
import { LanguagesService, DishSetService, ModalService, PagesService } from '../services';

@NgModule({
    imports: [
        CommonModule,
        SharedModule, WidgetHeaderModule, TranslateModule.forChild()
    ],
    declarations: [DishSetComponent],
    exports: [DishSetComponent],
    providers: [LanguagesService, DishSetService, ModalService, PagesService]
})
export class DishSetModule { }
