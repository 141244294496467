import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { Angulartics2 } from 'angulartics2';
import {
  ActivityService, ApiService, AuthService, ModalService, UtilsService,
  ClientService, Page, PagesService, DisplayTypeEnum
} from '../services';

@Component({
  selector: 'app-main-menu',
  templateUrl: 'main-menu.component.html'
})

/**
 * Handles the Menu Structure and widget displaying
 */
export class MainMenuComponent implements OnInit, OnDestroy {
  pages: Observable<any[]>;
  client: any;
  @ViewChild('modal') modal: any;
  getClient$: any;
  openModal$: any;
  openIframeModal$: any;
  backToMenu$: any;
  isVerticalDisplay = false;
  constructor(
    protected api: ApiService,
    protected activityService: ActivityService,
    protected authService: AuthService,
    protected pagesService: PagesService,
    protected clientService: ClientService,
    protected router: Router,
    protected angulartics2: Angulartics2,
    protected modalService: ModalService,
    protected utils: UtilsService
  ) {
    this.getClient$ = this.clientService.getClient().subscribe(c => {
      this.client = c;
      this.isVerticalDisplay = c.displayType && c.displayType === DisplayTypeEnum.VERTICAL;
    });
  }

  ngOnInit() {
    this.pagesService.getAllPages();
    this.pages = this.pagesService.pages$;
    this.pagesService.pages$.subscribe(pages => {
      const savedInstanceId = localStorage.getItem('page');
      if ((!this.pagesService.currentPage || typeof this.pagesService.currentPage === 'undefined') &&
          (!savedInstanceId || savedInstanceId === '0')) {
        this.pagesService.setCurrentPage(pages[0]);
        this.router.navigate(['/menu/' + pages[0].id]);
      }
      if ((!this.pagesService.currentPage || typeof this.pagesService.currentPage === 'undefined') && savedInstanceId) {
        pages.forEach(page => {
          if (String(page.id) === savedInstanceId) {
            this.pagesService.setCurrentPage(page);
            this.router.navigate(['/menu/' + page.id]);
          }
        });
      }
      if (this.pagesService.currentPage && this.pagesService.currentPage.id) {
        this.router.navigate(['/menu/' + this.pagesService.currentPage.id]);
        this.pagesService.setCurrentPage(this.pagesService.currentPage);
      }
    });
    this.openModal$ = this.modalService.openModal$.subscribe(res => {
      if (!this.modal.isModalShown) {
        this.modal.showModal(res.content, res.title, null, null, res.wideOverlay);
      }
    });
    this.openIframeModal$ = this.modalService.openIframeModal$.subscribe(res => {
      this.modal.showIframeModal(res.url, res.title);
    });
    this.backToMenu$ = this.modalService.backToMenu$.subscribe(res => {
      if (this.modal && this.modal.modal) {
        this.modal.modal.hide();
      }
    });
  }

  /**
	 * onSelect
	 * routes to the page with the given id
	 * @param {Page} pageID
	 * @return {void}
	 */
  onSelect(page: Page) {
    localStorage.setItem('page', String(page.id));
    this.pagesService.setCurrentPage(page);
    this.router.navigate(['/menu/' + page.id]);
  }

  /**
	 * setButtonStyle
	 * @param {Page} page
	 * @return {Object} style object
	 */
  setButtonStyle(page: any) {
    if (this.client && this.client.colors && this.pagesService.currentPage) {
      const styles = {
        // CSS property names
        'background-color': page['id'] === this.pagesService.currentPage.id ? this.client.colors.accent : this.client.colors.main,
        'color': this.client.colors.fontLight,
      };
      return styles;
    }
    return {};
  }

  ngOnDestroy() {
    if (this.getClient$) {
      this.getClient$.unsubscribe();
    }
    if (this.openModal$) {
      this.openModal$.unsubscribe();
    }
    if (this.openIframeModal$) {
      this.openIframeModal$.unsubscribe();
    }
    if (this.backToMenu$) {
      this.backToMenu$.unsubscribe();
    }
  }

}
