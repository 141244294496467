import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WeatherComponent } from './weather.component';
import { SharedModule } from '../shared';
import { WidgetHeaderModule } from '../widget-header/widget-header.module';
import { ClientService, LanguagesService, ModalService, WeatherService } from '../services';

@NgModule({
  imports: [
    CommonModule, SharedModule, WidgetHeaderModule
  ],
  declarations: [WeatherComponent],
  exports: [WeatherComponent],
  providers: [ClientService, LanguagesService, ModalService, WeatherService]
})
export class WeatherModule { }
