<div class="row">

  <div class="app-card-deck-wrapper">
    <div class="full-height scroller">

      <div class="card full-height" *ngFor="let page of currentWidgets | sortWidgets">
        <div class="full-height" [ngSwitch]="page.type">
          <app-content-widget *ngSwitchCase="'normal'" [page]="page"></app-content-widget>
          <app-content-widget *ngSwitchCase="'web'" [page]="page"></app-content-widget>
          <app-static-widget *ngSwitchCase="'static'" [page]="page"></app-static-widget>
          <app-content-widget *ngSwitchCase="'forecast'" [page]="page"></app-content-widget>
          <app-map-widget *ngSwitchCase="'map'" [page]="page"></app-map-widget>
          <app-weather-current-widget *ngSwitchCase="'weather'" [page]="page"></app-weather-current-widget>
          <app-flip-card-widget *ngSwitchCase="'flip'" [page]="page"></app-flip-card-widget>
          <app-sms-widget *ngSwitchCase="'sms'" [page]="page"></app-sms-widget>
          <app-email-widget *ngSwitchCase="'email'" [page]="page"></app-email-widget>
          <app-content-widget *ngSwitchCase="'feedback'" [page]="page"></app-content-widget>
          <app-content-widget *ngSwitchCase="'slideshow'" [page]="page"></app-content-widget>
          <app-content-widget *ngSwitchCase="'events'" [page]="page"></app-content-widget>
          <app-content-widget *ngSwitchCase="'dish-set'" [page]="page"></app-content-widget>
        </div>
      </div>

    </div>
  </div>

</div>
