<div class="content-widget">
  <div class="widget-img" [ngStyle]="{ 'background-image' : getBackground(page.image)}" (click)="openLink(page)">
  </div>
  <div class="background-area" (click)="openLink(page)">
    <div *ngIf="page.title" class="title-wrapper" [ngStyle]="setBGStyle()">
      <h4 [ngStyle]="setFontStyle()">{{page.title | i18n}}</h4>
      <h5 [ngStyle]="setFontStyle()">{{page.description | i18n}}</h5>
    </div>
  </div>
</div>
<custom-modal #modal></custom-modal>
