import { Component, OnInit, OnDestroy, ViewChild, ElementRef, Renderer2 } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { ClientService, ModalService, PagesService, SmsService, UtilsService } from '../services';
import { TranslateService } from '@ngx-translate/core';
import { I18nPipe } from '../shared/pipes/i18n.pipe';


/**
 * SMS construct
 */
class SMS {
  /** The type of the sms e.g restaurant, taxi... */
  smsType: string;
  /** The message if sms send successfully */
  successMessage: string;
  /** The message if sms was not send successfully */
  errorMessage: string;
  /** the message itself */
  sendMessage: string;
  constructor(
    smsType: string,
    args: Array<string>
  ) {
    this.smsType = smsType;
    this.build(args);
  }

  /**
	 * build the sms object for a given type
	 * @param {Array<string>} args - The body information for the sendMessage
	 */
  build(args: Array<string>) {
    switch (this.smsType) {
      case 'taxi':
        this.successMessage = 'Nachricht erfolgreich gesendet. Ihr Taxi wird Sie gleich abholen.';
        this.errorMessage = 'Es ist ein Fehler aufgetreten. Versuchen Sie es bitte erneut.';
        this.sendMessage = 'Bitte holen Sie ' + args[3] + ' ' + args[0] + ' aus Zimmer ' + args[1] + ' vom ' + args[2] + ' ab.';
        break;
      case 'restaurant':
        this.successMessage = 'Nachricht erfolgreich gesendet. Ein Tisch wird für Sie reserviert.';
        this.errorMessage = 'Es ist ein Fehler aufgetreten. Versuchen Sie es bitte erneut.';
        this.sendMessage = 'Bitte reservieren Sie ' + args[3] + ' einen Tisch für ' + args[0] + '. Zimmer: ' + args[1];
        break;
      case 'shop':
        this.successMessage = 'Nachricht erfolgreich gesendet. Rufen Sie bitte als nächstes den Link';
        this.successMessage += ' in der SMS auf, um den Checkout zu beenden.';
        this.errorMessage = 'Es ist ein Fehler aufgetreten. Versuchen Sie es bitte erneut.';
        this.sendMessage = 'Beenden Sie den Checkout mit folgendem Link: ' + args[0];
        break;
      default:
        this.successMessage = 'Nachricht erfolgreich gesendet.';
        this.errorMessage = 'Es ist ein Fehler aufgetreten. Versuchen Sie es bitte erneut.';
        this.sendMessage = args.join(' ');
        break;
    }

  }

}

@Component({
  selector: 'app-sms',
  templateUrl: 'sms.component.html',
  styleUrls: ['sms.component.css'],
  providers: [PagesService, SmsService]
})

/**
 * Class for SMS page
 */
export class SmsComponent implements OnInit, OnDestroy {
  page: any;
  roomNumber = '';
  personNumber = '1';
  inputFocus: boolean;
  alert: any;
  sms: SMS;
  smsType: string;
  selectedHour = 12;
  selectedMinute = 30;
  day = 'today';
  time = 'now';
  timeString = '';
  client: any;
  sending = false;

  smsResponse: string;
  smsSuccessfullySend: boolean;
  initializeUrlModal = false;
  private backToMenu$: any;

  @ViewChild('roomNr') roomInput: any;
  @ViewChild('modal') modal: any;
  @ViewChild('modalSend') modalSend: any;
  @ViewChild('smsForm') smsForm: any;
  @ViewChild('urlModal') urlModal: any;

  constructor(
    private route: ActivatedRoute,
    private modalService: ModalService,
    private pagesService: PagesService,
    private smsService: SmsService,
    private router: Router,
    private clientService: ClientService,
    private translate: TranslateService,
    private i18nPipe: I18nPipe,
    private utils: UtilsService,
    private elementRef: ElementRef,
    private renderer: Renderer2
  ) { }

  ngOnInit() {
    this.translate.onLangChange.subscribe(() => {
      this.translate.get('sms.setTime').subscribe(translation => {
        this.timeString = translation;

      });
    });
    this.translate.get('sms.setTime').subscribe(translation => {
      this.timeString = translation;
    });
    this.smsResponse = '';
    this.smsSuccessfullySend = false;
    this.clientService.getClient().subscribe(client => {
      this.client = client;
    });

    const id: string = this.route.snapshot.params['id'];
    this.smsType = this.route.snapshot.params['type'];
    this.pagesService.getPageByID(id).subscribe(p => {
      if (p.content && typeof p.content === 'object') {
        for (const locale in p.content) {
          if (typeof p.content[locale] !== 'object') {
            p.content[locale] = p.content[locale].replace(this.utils.getHrefRegex(), 'source=');
          }
        }
      } else if (typeof p.content !== 'undefined' && p.content !== '') {
        p.content = p.content.replace(this.utils.getHrefRegex(), 'source=');
      }

      this.page = p;

      setTimeout(() => {
        const elements = this.elementRef.nativeElement.querySelectorAll('a[source]');
        for (const element of elements) {
          this.renderer.listen(element, 'click', (evt) => {
            this.urlModal.showIframeModal({ '_': element.getAttribute('source') }, 'Title');
          });
        }
        if (elements.length > 0) {
          this.initializeUrlModal = true;
        }
      }, 200);
    });

    this.backToMenu$ = this.modalService.backToMenu$.subscribe(res => {
      this.back();
    });

  }

  /**
	 * selectDay
	 * @param {string} d - A day string
	 * @return {void}
	 */
  selectDay(d: string) {
    this.day = d;
  }

  /**
	 * selectTime
	 * @param {string} time - (optional) A time string
	 * @returns void
	 */
  selectTime(time?: string) {
    if (!time) {
      let minuteString = '' + this.selectedMinute;
      if (this.selectedMinute < 10) {
        minuteString = '0' + this.selectedMinute;
      }
      const t: string = this.selectedHour + ':' + minuteString;
      this.time = t;
      this.timeString = t;
    } else {
      this.time = time;
      this.translate.get('sms.setTime').subscribe(translation => {
        this.timeString = translation;
      });
    }
    this.modal.hide();
  }

  /**
	 * opens the time picker component modal
	 * @return {void}
	 */
  openTimepicker() {
    this.modal.show();
  }

  /**
	 * buildTimeText
	 * returns a text with the selected timeString
	 * @param {string} time - The time to concat
	 * @param {day} day - The day to concat
	 * @return {string} The date-time in textform
	 */
  buildTimeText(time: string, day: string): string {
    let timeText: string;
    let dayText: string;
    if (day === 'tomorrow') {
      dayText = 'morgen';
    } else if (day === 'today') {
      dayText = 'heute';
    } else if (day === 'dayaftertomorrow') {
      dayText = 'übermorgen';
    }
    if (time === 'now' && day === 'today') {
      timeText = 'jetzt';
    } else {
      timeText = dayText + ' um ' + time + 'Uhr';
    }
    return timeText;
  }

  /**
	 * sendSMS
	 * sends a sms via rest call
	 * displays a message with the result
	 * @param {string} roomNumber - The room number
	 * @param {string} personNumber - The number if people
	 * @return {void}
	 */
  sendSMS(roomNumber: string, personNumber: string) {
    if (roomNumber.trim().length < 1) {
      this.roomNumber = '';
      return false;
    }
    let personText: string;
    this.sending = true;
    const timeText: string = this.buildTimeText(this.time, this.day);
    if (personNumber === '1') {
      personText = personNumber + ' Gast';
    } else {
      personText = personNumber + ' Gäste';
    }
    const clientTitle = this.i18nPipe.transform(this.client.title, false);
    const sms = new SMS(this.smsType, [personText, roomNumber, clientTitle, timeText]);
    if (this.page) {
      const sendSms$ = this.smsService.sendSMS(sms.sendMessage, this.client.id, this.page.id);
      if (sendSms$) {
        sendSms$.subscribe(data => {
          this.smsResponse = sms.successMessage;
          this.smsSuccessfullySend = true;
          this.modalSend.show();
          this._resetForm();
          this.sending = false;
        }, error => {
          this.handleSmsError(sms, error);
        });
      } else {
        this.handleSmsError(sms);
      }
    }
  }

  private handleSmsError(sms: SMS, error?: any) {
    console.warn('Could not send sms.');
    if (error) {
      console.warn(error);
    }
    this.smsResponse = sms.errorMessage;
    this.smsSuccessfullySend = false;
    this.modalSend.show();
    this._resetForm();
    this.sending = false;
  }

  /**
	 * _showAlert
	 * depricated
	 */
  _showAlert(msg: string) {
    const alert = {
      msg: msg
    };
    this.alert = alert;
  }

  /**
	 * _resetForm
	 * reset the formdata
	 * @return {void}
	 */
  _resetForm() {
    this.roomNumber = '';
    this.personNumber = '';
    this.smsForm.form.reset();
  }

  /**
	 * closeAlert
	 * depricated
	 */
  closeAlert() {
    this.alert = null;
  }

  /**
	 * back
	 * return to the main navigation
	 * @return {void}
	 */
  back() {
    this.router.navigate(['/']);
  }

  /**
	 * successAndBack
	 * closes the modal and calls back
	 * @return {void}
	 */
  successAndBack() {
    this.modalSend.hide();
    this.back();
  }

  /**
	 * set the colors styles for the client
	 * @return {void}
	 */
  setHeaderStyle() {
    if (this.client && this.client.colors) {
      const styles = {
        'background-color': this.client.colors.main,
        'color': this.client.colors.fontLight,
        'opacity': 1
      };
      return styles;
    }
    return {};
  }

  keyUp($event) {
    if ($event && $event.path && $event.path[0]) {
      this.roomNumber = $event.path[0].value;
    }
  }

  ngOnDestroy() {
    if (this.backToMenu$) {
      this.backToMenu$.unsubscribe();
    }
  }

}
