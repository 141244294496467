import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared';
import { HeaderComponent } from './header.component';
import { TimeComponent } from './time/time.component';
import { LanguagesService, UtilsService, ModalService, AuthService, WeatherService, ClientService } from '../services';
import { ThinHeaderComponent } from './thin-header/thin-header.component';
import { ThickHeaderComponent } from './thick-header/thick-header.component';

@NgModule({
  imports: [
    CommonModule, SharedModule
  ],
  declarations: [HeaderComponent, TimeComponent, ThinHeaderComponent, ThickHeaderComponent],
  exports: [HeaderComponent, ThinHeaderComponent],
  providers: [UtilsService, AuthService, WeatherService, ClientService, LanguagesService, ModalService]
})
export class HeaderModule { }
