import { Component, OnInit } from '@angular/core';
import { WeatherService } from '../services';
import { Router } from '@angular/router';
import * as moment from 'moment';

@Component({
  selector: 'app-weather-current',
  templateUrl: './weather-current.component.html',
  styleUrls: ['./weather-current.component.css']
})
export class WeatherCurrentComponent implements OnInit {
  currentWeather: any;
  sunrise: string;
  sunset: string;
  windDirection: string;

  constructor(
    private weatherService: WeatherService,
    private router: Router,
  ) { }

  ngOnInit() {
    // If there is a current weather, set it in the view
    if (this.weatherService.currentWeather) {
      this.currentWeather = this.weatherService.currentWeather;
      this.setSun();
      this.setWindDir();
    }
    // If weather changes, set it in the view
    this.weatherService.currentWeather$.subscribe(cw => {
      this.currentWeather = cw;
      this.setSun();
      this.setWindDir();
    });
  }

  setSun() {
    this.sunrise = moment.unix(this.currentWeather.sys.sunrise).format('HH:mm');
    this.sunset = moment.unix(this.currentWeather.sys.sunset).format('HH:mm');
  }

  setWindDir() {
    if (this.currentWeather && this.currentWeather.wind && this.currentWeather.wind.deg) {
      this.windDirection = this.weatherService.getWindDirectionAsText(+this.currentWeather.wind.deg);
    }
  }

  back() {
    this.router.navigate(['/']);
  }

}
