import { Component, OnInit, ElementRef, AfterViewInit, ViewChild, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { ClientService, LanguagesService, ModalService, WeatherService } from '../services';
import { interval } from 'rxjs';
import { log } from 'util';

@Component({
  selector: 'app-weather',
  templateUrl: 'weather.component.html',
  styleUrls: ['weather.component.css']
})

export class WeatherComponent implements OnInit, AfterViewInit, OnDestroy {
  weather: any;
  isEndOfPage: boolean;
  private backToMenu$: any;
  private client: any;
  @ViewChild('contentElem') contentElem;
  constructor(
    private modalService: ModalService,
    private clientService: ClientService,
    public weatherService: WeatherService,
    private router: Router,
    public el: ElementRef,
    private languagesService: LanguagesService
  ) { }

  ngOnInit() {
    this.clientService.getClient().subscribe(client => {
      this.client = client;
    });

    this.weatherService.loadForecast();
    this.weatherService.forecast$.subscribe(w => {
      this.weather = w;
    });

    this.languagesService.currentLanguage$.subscribe(lang => {
      this.weatherService.loadForecast();
    });

    this.backToMenu$ = this.modalService.backToMenu$.subscribe(res => {
      this.back();
    });
  }

  /**
	 * back
	 * @returns void
	 */
  back() {
    this.router.navigate(['/']);
  }

  /**
	 * keys
	 * gets the weather list
	 * @return Array<string>
	 */
  keys(): Array<string> {
    if (this.weather && this.weather.list) {
      return Object.keys(this.weather.list);
    }
    return [];
  }

  ngAfterViewInit() {
    interval(500).subscribe(() => {
      this.endOfPageReached();
    });
  }

  /**
	 * endOfPageReached
	 * sets indicator if the end of the page is reached
	 * @returns void
	 */
  endOfPageReached() {
    const elem = this.contentElem.nativeElement;
    if (elem.clientHeight + elem.scrollTop >= elem.scrollHeight) {
      this.isEndOfPage = true;
    } else {
      this.isEndOfPage = false;
    }
  }

  /**
	 * scroll
	 * @returns void
	 */
  scroll() {
    if (!this.isEndOfPage) {
      const elem = this.contentElem.nativeElement;
      elem.scrollTop = elem.clientHeight + elem.scrollTop;
    }
  }

  /**
	 * setHeaderStyle
	 * returns style class
	 */
  setHeaderStyle() {
    if (this.client && this.client.colors) {
      const styles = {
        'background-color': this.client.colors.main,
        'color': this.client.colors.fontLight,
        'opacity': 1
      };
      return styles;
    }
    return {};
  }

  ngOnDestroy() {
    if (this.backToMenu$) {
      this.backToMenu$.unsubscribe();
    }
  }

}
