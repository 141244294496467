import { TranslateModule } from '@ngx-translate/core';
import { ScreensaverService } from './../services/screensaver.service';
import { ClientService } from './../services/client.service';
import { AdScreensaverRoutingModule } from './ad-screensaver-routing.module';
import { CustomModalModule } from './../custom-modal/custom-modal.module';
import { SwiperModule } from 'ngx-useful-swiper';
import { UtilsService } from './../services/utils.service';
import { AdScreensaverComponent } from './ad-screensaver.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@app/shared';

@NgModule({
  imports: [
    CommonModule, SharedModule, SwiperModule, CustomModalModule, AdScreensaverRoutingModule, TranslateModule
  ],
  declarations: [AdScreensaverComponent],
  exports: [AdScreensaverComponent],
  providers: [UtilsService, ClientService, ScreensaverService]
})
export class AdScreensaverModule { }
