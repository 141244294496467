<div class="slideshow-component-wrapper">
	<app-widget-header [header]="page ? (page.title | i18n) : null" (onClose)="back()"></app-widget-header>
	<div class="slideshow-content-wrapper">
		<div class="container-fluid">
			<div class="row" *ngIf="page">
				<div class="col-sm-12">
					<form #slideshowForm="ngForm">
						<swiper #swiper [config]="config" (touchend)="updateLastTouched()">
							<div class="swiper-wrapper">
								<div class="swiper-slide" *ngFor="let slide of page.slides">
									<img class="swiper-slide-image" [src]="utils.buildAmazonawsURL(clientId, (slide | i18n)['key'] )" (click)="slideClicked($event, slide)">
								</div>
							</div>
							<!-- Add Pagination -->
							<div class="swiper-pagination" [ngClass]="{'destroyed': destroyed}"></div>
							<!-- Add Arrows -->
							<div class="swiper-button-next"></div>
							<div class="swiper-button-prev"></div>
						</swiper>
					</form>
				</div>
			</div>
		</div>
	</div>
</div>
<custom-modal #hotspotWidgetModal></custom-modal>
