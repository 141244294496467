import { Component, OnInit } from '@angular/core';
import { Client, ClientService, LanguagesService, ModalService, WeatherService, CurrentWeatherObject, AuthService,
  UtilsService
} from '../../services';
import { Router } from '@angular/router';
import { HeaderComponent } from '../';

@Component({
  selector: 'app-thin-header',
  templateUrl: './thin-header.component.html',
  styleUrls: ['./thin-header.component.css']
})
export class ThinHeaderComponent extends HeaderComponent implements OnInit {

  constructor(
    protected weatherService: WeatherService,
    protected clientService: ClientService,
    protected languagesService: LanguagesService,
    public modalService: ModalService,
    protected authService: AuthService,
    protected utils: UtilsService,
    protected router: Router
  ) {
    super(weatherService, clientService, languagesService, modalService, authService, utils, router);
  }

  ngOnInit() {
    super.ngOnInit();
  }

}
