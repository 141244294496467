<div class="sms-component-wrapper">
  <app-widget-header [header]="page ? (page.title | i18n) : null" (onClose)="back()"></app-widget-header>
  <div class="container-fluid">
    <div class="sms-content-wrapper">
      <div class="row">
        <div *ngIf="page" class="col-md-6" >
          <div class="html-content">
            <div class="fr-view" [innerHTML]="(page.content | i18n | safe | awsUrl)"></div>
          </div>
        </div>
        <div class="col-sm-6">
          <form (ngSubmit)="sendSMS(roomNumber, personNumber)" #smsForm="ngForm">
            <div class="row">
              <div class="col-xs-6" style="margin-right: 10px">
                <div class="form-group">
                  <p>{{'sms.chooseADay' | translate}}</p>
                  <div class="btn-group resp-group-wrapper" role="group" aria-label="('sms.chooseADay' | translate)">
                    <button (click)="selectDay('today')" [class.btn-success]="day == 'today' ? true : false" [class.btn-secondary]="day != 'today' ? true : false"
                      type="button" class="btn btn-secondary btn-lg resp-button-wrapper">{{'datesNTimes.days.today' | translate}}</button>
                    <button (click)="selectDay('tomorrow')" [class.btn-success]="day == 'tomorrow' ? true : false" [class.btn-secondary]="day != 'tomorrow' ? true : false"
                      type="button" class="btn btn-secondary btn-lg resp-button-wrapper">{{'datesNTimes.days.tomorrow' | translate}}</button>
                    <button (click)="selectDay('dayaftertomorrow')" [class.btn-success]="day == 'dayaftertomorrow' ? true : false" [class.btn-secondary]="day != 'dayaftertomorrow' ? true : false"
                      type="button" class="btn btn-secondary btn-lg resp-button-wrapper">{{'datesNTimes.days.dayAfterTommorow' | translate}}</button>
                  </div>
                </div>
                <div class="form-group">
                  <p>{{'sms.chooseATime' | translate}}</p>
                  <div class="btn-group resp-group-wrapper" role="group" aria-label="('sms.chooseATime' | translate)">
                    <button [disabled]="day != 'today'" (click)="selectTime('now')" [class.btn-success]="time == 'now' && day == 'today' ? true : false"
                      [class.btn-secondary]="time != 'now' ? true : false" type="button" class="btn btn-secondary btn-lg resp-button-wrapper">{{'datesNTimes.time.now' | translate}}</button>
                    <button #timeButton (click)="openTimepicker()" [class.btn-success]="time != 'now' || day != 'today' ? true : false" [class.btn-secondary]="time == 'now' ? true : false"
                      type="button" class="btn btn-secondary btn-lg resp-button-wrapper">{{timeString}}</button>
                  </div>
                </div>
              </div>
              <div class="col-xs-6" >
                <div class="form-group">
                  <p for="personNumberInput">{{'sms.personCount' | translate}}</p>
                  <ng-select [(ngModel)]="personNumber" ngControl="numb" name="numb" #numb="ngModel" class="choiceSelect" id="personNumberInput" [searchable]="false" [clearable]="false"
                    required>
                    <!--<option value="" selected>Bitte wählen...</option>-->
                    <ng-option  value="1">1</ng-option >
                    <ng-option  value="2">2</ng-option >
                    <ng-option  value="3">3</ng-option >
                    <ng-option  value="4">4</ng-option >
                    <ng-option  value="5">5</ng-option >
                    <ng-option  value="6">6</ng-option >
                    <ng-option  value="7">7</ng-option >
                    <ng-option  value="8">8</ng-option >
                    <ng-option  value="9">9</ng-option >
                    <ng-option  value="10">10</ng-option >
                  </ng-select>
                  <div [hidden]="numb.valid || numb.pristine" class="alert alert-danger">
                    {{'sms.personCountAlert' | translate}}
                  </div>
                </div>
                <div class="form-group">
                  <p for="roomNumberInput">{{'sms.roomNumber' | translate}}</p>
                  <input type="text" class="form-control form-control-lg input" [(ngModel)]="roomNumber" ngControl="roomNr" name="roomNr" #roomNr="ngModel"
                    id="roomNumberInput" placeholder="{{'sms.roomNumber' | translate}}" [value]="roomNumber" (keyup)="keyUp($event)"
                    required>
                  <div [hidden]="roomNr.valid || roomNr.pristine" class="alert alert-danger">
                    {{'sms.roomNumberAlert' | translate}}
                  </div>
                </div>
                <div class="form-group">
                  <button class="btn btn-success lsmaller" type="submit" [disabled]="sending">{{'sms.send' | translate}}</button>
                </div>
              </div>
              <alert *ngIf="alert" (close)="closeAlert()" type="success" dismissible="true">
                <p>{{ alert?.msg }}
                  <p>
              </alert>
            </div>

          </form>

        </div>
      </div>
    </div>
  </div>
</div>

<div bsModal #modal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="smallModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <app-widget-header [header]="('sms.modalChooseTime' | translate)" (onClose)="modal.hide()"></app-widget-header>
      <div class="modal-body">
        <div class="container-fluid">
          <div class="row">
            <div class="col-md-12 modal-html-content">
              <div class="timepicker-container">
                <app-timepicker style="width: 435px;" [(currentHour)]="selectedHour" [(currentMinute)]="selectedMinute"></app-timepicker>
              </div>
              <div class="text-xs-right">
                <button class="btn btn-secondary btn-lg" (click)="modal.hide()">{{'std.cancel' | translate}}</button>
                <button class="btn btn-success btn-lg" (click)="selectTime()">{{'std.apply' | translate}}</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div bsModal #modalSend="bs-modal" [config]="{backdrop: 'static'}" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="smallModalLabel"
  aria-hidden="true">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header" [ngStyle]="setHeaderStyle()">
        <h4 class="modal-title">{{'sms.reservation' | translate}}</h4>
      </div>
      <div class="modal-body">
        <div class="container-fluid">
          <div class="row">
            <div class="col-md-12 modal-html-content">
              {{smsResponse}}
              <div class="text-xs-right">
                <button *ngIf="!smsSuccessfullySend" class="btn btn-secondary btn-lg" (click)="modalSend.hide()">Abbrechen</button>
                <button *ngIf="smsSuccessfullySend" class="btn btn-success btn-lg" (click)="successAndBack()">Ok</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<custom-modal *ngIf="initializeUrlModal" #urlModal></custom-modal>
