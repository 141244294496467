import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UtilsService, ClientService, ModalService } from '@app/services';
import { I18nPipe } from '@app/shared/pipes/i18n.pipe';
@Component({
  selector: 'app-map-widget',
  templateUrl: 'map-widget.component.html',
  styleUrls: ['map-widget.component.css']
})

/** Map Widget */
export class MapWidgetComponent implements OnInit {
  static client: any;

  @Input()
  page: any;
  bg: string;

  constructor(
    protected router: Router,
    protected clientService: ClientService,
    protected utils: UtilsService,
    protected i18n: I18nPipe,
    protected modalService: ModalService
  ) {
    this.clientService.getClient(false).subscribe(client => {
      MapWidgetComponent.client = client;
    });
  }

  ngOnInit() {}

  openMap() {
    this.modalService.openWidget(this.page);
  }

  /**
	 * toggleActive
	 * @param {Page} page - Page object
	 * @return {void}
	 */
  toggleActive(page: any) {
    if (!page.active) {
      page.active = true;
    } else {
      page.active = false;
    }
  }

  /**
	 * select the description for the widget.
	 * Will be one of the following:
	 * - description
	 * - shortDescription
	 * - void
	 * @param {Page} page
	 * @returns {void}
	 */
  getDescription(page: any) {
    if (page.active && page.description) {
      return page.description;
    } else if (!page.active && page.shortDescription) {
      return page.shortDescription;
    } else {
      return '';
    }
  }

  getBackground(image: any): string {
    const imageUrl = this.i18n.transform(image, false);
    if (imageUrl && imageUrl !== '') {
      return 'url(' + imageUrl + ')';
    }
    return '';
  }

  /**
	 * setStyle
	 * @return {Object} style object
	 */
  setStyle() {
    if (MapWidgetComponent.client && MapWidgetComponent.client.colors) {
      const styles = {
        // CSS property names
        'background-color': MapWidgetComponent.client.colors.main,
        'color': MapWidgetComponent.client.colors.fontLight,
      };
      return styles;
    }
    return {};
  }

}
