import { Injectable } from '@angular/core';

@Injectable()
export class ActivityService {

  lastActive: any = new Date();
  lastScreensaverWidgetOpened: any = new Date(0);
  lastScreensaverWidgetClosed: any = new Date(0);

  public updateLastActive() {
    this.lastActive = new Date();
    this.lastScreensaverWidgetClosed = new Date();
  }

  public updateLastScreensaverWidgetOpened() {
    this.lastScreensaverWidgetOpened = new Date();
  }

  public updateLastScreensaverWidgetClosed() {
    this.lastScreensaverWidgetClosed = new Date();
  }
}
