import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpParams } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { AuthService } from './auth.service';
import { ApiService } from './api.service';
import { UtilsService } from './utils.service';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class AdvertisingPartnerService {

  constructor(
    private authService: AuthService,
    private api: ApiService,
    private utils: UtilsService,
  ) {}

  getAll(): Observable<any> {
    const clientId: string = this.authService.authObject.clientId;
    const deviceGroupId: string = this.authService.authObject.deviceGroupId;

    let parameters = new HttpParams();
    parameters = parameters.append('key', 'ads.' + clientId);
    parameters = parameters.append('deviceGroupId', deviceGroupId);

    return this.api.get('/content/' + clientId, parameters).pipe(map(res => this.utils.extractData(res)));
  }

  getAllGlobal(): Observable<any> {
    const clientId: string = this.authService.authObject.clientId;
    const deviceGroupId: string = this.authService.authObject.deviceGroupId;

    let parameters = new HttpParams();
    parameters = parameters.append('key', 'ads');
    parameters = parameters.append('deviceGroupId', deviceGroupId);

    return this.api.get('/global-content/all/' + clientId, parameters).pipe(map(res => this.utils.extractData(res)));
  }

  verifyAdvertisement(ad): boolean {
    if (this.isValidDateRange(ad) && this.isValidTimeRange(ad)) {
      return this.verifyDateRange(ad) && this.verifyTimeRange(ad);
    } else if (this.isValidTimeRange(ad)) {
      return this.verifyTimeRange(ad);
    } else if (this.isValidDateRange(ad)) {
      return this.verifyDateRange(ad);
    } else {
      return true;
    }
  }

  private isValidTimeRange(ad): boolean {
    return this.utils.isValid(ad.timeFrom) && this.utils.isValid(ad.timeTo);
  }

  private isValidDateRange(ad): boolean {
    return this.utils.isValid(ad.dateRange) && this.utils.isValid(ad.dateRange[0]) && this.utils.isValid(ad.dateRange[1]);
  }

  private verifyDateRange(ad): boolean {
    const currentDate = moment(new Date()).startOf('day');
    const startDate = moment(Date.parse(ad.dateRange[0])).startOf('day');
    const endDate = moment(Date.parse(ad.dateRange[1])).startOf('day');
    return currentDate.isAfter(startDate) && currentDate.isBefore(endDate) ||
      currentDate.isSame(startDate) || currentDate.isSame(endDate);
  }

  private verifyTimeRange(ad): boolean {
    const currentDate = moment(new Date());
    const startDate = moment(Date.parse(ad.timeFrom));
    const endDate = moment(Date.parse(ad.timeTo));
    return this.getSecondsOfDay(currentDate) >= this.getSecondsOfDay(startDate) &&
      this.getSecondsOfDay(currentDate) <= this.getSecondsOfDay(endDate);
  }

  private getSecondsOfDay(date: moment.Moment): number {
    return date.seconds() + date.minutes() * 60 + date.hours() * 60 * 60;
  }
}

export class Advertising {
  constructor(
    public id: number,
    public activated: boolean,
    public name: string,

    public image: any,
    public websiteUrl: string,
    public lowerBanner = { active: false },

    public mapBanner = { active: false, image: '', websiteUrl: '' },

    public screensaver = { active: false, image: '' }
  ) { }
}
