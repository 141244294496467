import { DishSet } from './../services/dish-set.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SortPagesPipe } from '../shared/pipes/sort-pages.pipe';
import { LanguagesService, MenuDay, DishSetService, ModalService, PagesService } from '../services';
import { Router } from '@angular/router';
import * as moment from 'moment';

@Component({
  selector: 'app-dish-set',
  templateUrl: './dish-set.component.html',
  styleUrls: ['./dish-set.component.css']
})
export class DishSetComponent implements OnInit, OnDestroy {

  page: any;
  dishSets: any = [];
  days: MenuDay[] = [];
  loading = true;
  dateRangeDetails: { startDate: moment.Moment, endDate: moment.Moment, days: number };
  private backToMenu$: any;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private dishSetService: DishSetService,
    private languagesService: LanguagesService,
    private modalService: ModalService,
    private pagesService: PagesService,
    private sortPagesPipe: SortPagesPipe) { }

  ngOnInit() {
    moment.locale('de');
    const contentId = this.route.snapshot.params['id'];
    this.pagesService.getPageByID(contentId).subscribe(page => {
      this.page = page;
      this.dishSetService.dishSets$.subscribe(dishSets => {
        this.prepareDateRangeDetails();
        this.dishSets = this.getDishSetsForNextXDays(dishSets);
        this.dishSets = this.sortPagesPipe.transform(this.dishSets);
        if (this.dishSets.length > 0) {
          this.loadDays(this.dishSets);
        }
      });
    });

    this.languagesService.currentLanguage$.subscribe(lang => {
      const langString: string = <string>lang;
      if (langString !== '_') {
        moment.locale(langString.slice(0, 2));
        if (this.dishSets.length > 0) {
          this.loadDays(this.dishSets);
        }
      }
    });

    this.backToMenu$ = this.modalService.backToMenu$.subscribe(res => {
      this.back();
    });
  }

  private prepareDateRangeDetails() {
    switch (this.page.dishSetsOption) {
      case 'WEEKS': {
        const startDate = moment(new Date());
        startDate.add(-(startDate.day() - 1), 'days');
        startDate.add(this.page.dishSetNoOfWeeksAhead, 'weeks');
        this.dateRangeDetails = {
          startDate: startDate,
          endDate: startDate.clone().add(this.page.dishSetNoOfWeeks, 'weeks'),
          days: this.page.dishSetNoOfWeeks * 7
        };
        break;
      }
      case 'DAYS':
      default: {
        const startDate = moment(new Date()).add(this.page.dishSetNoOfDaysAhead, 'days');
        this.dateRangeDetails = {
          startDate: startDate,
          endDate: startDate.clone().add(this.page.dishSetNoOfDays, 'days'),
          days: this.page.dishSetNoOfDays
        };
        break;
      }
    }
  }

  private loadDays(dishSets: any) {
    this.days = [];
    const newDays: MenuDay[] = [];
    for (let i = 0; i < this.dateRangeDetails.days; i++) {
      const day = this.dateRangeDetails.startDate.clone();
      day.add(i, 'days');
      newDays.push({ day: day, dayString: day.format('dddd') });
    }
    this.days = this.trimDaysToDateOfLatestServedDish(newDays, dishSets);
    this.loading = false;
  }

  private trimDaysToDateOfLatestServedDish(days: MenuDay[], dishSets: any): MenuDay[] {
    if (dishSets) {
      let latestDate = new Date();
      dishSets.forEach(dishSet => {
        if (moment(dishSet.dateRange[1]).isAfter(latestDate)) {
          latestDate = dishSet.dateRange[1];
        }
      });
      return days.filter(day => moment(day.day).isBefore(latestDate, 'day') || moment(day.day).isSame(latestDate, 'day'));
    }
  }

  private getDishSetsForNextXDays(dishSets: any) {
    return dishSets
      .filter((dishSet) => dishSet.dateRange)
      .filter((dishSet) => {
        const startDate = moment(dishSet.dateRange[0]);
        const endDate = moment(dishSet.dateRange[1]);
        return (startDate.isBefore(this.dateRangeDetails.endDate, 'day') || startDate.isSame(this.dateRangeDetails.endDate, 'day')) &&
          (endDate.isSame(this.dateRangeDetails.startDate, 'day') || endDate.isAfter(this.dateRangeDetails.startDate, 'day'));
      });
  }

  isDishSetValidIn(dishSet: any, date: moment.Moment) {
    if (dishSet.dateRange) {
      const startDate = moment(dishSet.dateRange[0]);
      const endDate = moment(dishSet.dateRange[1]);

      if (startDate.isBefore(date) || startDate.isSame(date, 'day')) {
        if (endDate.isSame(date, 'day') || endDate.isAfter(date)) {
          return true;
        }
      }
    }
    return false;
  }

  isDishSetValidToday(dishSet: any, date: moment.Moment) {
    if (dishSet.dateRange) {
      const startDate = moment(dishSet.dateRange[0]);
      const endDate = moment(dishSet.dateRange[1]);
      if (startDate.isSame(date, 'day') && endDate.isSame(date, 'day')) {
        return true;
      }
    }
    return false;
  }

  /**
   * back
   * return to the main navigation
   * @return {void}
   */
  back() {
    this.router.navigate(['/']);
  }

  ngOnDestroy() {
    if (this.backToMenu$) {
      this.backToMenu$.unsubscribe();
    }
  }
}
