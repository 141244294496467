import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, share } from 'rxjs/operators';
import { HttpParams } from '@angular/common/http';
import { UtilsService } from './utils.service';
import { ApiService } from './api.service';
import { AuthService } from './auth.service';

/**
 * This service handles all request and modifications for locations from the backend
 */
@Injectable()
export class PlacesService {
  constructor(
    private authService: AuthService,
    private utils: UtilsService,
    private api: ApiService
  ) {}

  /**
   * getAllPlaces
   * delivers all places for a client
   * @return {Observable<any>} An observable with the respone
   */
  getAllPlaces(): Observable<Place[]> {
    return this.makeRequest();
  }

  /**
   * getPlacesByCat
   * returns all places for a given category
   * @param {string} type the category to search poi for
   * @return {Observable<any>} An observable with the respone
   */
  getPlacesByCat(type: string): Observable<Place[]> {
    return this.makeRequest(type);
  }

  /**
   * getPlaceById
   * returns the poi for the given id
   * @param {string} id  - The poi id
   * @return {Observable<any>} An observable with the respone
   */
  getPlaceById(id: string): Observable<Place> {
    const clientId: string = this.authService.authObject.clientId;

    return this.api.get('/content/' + clientId + '/' + id).pipe(
      map(response => {
        if (response.body) {
          const resJson: any = response.body;
          const val = resJson.value;
          val['id'] = resJson.id;
          if (val['properties']) {
            val['properties']['_toString'] = JSON.stringify(val['properties']);
          }
          return val;
        } else {
          return null;
        }
      })
    );
  }

  getCategories(): Observable<any> {
    // get the basics to make a request
    const clientId: string = this.authService.authObject.clientId;

    // set the url parameters
    let parameters = new HttpParams();
    parameters = parameters.append('key', 'poiCategory.' + clientId);

    // send the request
    return this.api
      .get('/content/' + clientId, parameters)
      .pipe(
        map(response => {
          return response.body;
        }),
        share()
      );
  }

  /**
   * makeRequest
   * the main request function. This function makes the actual rest call
   * and delivers the result back to the caller
   * @param {string} type - (optional) If a type is present the pois for this type will be fetched, otherwise all pois are fetched
   * @return {Observable<any>} An observable with the respone
   */
  makeRequest(type?: string): Observable<Place[]> {
    // if no type was specified use blanc
    if (!type) {
      type = '';
    }

    // get the basics to make a request
    const clientId: string = this.authService.authObject.clientId;
    const deviceGroupId: string = this.authService.authObject.deviceGroupId;

    // set the url parameters
    let parameters = new HttpParams();
    parameters = parameters.append('key', 'poi.' + clientId);
    parameters = parameters.append('deviceGroupId', deviceGroupId);

    // send the request
    return this.api
      .get('/content/' + clientId, parameters)
      .pipe(
        map(response => {
          const ret = this.utils
            .extractData(response, 'place')
            .filter(place => {
              if (type && type !== '' && type === place.properties.category) {
                return true;
              }
              if (type && type === '') {
                return true;
              }
              return false;
            });
          return <Place[]>ret;
        }),
        share()
      );
  }
}

export interface Geometry {
  type: string;
  coordinates: Coordinates;
}

export interface PlaceAttributes {
  name: string;
  type: string;
  title: {_: string};
  distanceInMeters: string;
  category: string;
}

export interface Place {
  ID: string;
  geometry: Geometry;
  properties: PlaceAttributes;
  selected: boolean;
}
