import { Component, OnInit } from '@angular/core';
import { FlipCardWidgetComponent } from '../../flip-card-widget/flip-card-widget.component';
import { Router } from '@angular/router';
import { UtilsService, ClientService, ModalService } from '@app/services';
import { I18nPipe } from '@app/shared/pipes/i18n.pipe';

@Component({
  selector: 'app-flip-card-widget-vertical',
  templateUrl: './flip-card-widget-vertical.component.html',
  styleUrls: ['./flip-card-widget-vertical.component.css']
})
export class FlipCardWidgetVerticalComponent extends FlipCardWidgetComponent implements OnInit {

  constructor(
    protected router: Router,
    protected clientService: ClientService,
    protected modalService: ModalService,
    protected utils: UtilsService
  ) {
    super(router, clientService, modalService, utils);
  }

  ngOnInit() {
    super.ngOnInit();
  }

}
