import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { Router } from '@angular/router';
import { SwiperComponent } from 'ngx-useful-swiper';
import {
  ActivityService, ApiService, AuthService, ModalService, UtilsService, ClientService, Advertising,
  AdvertisingPartnerService
} from '@app/services';

@Component({
  selector: 'app-advertising-partner',
  templateUrl: './advertising-partner.component.html',
  styleUrls: ['./advertising-partner.component.css']
})
export class AdvertisingPartnerComponent implements OnInit {

  @ViewChild('modal')
  modal: any;
  @Input()
  smallerAd: boolean;
  backToMenu$: any;
  private advertisements: Array<any>;
  private filteredAdvertisements: Array<any>;
  @ViewChild('swiper')
  swiper: SwiperComponent;
  // change ad interval in sec
  interval = 15;

  config = {
    paginationClickable: false,
    loop: false,
    spaceBetween: 15
  };

  constructor(
    private api: ApiService,
    private activityService: ActivityService,
    private clientService: ClientService,
    private authService: AuthService,
    private advertisingPartnerService: AdvertisingPartnerService,
    private router: Router,
    private modalService: ModalService,
    private utils: UtilsService
  ) { }

  ngOnInit() {
    const clientId: string = this.authService.authObject.clientId;
    this.advertisingPartnerService.getAllGlobal().subscribe(globalAds => {
      const tempAds = new Array<Advertising>();
      globalAds.forEach(ad => {
        if (ad.activated) {
          ad.image = this.utils.buildAmazonawsURL('0', ad.image);
          tempAds.push(ad);
        }
      });

      this.advertisingPartnerService.getAll().subscribe(ads => {
        ads.forEach(ad => {
          if (ad.activated) {
            ad.image = this.utils.buildAmazonawsURL(clientId, ad.image);
            tempAds.push(ad);
          }
        });

        this.advertisements = tempAds;
        this.filteredAdvertisements = tempAds.filter(ad =>
          this.advertisingPartnerService.verifyAdvertisement(ad) && this.isActive(ad)
        );
        this.setAdChangeTimeout();
      });

      this.backToMenu$ = this.modalService.backToMenu$.subscribe(res => {
        if (this.modal) {
          this.modal.modal.hide();
        }
      });
    });
  }

  redirectPartnerPage(ad: any) {
    if (this.utils.isValid(ad.websiteUrl) && ad.websiteUrl !== '') {
      this.modal.showIframeModal({ '_': ad.websiteUrl }, ad.name);
    }
  }

  private setAdChangeTimeout() {
    const milliSecInterval = this.interval * 1000;
    setTimeout(() => {
      this.filteredAdvertisements = this.advertisements.filter(ad =>
        this.advertisingPartnerService.verifyAdvertisement(ad) && this.isActive(ad)
      );
      if (this.swiper && !this.swiper.swiper.slideNext()) {
        this.swiper.swiper.slideTo(0, milliSecInterval / 2 > 500 ? 500 : milliSecInterval / 2);
      }
      this.setAdChangeTimeout();
    }, milliSecInterval);
  }

  getFilteredAdvertisements(): Array<any> {
    if (!this.utils.isValid(this.filteredAdvertisements)) {
      return [];
    }
    return this.filteredAdvertisements;
  }

  private isActive(ad: Advertising): boolean {
    return ad.lowerBanner && ad.lowerBanner.active;
  }
}
