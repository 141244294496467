import { Pipe, PipeTransform } from '@angular/core';
import { AuthService } from '../../services/auth.service';

/**
 * this pipe sorts the content from widgets object, the premise
 * is a order property
 */
@Pipe({
  name: 'sortWidgets'
})
export class SortWidgetsPipe implements PipeTransform {

  constructor(
    private authService: AuthService
  ) { }

  transform(value: any, args?: any): any {
    if (value) {
      value.sort((a, b) => {
        return a.order - b.order;
      });
    }
    return value;
  }
}
