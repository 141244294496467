import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

/**
 * AuthObject describes the Response of the auth service
 */
export class AuthObject {
  constructor(
    /** The JWT Token */
    public token: string,
    /** The DeviceGroupId */
    public deviceGroupId: string,
    /** The ClintID */
    public clientId: string,
    /** The Amazon AWS bucket name */
    public awsBucketName: string,
    /** The Amazon AWS bucket name */
    public buildVersion: string,
    /** The DeviceId */
    public deviceId: string
  ) { }
}

/**
 * This Service provides funtion to authenticate to the backendURL
 * and handles all errors regarding the requests.
 */
@Injectable()
export class AuthService {
  redirectUrl: string;
  authObject: AuthObject;

  constructor(private _http: HttpClient) { }

  /**
	 * The login function authenticates the device to the lobbit backend
	 * @param {string} authString - The mac address or IMEI of the device
	 * @return {Observable} The Response Object
	 */
  login(authString: string, password: string): Observable<AuthObject> {
    if (authString && authString !== '') {
      const body = {};
      body['authentication'] = authString;
      body['password'] = password;
      const apiURL: string = localStorage.getItem('backendURL') + '/api/v2/account/device';
      const headers: HttpHeaders = new HttpHeaders({ 'Content-Type': 'application/json' });
      const options = { headers: headers };
      const obs = this._http.post(apiURL, body, options).pipe(
        map(this._handleResponse),
        catchError(this._handleError),
      );
      obs.subscribe(authObject => {
        if (authObject) {
          this.authObject = <AuthObject>authObject;
        }
      });
      return obs;
    }
  }

  /**
	 * This function transforms the result from the Server to the AuthObject
	 * @param {Response} res - The response object from the Server
	 * @return {AuthObject} The AuthObject
	 */
  private _handleResponse(res: Response): AuthObject {
    const resAsJson = res;
    const formatedRes: AuthObject = new AuthObject(
      resAsJson['token'],
      resAsJson['deviceGroupId'],
      resAsJson['clientId'],
      resAsJson['awsBucketName'],
      resAsJson['buildVersion'],
      resAsJson['deviceId']
    );
    return formatedRes;
  }

  /**
	 * If an error occures on the http request it will be handelt here
	 * @param {any} error - the error object
	 */
  private _handleError(error: any) {
    const errMsg = (error.message) ? error.message :
      error.status ? `${error.status} - ${error.statusText}` : 'Server error';
    console.error(errMsg); // log to console
    return throwError(errMsg);
  }

}
