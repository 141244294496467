<div class="widget-wrapper">
  <div *ngFor="let page of currentWidgets | sortWidgets">
    <div class="column" [ngSwitch]="page.type" >
      <app-content-widget-vertical *ngSwitchCase="'normal'" [page]="page"></app-content-widget-vertical>
      <app-content-widget-vertical *ngSwitchCase="'web'" [page]="page"></app-content-widget-vertical>
      <app-static-widget-vertical *ngSwitchCase="'static'" [page]="page"></app-static-widget-vertical>
      <app-content-widget-vertical *ngSwitchCase="'forecast'" [page]="page"></app-content-widget-vertical>
      <app-map-widget-vertical *ngSwitchCase="'map'" [page]="page"></app-map-widget-vertical>
      <app-weather-current-widget-vertical *ngSwitchCase="'weather'" [page]="page"></app-weather-current-widget-vertical>
      <app-flip-card-widget-vertical *ngSwitchCase="'flip'" [page]="page"></app-flip-card-widget-vertical>
      <app-sms-widget-vertical *ngSwitchCase="'sms'" [page]="page"></app-sms-widget-vertical>
      <app-email-widget-vertical *ngSwitchCase="'email'" [page]="page"></app-email-widget-vertical>
      <app-content-widget-vertical *ngSwitchCase="'feedback'" [page]="page"></app-content-widget-vertical>
      <app-content-widget-vertical *ngSwitchCase="'slideshow'" [page]="page"></app-content-widget-vertical>
      <app-content-widget-vertical *ngSwitchCase="'events'" [page]="page"></app-content-widget-vertical>
      <app-content-widget-vertical *ngSwitchCase="'dish-set'" [page]="page"></app-content-widget-vertical>
    </div>
  </div>
</div>
