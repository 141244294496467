<div class="language-footer" *ngIf="languages && languages.length > 1">
  <ul>
    <li *ngFor="let lang of languages | slice:0:3 | sortPages" [class.selected]="currentLang == lang['key']" (click)="onSelect(lang['key'])">
      <span>{{lang.description}}</span>
    </li>
    <li *ngIf="languages && languages.length > 3" (click)="openAllLanguagesOverlay()">
      <div class="pseudoimg">
        <i class="material-icons">language</i>
      </div>
    </li>
  </ul>
</div>

<!---  ModalView-->
<div bsModal #lgModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header" [ngStyle]="setHeaderStyle()">
        <button type="button" class="close" aria-label="Close" [ngStyle]="setHeaderStyle()" (click)="lgModal.hide()">
          <span aria-hidden="true" class="close-description">{{'std.closeHere' | translate}} </span>
          <i class="material-icons close-icon icon-centered">close</i>
        </button>
        <h4 class="modal-title">
          <i class="material-icons">language</i>
        </h4>
      </div>
      <div class="modal-body">
        <ul>
          <li *ngFor="let lang of languages | sortPages" [class.selected]="currentLang == lang['key']" (click)="onSelect(lang['key'])">
            <span>{{lang.description}}</span>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
