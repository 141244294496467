import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { ClientService, Page, CurrentWeatherObject, WeatherService, ModalService } from '@app/services';
import { I18nPipe } from '@app/shared/pipes/i18n.pipe';

@Component({
  selector: 'app-weather-current-widget',
  templateUrl: 'weather-current-widget.component.html',
  styleUrls: ['weather-current-widget.component.css'],
})

/**
 * Current weather widget
 */
export class WeatherCurrentWidgetComponent implements OnInit {
  @Input()
  page: Page;
  client: any;
  currentWeather: CurrentWeatherObject;
  constructor(
    protected router: Router,
    protected weatherService: WeatherService,
    protected clientService: ClientService,
    protected i18n: I18nPipe,
    protected modalService: ModalService
  ) { }

  ngOnInit() {
    this.clientService.getClient().subscribe(client => {
      this.client = client;
    });
    // If there is a current weather, set it in the view
    if (this.weatherService.currentWeather) {
      this.currentWeather = this.weatherService.currentWeather;
    }
    // If weather changes, set it in the view
    this.weatherService.currentWeather$.subscribe(cw => {
      this.currentWeather = cw;
    });
  }

  getBackgroundPosition() {
    if (this.page && this.page['centerImage']) {
      return 'center';
    }
    return 'left top';
  }

  getBackground(image: any): string {
    const imageUrl = this.i18n.transform(image, false);
    if (imageUrl && imageUrl !== '') {
      return 'url(' + imageUrl + ')';
    }
    return '';
  }

  showDetailPage() {
    this.modalService.openWidget(this.page);
  }

  /**
	 * setFontStyle
	 * this function is called by the dom elems to check style information
	 * @return {Object} style class
	 */
  setFontStyle() {
    if (this.client && this.client.colors) {
      const styles = {
        'color': this.client.colors.main,
      };
      return styles;
    }
    return {};
  }

}
