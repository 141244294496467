import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { LanguageComponent } from './language.component';
import { SharedModule } from '../shared';
import { ModalModule } from 'ngx-bootstrap/modal';
import { ClientService, LanguagesService, WeatherService } from '../services';

@NgModule({
  imports: [
    CommonModule, SharedModule, ModalModule.forRoot(), TranslateModule.forChild()
  ],
  declarations: [LanguageComponent],
  exports: [LanguageComponent],
  providers: [ClientService, LanguagesService, WeatherService]
})
export class LanguageModule { }
