<div *ngIf="currentWeather" class="weather-current-widget" (click)="showDetailPage()">
  <div class="widget-img" [ngStyle]="{ 'background-image' : getBackground(page.image)}">
  </div>
  <div class="background-area" [ngStyle]="{ 'background-position' : getBackgroundPosition() }">
    <div class="text-wrapper">
      <h4 [ngStyle]="setFontStyle()">{{page.title | i18n}}</h4>
      <div class="weather-info">
        <span class="temperature">{{ currentWeather.temp | number:'1.0-0' }}°C</span>
        <div class="weather-icon-block" *ngIf="currentWeather.weather && currentWeather.weather[0]">
          <img class="weather-icon" src="https://openweathermap.org/img/wn/{{currentWeather.weather[0].icon}}.png">
        </div>
      </div>
      <h5 [ngStyle]="setFontStyle()" *ngIf="currentWeather.weather && currentWeather.weather[0]">
        {{currentWeather.weather[0].description}}
      </h5>
    </div>
  </div>
</div>
