import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WeatherCurrentComponent } from './weather-current.component';
import { WidgetHeaderModule } from '../widget-header/widget-header.module';
import { WeatherService } from '../services';

@NgModule({
  imports: [
    CommonModule, WidgetHeaderModule
  ],
  declarations: [WeatherCurrentComponent],
  exports: [WeatherCurrentComponent],
  providers: [WeatherService]
})
export class WeatherCurrentModule { }
