<div class="static-widget" [ngStyle]="setBGStyle()">
  <div class="widget-img" [ngStyle]="{ 'background-image' : getBackground(page.image)}">
  </div>
  <div class="background-area">
    <div *ngIf="page.description" class="title-wrapper">
      <h4 class="card-static" [ngStyle]="setFontStyle()">{{page.description | i18n}}</h4>
      <h5 class="card-static" [ngStyle]="setFontStyle()">{{page.shortDescription | i18n}}</h5>
    </div>
  </div>
</div>
