<div *ngIf="currentWeather" class="weather-current-widget" (click)="showDetailPage()">
  <div class="background-area" [ngStyle]="{ 'background-image' : getBackground(page.image), 'background-position' : getBackgroundPosition() }">
    <h3 class="card-title" [ngStyle]="setFontStyle()" [ngClass]="{'largeLabels': page.largeLabels}">{{page.title | i18n}}</h3>
    <div class="weather-info" [ngClass]="{'weather-info-large': page.largeLabels}">
      <span class="temperature">{{ currentWeather.temp | number:'1.0-0' }}°C</span>
      <div class="weather-icon-block" *ngIf="currentWeather.weather && currentWeather.weather[0]">
        <img class="weather-icon" src="https://openweathermap.org/img/wn/{{currentWeather.weather[0].icon}}.png">
      </div>
    </div>
    <div class="description" *ngIf="currentWeather.weather && currentWeather.weather[0]">
      {{currentWeather.weather[0].description}}
    </div>
  </div>
</div>
