import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { SmsComponent } from './sms.component';
import { SharedModule } from '../shared';
import { WidgetHeaderModule } from '../widget-header/widget-header.module';
import { AlertModule } from 'ngx-bootstrap/alert';
import { ModalModule } from 'ngx-bootstrap/modal';
import { TimepickerModule } from '../timepicker/timepicker.module';
import { CustomModalModule } from '../custom-modal/custom-modal.module';
import { ClientService, ModalService, PagesService, SmsService, UtilsService } from '../services';
import { NgSelectModule } from '@ng-select/ng-select';

@NgModule({
  imports: [
    CommonModule, SharedModule, WidgetHeaderModule, FormsModule, TranslateModule.forChild(), AlertModule.forRoot(),
    ModalModule.forRoot(), TimepickerModule, CustomModalModule, NgSelectModule
  ],
  declarations: [SmsComponent],
  exports: [SmsComponent],
  providers: [ClientService, ModalService, PagesService, SmsService, UtilsService]
})
export class SmsModule { }
