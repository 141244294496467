<div class="sms-widget">
  <div class="widget-img" [ngStyle]="{ 'background-image' : getBackground(page.image)}" (click)="open()">
  </div>
  <div class="background-area">
    <div *ngIf="page.title" (click)="open()" class="title-wrapper" [ngStyle]="setBGStyle()">
      <h4 [ngStyle]="setFontStyle()" class="card-title">{{page.title | i18n}}</h4>
      <h5 [ngStyle]="setFontStyle()" class="card-title">{{page.description | i18n}}</h5>
    </div>
  </div>
</div>
