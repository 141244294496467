import { Injectable } from '@angular/core';
import { HttpResponse } from '@angular/common/http';
import { Angulartics2 } from 'angulartics2';
import { environment } from '../../environments/environment';
import { AuthService } from './auth.service';
import { Observable, throwError } from 'rxjs';

@Injectable()
export class UtilsService {
  constructor(
    private authService: AuthService,
    private angulartics2: Angulartics2
  ) {}

  /**
   * This function transform the received data from the backend to a list of page objects.
   * Additionally this function filters active pages.
   * @param {Response} res - The response object from the Server
   * @param {string} type - (optional) the service which calls the function (needed for advanced filters)
   * @return {any[]} The list that was transformed
   */
  public extractData(res: HttpResponse<any>, type?: string): any {
    const respAsJson = res.body;
    let content: any;
    if (respAsJson && respAsJson.length) {
      content = respAsJson.map(data => {
        let val = {};
        if (type && type === 'widget') {
          val = data.content.value;
          val['id'] = data.content.id;
          val['order'] = data.sortOrder;
        } else {
          val = data.value;
          val['id'] = data.id;
          val['pageContents'] = data.pageContents;
        }
        // if its a place use the stringify hack to enable custom languages support in the mapbox-gl compontent
        if (type && type === 'place' && val['properties']) {
          val['properties']['_toString'] = JSON.stringify(val['properties']);
        }

        return val;
      });

      // if the client is fetched check only the languages and return
      if (type && type === 'client') {
        const val = content[0];
        // Filter active languages
        if (val['locales']) {
          val['locales'] = val['locales'].filter(locale => {
            if (locale.active) {
              return true;
            }
            return false;
          });
        }
        return val;
      }

      if (type && type === 'place') {
        content = content.filter(place => {
          // some basic filtering e.g check if its active and if a cat was given
          if (place && place.properties.activated) {
            return true;
          }
          return false;
        });
      }

      // check if any filtering is needed
      content = content.filter(item => {
        // check if an activation field exists and is a boolen
        // else ignore it
        if (
          (item && typeof item.active === 'boolean') ||
          typeof item.activated === 'boolean'
        ) {
          if (item && (item.activated || item.active)) {
            return true;
          }
        } else {
          return true;
        }
        return false;
      });
    } else {
      let val = {};
      val = (respAsJson && respAsJson.value) ? respAsJson.value : respAsJson;
      if (val) {
        val['id'] = respAsJson && respAsJson.id ? respAsJson.id : null;
      }
      content = val;
    }
    return content;
  }

  /**
   * If an error occures on the http request it will be handelt here
   * @param {any} error - the error object
   * @return {Observable<any>}
   */
  public handleError(error: any): Observable<any> {
    const errMsg = error.message
      ? error.message
      : error.status
        ? `${error.status} - ${error.statusText}`
        : 'Server error';
    console.error(errMsg); // log to console instead
    return throwError(errMsg);
  }

  /**
   * ensures backwards compatibility for strings
   * @param {any} text - the text or object
   * @return {string}
   */
  public toStringComp(text: any): string {
    if (typeof text === 'object' && text['_']) {
      return text['_'];
    } else {
      return <string>text;
    }
  }

  public getAwsBucketUrlRegex(bucket: string): RegExp {
    const oldValue = 'https://' + bucket + '.s3.amazonaws.com/img/';
    return new RegExp(oldValue, 'g');
  }

  public buildAmazonawsURL(clientId: string, key: string): string {
    const amazonAwsBucketName: string = environment.amazonAwsBucketName
      ? environment.amazonAwsBucketName
      : 'live-bit4m';
    return clientId &&
      key &&
      typeof clientId !== 'undefined' &&
      typeof key !== 'undefined'
      ? amazonAwsBucketName === 'live-bit4m' && environment.cdnAddress
        ? 'https://' + environment.cdnAddress + '/img/' + clientId + '/' + key
        : 'https://' + amazonAwsBucketName + '.s3.amazonaws.com/img/' + clientId + '/' + key
      : '';
  }

  public hexToRgb(hex, alpha): string {
    hex = hex.replace('#', '');
    const r = parseInt(
      hex.length === 3 ? hex.slice(0, 1).repeat(2) : hex.slice(0, 2),
      16
    );
    const g = parseInt(
      hex.length === 3 ? hex.slice(1, 2).repeat(2) : hex.slice(2, 4),
      16
    );
    const b = parseInt(
      hex.length === 3 ? hex.slice(2, 3).repeat(2) : hex.slice(4, 6),
      16
    );
    if (alpha || alpha === 0) {
      return 'rgba(' + r + ', ' + g + ', ' + b + ', ' + alpha + ')';
    } else {
      return 'rgb(' + r + ', ' + g + ', ' + b + ')';
    }
  }

  public getHrefRegex(): RegExp {
    const oldValue = 'href=';
    return new RegExp(oldValue, 'g');
  }

  /**
   * redirectWebWidget
   * redirects to URL from web widget. Usable when page is not compatible with iframes.
   */
  public redirectWebWidget(url, lang): void {
    let src;
    if (url[lang]) {
      src = url[lang];
    } else if (url['_']) {
      src = url['_'];
    } else if (url) {
      src = url;
    }
    if (src) {
      src = this.addStartUrlParam(src);
      window.location.href = src;
    }
  }

  public addStartUrlParam(url): string {
    const loc = window.location;
    const encodedUrl = encodeURIComponent(loc.protocol + '//' + loc.hostname + '/auth.html?mac=' + window.localStorage.getItem('mac'));
    if (url.slice(url.indexOf('/') + 1).indexOf('?') === -1) {
      url = url + '?panelLobbitStartUrl=' + encodedUrl;
    } else {
      url = url + '&panelLobbitStartUrl=' + encodedUrl;
    }
    return url;
  }

  public getUrlParams(url, lang): any {
    let src;
    if (url[lang]) {
      src = url[lang];
    } else if (url['_']) {
      src = url['_'];
    } else if (url) {
      src = url;
    }
    const paramObj = {};
    if (src) {
      src = decodeURI(src);
      if (typeof src === 'string' && src.split('?')[1]) {
        const eachParamsArr = src.split('?')[1].split('&');
        if (eachParamsArr && eachParamsArr.length) {
          eachParamsArr.map(param => {
            const keyValuePair = param.split('=');
            const key = keyValuePair[0];
            const value = keyValuePair[1];
            paramObj[key] = value;
          });
        }
      }
    }
    return paramObj;
  }

  public prepareWidget(widget, preloadImage): void {
    const amazonAwsBucketName: string = this.authService.authObject
      ? this.authService.authObject.awsBucketName
      : '';
    if (widget.content && typeof widget.content === 'object') {
      for (const locale of Object.keys(widget.content)) {
        // If it is no object it is the image ID
        if (typeof widget.content[locale] !== 'object') {
          widget.content[locale] = this.replaceAwsBucketUrl(widget.content[locale], amazonAwsBucketName);
          widget.content[locale] = widget.content[locale].replace(
            this.getHrefRegex(),
            'source='
          );
        }
        if (preloadImage) {
          const regex = /src\=\"https:\/\/.*?(jpeg|jpg|gif|png)\"/g;
          const match = regex.exec(widget.content[locale]);
          if (match) {
            this.preloadImage(
              match[0].replace(/src\=/g, '').replace(/\"/g, '')
            );
          }
        }
      }
    } else if (
      widget.content &&
      widget.content !== undefined &&
      widget.content !== ''
    ) {
      widget.content = this.replaceAwsBucketUrl(widget.content, amazonAwsBucketName);
      widget.content = widget.content.replace(this.getHrefRegex(), 'source=');
    }
  }

  public replaceAwsBucketUrl(widgetContent, amazonAwsBucketName): any {
    widgetContent = widgetContent.replace(
      this.getAwsBucketUrlRegex(
        environment.amazonAwsBucketNamePlaceholder
      ),
      amazonAwsBucketName === 'live-bit4m' && environment.cdnAddress
        ? 'https://' + environment.cdnAddress + '/img/'
        : 'https://' + amazonAwsBucketName + '.s3.amazonaws.com/img/'
    );
    // replace aws url when value is encoded
    if (widgetContent.includes(encodeURIComponent(environment.amazonAwsBucketNamePlaceholder).toLowerCase())) {
      widgetContent = widgetContent.replace(
        this.getAwsBucketUrlRegex(
          encodeURIComponent(environment.amazonAwsBucketNamePlaceholder).toLowerCase()
        ),
        amazonAwsBucketName === 'live-bit4m' && environment.cdnAddress
          ? 'https://' + environment.cdnAddress + '/img/'
          : 'https://' + amazonAwsBucketName + '.s3.amazonaws.com/img/'
      );
    }
    return widgetContent;
  }

  public preloadImage(src: string): void {
    const img = new Image();
    // img.addEventListener("load", function(event) {
    // 	page.background[locale] = img.src;
    // });
    setTimeout(() => {
      // preload all images with delay to speed up loading first page
      img.src = src;
    }, 4000);
  }

  public wrapContentToFroalaElement(content) {
    if (content && typeof content === 'object') {
      for (const key of content) {
        content[key] =
          '<div class="fr-element fr-view">' + content[key] + '</div>';
      }
    } else if (content && typeof content === 'string') {
      content = '<div class="fr-element fr-view">' + content + '</div>';
    }
  }

  isValid(value: any): boolean {
    return value !== undefined && value !== null;
  }
}
