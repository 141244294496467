<div class="weather-wrapper">
	<app-widget-header (onClose)="back()"></app-widget-header>
	<div class="weather-content">
		<div class="days" #contentElem>
			<div *ngFor="let day of keys()" class="day-wrapper">
				<h2>{{day}}</h2>
				<div class="row">
					<div *ngFor="let item of weather.list[day]" class="horizontal-list-item col-md-3">
						<h3>{{item.time}}</h3>
						<h4 class="temp">{{ item.main.temp | number:'1.0-0' }} °C</h4>
						<div class="icon-with-text" *ngFor="let w of item.weather">
							<img class="weather-icon" src="https://openweathermap.org/img/wn/{{w.icon}}.png"> {{ w.description }}
						</div>
					</div>
				</div>
			</div>
		</div>
    <i class="material-icons arrow" *ngIf="!isEndOfPage" (click)="scroll()">keyboard_arrow_down</i>
	</div>
</div>
