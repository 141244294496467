import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { EventsComponent } from './events.component';
import { SharedModule } from '../shared';
import { WidgetHeaderModule } from '../widget-header/widget-header.module';
import { EventsService, ModalService, PagesService } from '../services';

@NgModule({
  imports: [
    CommonModule, SharedModule, TranslateModule.forChild(), WidgetHeaderModule
  ],
  declarations: [EventsComponent],
  exports: [EventsComponent],
  providers: [EventsService, ModalService, PagesService]
})
export class EventsModule { }
