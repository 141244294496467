<div class="feedback-component-wrapper">
	<app-widget-header [header]="page ? (page.title | i18n) : null" (onClose)="back()"></app-widget-header>
	<div class="feedback-content-wrapper">
		<div class="container-fluid">
			<div class="row" *ngIf="page">
				<div class="col-sm-12">
					<form #feedbackForm="ngForm">

						<table class="table">
							<tbody>
								<tr class="page-row" *ngIf="page.askForRoomNumber">
								<td class="ask-number-borders">
									<input type="text" class="form-control form-control-lg input-number" [(ngModel)]="roomNumber" ngControl="roomNr" name="roomNr" #roomNr="ngModel"
										id="roomNumberInput" placeholder="{{'sms.roomNumber' | translate}}" [value]="roomNumber" (keyup)="keyUp($event)" required>
									<div [hidden]="roomNr.valid || roomNr.pristine" class="alert alert-danger">
									{{'sms.roomNumberAlert' | translate}}
									</div>
								</td>
								<td class="ask-number-borders"></td>
								</tr>

								<tr class="page-row" *ngFor="let question of page.questions; let i = index">
									<td [class]="question.notRequired ? 'question-container' : 'question-container required'">
										<span>{{ question | i18n }}</span>
									</td>
									<td class="clear-borders smileys-container">
										<table class="clear-borders smileys-wrapper">
											<td class="smiley-cell" (click)="answer(i, 5)" [ngClass]="{ 'answered': question.answer == 5 }">
												<div class="smiley-container" [ngStyle]="{ 'background-image': question.answer == 5 ? 'url(/assets/smiley-faces/smiley-5.svg)' : 'url(/assets/smiley-faces/smiley-5-desaturated.svg)' }"></div>
											</td>
											<td class="smiley-cell" (click)="answer(i, 4)" [ngClass]="{ 'answered': question.answer == 4 }">
												<div class="smiley-container" [ngStyle]="{ 'background-image': question.answer == 4 ? 'url(/assets/smiley-faces/smiley-4.svg)' : 'url(/assets/smiley-faces/smiley-4-desaturated.svg)' }"></div>
											</td>
											<td class="smiley-cell" (click)="answer(i, 3)" [ngClass]="{ 'answered': question.answer == 3 }">
												<div class="smiley-container" [ngStyle]="{ 'background-image': question.answer == 3 ? 'url(/assets/smiley-faces/smiley-3.svg)' : 'url(/assets/smiley-faces/smiley-3-desaturated.svg)' }"></div>
											</td>
											<td class="smiley-cell" (click)="answer(i, 2)" [ngClass]="{ 'answered': question.answer == 2 }">
												<div class="smiley-container" [ngStyle]="{ 'background-image': question.answer == 2 ? 'url(/assets/smiley-faces/smiley-2.svg)' : 'url(/assets/smiley-faces/smiley-2-desaturated.svg)' }"></div>
											</td>
											<td class="smiley-cell" (click)="answer(i, 1)" [ngClass]="{ 'answered': question.answer == 1 }">
												<div class="smiley-container" [ngStyle]="{ 'background-image': question.answer == 1 ? 'url(/assets/smiley-faces/smiley-1.svg)' : 'url(/assets/smiley-faces/smiley-1-desaturated.svg)' }"></div>
											</td>
										</table>
									</td>
								</tr>
                <div>{{"feedback.requiredQuestions" | translate}}</div>
                <br>

                <tr class="page-row" *ngIf="page.choiceQuestion && page.choiceQuestion.active">
                  <td class="clear-borders question-container">
                    <span >{{ page.choiceQuestion.question | i18n }}</span>
                  </td>
                  <td class="clear-borders smileys-container">
                    <ng-select id="copyPage" class="choiceSelect" name="choiceQuestion" [(ngModel)]="page.choiceQuestion.answer" [searchable]="false">
                      <ng-option *ngFor="let choice of page.choiceQuestion.choices" [value]="getChoiceValue(choice)">{{ choice | i18n }}</ng-option>
                    </ng-select>
                  </td>
				        </tr>
								<br>
								<tr class="page-row" *ngIf="page.additionalTextForm && page.additionalTextForm.active" >
									<td class="clear-borders">
										<span >{{ page.additionalTextForm.labelName | i18n }}</span>
									</td>
									<td class="clear-borders">
										<textarea id="additionalTextFormInput" name="additionalTextValue" class="form-control form-control-lg"
											[(ngModel)]="additionalTextValue" [value]="additionalTextValue" style="resize: none;" rows="4"   ></textarea>
									</td>
								</tr>
							</tbody>
						</table>
            <br>

						<div class="form-group">
							<button type="button" class="btn btn-success lsmaller" (click)="submitFeedback()" [disabled]="sending || !answeredAllRequired()">
								{{'sms.send' | translate}}
								<div *ngIf="sending" class="sk-fading-circle">
									<div class="sk-circle1 sk-circle"></div>
									<div class="sk-circle2 sk-circle"></div>
									<div class="sk-circle3 sk-circle"></div>
									<div class="sk-circle4 sk-circle"></div>
									<div class="sk-circle5 sk-circle"></div>
									<div class="sk-circle6 sk-circle"></div>
									<div class="sk-circle7 sk-circle"></div>
									<div class="sk-circle8 sk-circle"></div>
									<div class="sk-circle9 sk-circle"></div>
									<div class="sk-circle10 sk-circle"></div>
									<div class="sk-circle11 sk-circle"></div>
									<div class="sk-circle12 sk-circle"></div>
								</div>
							</button>
						</div>
					</form>

				</div>
			</div>



			<div bsModal #modalSend="bs-modal" [config]="{backdrop: 'static'}" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="smallModalLabel"
			 aria-hidden="true">
				<div class="modal-dialog modal-lg">
					<div class="modal-content">
						<div class="modal-header" [ngStyle]="setHeaderStyle()">
							<h4 class="modal-title">{{'feedback.title' | translate}}</h4>
						</div>
						<div class="modal-body">
							<div class="container-fluid">
								<div class="row">
									<div class="col-md-12 modal-html-content">
                    {{feedbackSuccessfullySend ? ('feedback.successMessage' | translate) : ('feedback.errorMessage' | translate)}}
										<div class="text-xs-right">
											<button *ngIf="!feedbackSuccessfullySend" class="btn btn-secondary btn-lg" (click)="modalSend.hide()">{{'feedback.abort' | translate}}</button>
											<button *ngIf="feedbackSuccessfullySend" class="btn btn-success btn-lg" (click)="successAndBack()">{{'feedback.ok' | translate}}</button>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
