import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { ModalModule } from 'ngx-bootstrap/modal';
import { FeedbackComponent } from './feedback.component';
import { SharedModule } from '../shared';
import { WidgetHeaderModule } from '../widget-header/widget-header.module';
import { ClientService, ModalService, PagesService, EmailService } from '../services';
import { NgSelectModule } from '@ng-select/ng-select';

@NgModule({
  imports: [
    CommonModule, SharedModule, WidgetHeaderModule, FormsModule, TranslateModule.forChild(), ModalModule.forRoot(), NgSelectModule
  ],
  declarations: [FeedbackComponent],
  exports: [FeedbackComponent],
  providers: [ClientService, ModalService, PagesService, EmailService]
})
export class FeedbackModule { }
