import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { WidgetHeaderComponent } from './widget-header.component';
import { ClientService } from '../services';

@NgModule({
  imports: [
    CommonModule, TranslateModule.forChild(),
  ],
  declarations: [WidgetHeaderComponent],
  exports: [WidgetHeaderComponent],
  providers: [ClientService]
})
export class WidgetHeaderModule { }
