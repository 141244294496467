import { AuthService } from './auth.service';
import { map } from 'rxjs/operators';
import { ModalService } from '@app/services';
import { Router } from '@angular/router';
import { UtilsService } from './utils.service';
import { ApiService } from './api.service';
import { ClientService } from './client.service';
import { Advertising, AdvertisingPartnerService } from './advertising-partner.service';
import { ActivityService } from './activity.service';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

export enum ScreensaverState {
  SHOW_WIDGET,
  SHOW_ADS,
  CLOSE
}

@Injectable({
  providedIn: 'root'
})
export class ScreensaverService {
  private readonly checkHandleScreensaverTimeInSeconds = 10000;
  private readonly defaultInActivityIdleTime: number = 15;
  private readonly widgetTimeActiveInSeconds = 30;

  screensaverAds: Array<Advertising>;
  screensaverWidget: any;
  private state = ScreensaverState.CLOSE;

  private lastTimeWidgetActive: Date;
  private iterationNumber = 0;
  private handleScreensaver$: any;
  private client: any;
  private deviceTypeIdleTime: number;

  constructor(
    private activityService: ActivityService,
    private clientService: ClientService,
    private api: ApiService,
    private advertisingPartnerService: AdvertisingPartnerService,
    private utils: UtilsService,
    private authService: AuthService,
    private router: Router,
    private modalService: ModalService,
  ) {
    this.init();
  }

  private init() {
    this.clientService.client$.subscribe(client => {
      this.client = client;
      const deviceGroupId = localStorage.getItem('deviceGroupId');
      this.api
        .get(/devicegroup/ + this.getClientId() + '/idletime/' + deviceGroupId)
        .subscribe(result => {
          if (typeof result.body === 'number') {
            this.deviceTypeIdleTime = result.body;
          }
        });
      this.getScreensaverWidget().subscribe(widget => this.screensaverWidget = widget);
      this.getScreensaverAds().subscribe(o => o.subscribe(ads => this.screensaverAds = ads));
    });
  }

  public handleScreensaver() {
    if (this.isInactive() && this.isScreensaverEnabled()) {
      if (this.isValidScreensaverWidget() && (this.state === ScreensaverState.CLOSE || this.state === ScreensaverState.SHOW_WIDGET)) {
        if (this.state !== ScreensaverState.SHOW_WIDGET) {
          this.lastTimeWidgetActive = new Date(new Date().getTime() + this.widgetTimeActiveInSeconds * 1000);
        }
        this.showScreensaverWidget();
      } else if (this.isValidScreensaverAds() && (this.state === ScreensaverState.CLOSE || this.state === ScreensaverState.SHOW_ADS)) {
        this.showScreensaverAds();
      } else {
        this.state = ScreensaverState.CLOSE;
        this.navigateToMainScreen();
        this.activityService.updateLastScreensaverWidgetClosed();
      }
    }

    this.handleScreensaver$ = setTimeout(() => {
      if (new Date() >= this.lastTimeWidgetActive) {
        this.redirectWidgetToAds();
      }
      this.handleScreensaver();
      this.refreshData();
    }, this.checkHandleScreensaverTimeInSeconds);
  }

  private isInactive() {
    return new Date().getTime() - this.activityService.lastActive.getTime() > (this.isScreensaverEnabled()
      ? this.client.screensaver.idle
      : (this.deviceTypeIdleTime ? this.deviceTypeIdleTime : this.defaultInActivityIdleTime)) * 60 * 1000;
  }

  private showScreensaverAds() {
    this.state = ScreensaverState.SHOW_ADS;
    this.router.navigate(['/ad-screensaver']);
    this.activityService.updateLastScreensaverWidgetOpened();
  }

  private showScreensaverWidget() {
    this.state = ScreensaverState.SHOW_WIDGET;
    this.utils.prepareWidget(this.screensaverWidget, false);
    this.modalService.openWidget(this.screensaverWidget, true);
    this.activityService.updateLastScreensaverWidgetOpened();
  }

  public redirectWidgetToAds() {
    if (this.isValidScreensaverAds() && this.state === ScreensaverState.SHOW_WIDGET) {
      this.modalService.backToMenu();
      this.showScreensaverAds();
    }
  }

  public redirectAdsToWidget() {
    if (this.isValidScreensaverWidget() && this.state === ScreensaverState.SHOW_ADS) {
      this.lastTimeWidgetActive = new Date(new Date().getTime() + this.widgetTimeActiveInSeconds * 1000);
      this.router.navigate(['/']);
      this.showScreensaverWidget();
    }
  }

  public closeAds() {
    this.router.navigate(['/']);
    this.updateLastActive();
  }

  public updateLastActive() {
    this.state = ScreensaverState.CLOSE;
    this.activityService.updateLastActive();
  }

  public clearTimeout() {
    if (this.handleScreensaver$) {
      this.state = ScreensaverState.CLOSE;
      clearTimeout(this.handleScreensaver$);
    }
  }

  private navigateToMainScreen() {
    if (this.state === ScreensaverState.SHOW_ADS) {
      this.router.navigate(['/']);
    } else if (this.state === ScreensaverState.SHOW_WIDGET) {
      this.modalService.backToMenu();
    }
  }

  private isValidScreensaverWidget() {
    return this.client['screensaver'] && this.client['screensaver'].activated
      && this.isInWidgetScreensaverTimeframe() && this.screensaverWidget;
  }

  private isValidScreensaverAds() {
    return this.client['screensaver'] && this.client['screensaver'].ads && this.client['screensaver'].ads.activated
      && this.screensaverAds && this.screensaverAds.length > 0;
  }

  private isInWidgetScreensaverTimeframe() {
    const timeFrom = this.dateToTime(new Date(this.client.screensaver.timeFrom));
    const timeTo = this.dateToTime(new Date(this.client.screensaver.timeTo));
    const now = this.dateToTime(new Date());
    if (timeFrom < timeTo) {
      return now >= timeFrom && now < timeTo;
    } else {
      return (now >= timeFrom && now > timeTo) || (now < timeFrom && now < timeTo);
    }
  }

  private getScreensaverAds(): Observable<any> {
    return this.advertisingPartnerService.getAllGlobal().pipe(map(globalAds => {
      const tempAds = new Array<Advertising>();
      return this.advertisingPartnerService.getAll().pipe(map(ads => {
        ads.forEach(ad => {
          if (ad.activated && ad.screensaver) {
            ad.screensaver.image = this.utils.buildAmazonawsURL(this.getClientId(), ad.screensaver.image);
            tempAds.push(ad);
          }
        });
        globalAds.forEach(ad => {
          if (ad.activated && ad.screensaver) {
            ad.screensaver.image = this.utils.buildAmazonawsURL('0', ad.screensaver.image);
            tempAds.push(ad);
          }
        });

        this.screensaverAds = tempAds.filter(ad =>
          this.advertisingPartnerService.verifyAdvertisement(ad) && this.isActive(ad)
        );
        return this.screensaverAds;
      }));
    }));
  }

  private getScreensaverWidget(): Observable<any> {
    return this.api
      .get(/content/ + this.getClientId() + '/' + this.client.screensaver.widgetId)
      .pipe(map(res => this.utils.extractData(res)));
  }

  private refreshData() {
    if (++this.iterationNumber > 6) {
      this.getScreensaverWidget().subscribe(widget => this.screensaverWidget = widget);
      this.getScreensaverAds().subscribe(o => o.subscribe(ads => this.screensaverAds = ads));
      this.iterationNumber = 0;
    }
  }

  private isScreensaverEnabled() {
    return this.client && this.client['screensaver'];
  }

  private dateToTime(date: Date) {
    return new Date(1970, 1, 1, date.getHours(), date.getMinutes(), 0, 0);
  }

  private getClientId() {
    return this.authService.authObject ? this.authService.authObject.clientId : null;
  }

  private isActive(ad: Advertising): boolean {
    return ad.screensaver && ad.screensaver.active;
  }
}
