import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { I18nPipe } from './pipes/i18n.pipe';
import { SafePipe } from './pipes/safe.pipe';
import { SortPagesPipe } from './pipes/sort-pages.pipe';
import { SortWidgetsPipe } from './pipes/sort-widgets.pipe';
import { AwsUrlPipe } from './pipes/aws-url.pipe';

@NgModule({
    imports: [CommonModule],
    declarations: [I18nPipe, SafePipe, SortPagesPipe, SortWidgetsPipe, AwsUrlPipe],
    exports: [I18nPipe, SafePipe, SortPagesPipe, SortWidgetsPipe, AwsUrlPipe],
    providers: [I18nPipe, SafePipe, SortPagesPipe, SortWidgetsPipe, AwsUrlPipe],
})
export class SharedModule { }
