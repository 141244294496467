import {
  Component, OnInit, Input
} from '@angular/core';
import {
  trigger,
  state,
  style,
  transition,
  animate
} from '@angular/animations';
import {
  Client, ClientService, LanguagesService, ModalService, WeatherService, CurrentWeatherObject, AuthService,
  UtilsService, DisplayTypeEnum } from '../services';
import { Router } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: 'header.component.html',
  animations: [
    trigger('hideHeader', [
      state('fullscreen', style({
        height: '0'
      })),
      transition('fullscreen => *', animate('200ms ease-in')),
      transition('* => fullscreen', animate('200ms ease-out'))
    ])
  ]
})
/**
 * Header Class
 */
export class HeaderComponent implements OnInit {
  currentWeather: CurrentWeatherObject;
  client: Client;
  logo: any;
  isVerticalDisplay = false;
  constructor(
    protected weatherService: WeatherService,
    protected clientService: ClientService,
    protected languagesService: LanguagesService,
    public modalService: ModalService,
    protected authService: AuthService,
    protected utils: UtilsService,
    protected router: Router
  ) {
  }

  ngOnInit() {
    // Get client data. And when received display the logo
    this.clientService.client$.subscribe(client => {
      const clientId: string = this.authService.authObject ? this.authService.authObject.clientId : null;
      this.client = client;
      if (client['logo'] && client['logo'][this.languagesService.currentLanguage]) {
        this.logo = this.utils.buildAmazonawsURL(clientId, client['logo'][this.languagesService.currentLanguage]);
      } else if (typeof client['logo'] === 'string' && client['logo'] !== '') {
        this.logo = this.utils.buildAmazonawsURL(clientId, '' + client['logo']);
      }
      this.isVerticalDisplay = client.displayType && client.displayType === DisplayTypeEnum.VERTICAL;
    });
    // If the language was changed reload the header data for the set language
    this.languagesService.currentLanguage$.subscribe(lang => {
      const clientId: string = this.authService.authObject ? this.authService.authObject.clientId : null;
      if (this.client && this.client['logo'] && this.client['logo']['' + lang]) {
        this.logo = this.utils.buildAmazonawsURL(clientId, this.client['logo']['' + lang]);
      }
    });
    // Get the current weather to display it in the header
    this.weatherService.currentWeather$.subscribe((weather: CurrentWeatherObject) => {
      this.currentWeather = weather;
    });
  }

  showForecast() {
    this.router.navigate(['/weather']);
  }

}
