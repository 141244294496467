<div bsModal #modal="bs-modal" class="modal fade resp-modal" tabindex="-1" role="dialog" aria-labelledby="smallModalLabel"
  aria-hidden="true" (onHide)="reset()" (onShown)="setContent()">
  <div class="modal-dialog modal-lg" [ngClass]="{'iframe-modal-size': iframeSrc, 'wideOverlay': wideOverlay}">
    <div class="modal-content" class="modal-content">
      <app-widget-header [header]="modalTitle ? (modalTitle | i18n) : null" (onClose)="modal.hide()"></app-widget-header>
      <div class="modal-body" class="modal-body">
        <div class="container-fluid" [ngClass]="{'max-height': iframeSrc}">
          <div class="row " class="max-height">
            <!--<div *ngIf="!iframeSrc " class="col-md-12 modal-html-content fr-view" [innerHTML]="(modalBody | i18n | safe ) "></div>-->
            <div *ngIf="!iframeSrc ">
              <div  class="col-md-12 modal-html-content fr-view" [innerHTML]="(modalBody | i18n | safe ) "></div>
            </div>
            <!--<div><quill-editor #editor [innerHTML]="(modalBody | i18n | safe ) " [strict]="false" [modules]="{}" [readOnly]="true"></quill-editor></div> -->
            <iframe *ngIf="iframeSrc" [src]="iframeSrc" [ngClass]="{'iframe': iframeSrc}" id="theframe " sandbox="allow-forms allow-same-origin allow-scripts"></iframe>
          </div>
          <div class="row " *ngIf="modalTarget ">
            <div class="col-md-12 ">
              <button class="btn btn-primary " (click)="openTarget(modalTarget) ">{{modalTargetTitle | i18n}}</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<custom-modal *ngIf="initializeUrlModal" #urlModal></custom-modal>
