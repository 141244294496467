import { Component, Input, ViewChild, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { UtilsService, ClientService, LanguagesService, ModalService, Page } from '@app/services';
import { I18nPipe } from '@app/shared/pipes/i18n.pipe';

@Component({
  selector: 'app-content-widget',
  templateUrl: 'content-widget.component.html',
  styleUrls: ['content-widget.component.css']
})

/**
 * Content Widget
 */
export class ContentWidgetComponent implements OnInit, OnDestroy {
  @Input() page: any;
  bg: string;
  client: any;
  @ViewChild('modal') modal: any;
  backToMenu$: any;
  constructor(
    protected router: Router,
    protected clientService: ClientService,
    protected languagesService: LanguagesService,
    protected modalService: ModalService,
    protected utils: UtilsService,
    protected i18n: I18nPipe
  ) {
    window.addEventListener('message', this.receiveBackPluginMessage);
  }

  ngOnInit() {
    this.clientService.getClient().subscribe( client => {
      this.client = client;
    });

    this.backToMenu$ = this.modalService.backToMenu$.subscribe(res => {
      this.modal.modal.hide();
    });
  }

  /**
	 * redirects to the content section of a given page
	 * @param {Page} page - The page. It must hold content || modal || type information
	 * @return {void}
	 */
  openLink(page: Page) {
    this.modalService.openWidget(page);
  }

  /**
	 * receive and store back button plugin message that plugin is available
	 */
  receiveBackPluginMessage (event) {
    if (event.origin === 'http://127.0.0.1' && event.data === 'created back button') {
      window.localStorage.setItem('backButtonCreated', 'true');
    }
  }

  /**
	 * setBGStyle
	 * this function is called by the dom elems to check style information
	 * @return {Object} style class
	 */
  setBGStyle() {
    if (this.client && this.client.colors) {
        const styles = {
        'background-color': this.utils.hexToRgb(this.client.colors.lightBG, this.page.opacity),
      };
      return styles;
    }
    return {};
  }

  getBackgroundPosition() {
    if (this.page && this.page['centerImage']) {
      return 'center';
    }
    return 'left top';
  }

  getBackground(image: any): string {
    const imageUrl = this.i18n.transform(image, false);
    if (imageUrl && imageUrl !== '') {
      return 'url(' + imageUrl + ')';
    }
    return '';
  }

  /**
	 * setFontStyle
	 * this function is called by the dom elems to check style information
	 * @return {Object} style class
	 */
  setFontStyle() {
    if (this.client && this.client.colors) {
      const styles = {
        'color': this.client.colors.main
      };
      return styles;
    }
    return {};
  }

  ngOnDestroy() {
    if (this.backToMenu$) {
      this.backToMenu$.unsubscribe();
    }
  }

}
